import PostIt from "../../../components/elements/findhelpfor/postIt";
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Attachment() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Attachment Disorder</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Attachment Disorder</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/attachement-disorder.jpg" alt="Attachment Disorder image" />
                </div>
              </div>
              <div className="content">
                <p>Attachment disorder is usually diagnosed in children between 1 – 5 years old.</p>
                <p>This is when children are more aware of their caregiver’s role and what that means within their life.</p>
                <p>A diagnosed attachment disorder is rarer than the more common separation anxiety that children experience. For example, from when caregivers go back to work or children are left with babysitters.</p>
                <p>When diagnosed with Attachment disorder, you are looking for one of two forms for this to appear as:</p>
                <ul>
                  <li>Reactive Attachment Disorder (or RAD). Exhibited in children who find little comfort in their caregivers and don’t usually look for comfort from loved ones.</li>
                  <li>Disinhibited social engagement disorder (or DSED). Children look for no more comfort in strangers than they do their caregivers. DSED can mean a child will hug, run to, or look for comfort in anyone, including strangers.</li>
                </ul>
                <p>Attachment Disorder is not diagnosed in adults. However, children who haven’t had the opportunity to understand how to live with RAD or DSED can find themselves dealing with lingering issues in adult life such as:</p>
                <ul>
                  <li>Social <a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">anxiety</a></li>
                  <li>Commitment issues</li>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/anger/" target="_blank" rel="noreferrer noopener">Anger</a> issues</li>
                  <li>Attempts to control your emotions using substances leading to <a href="https://www.therapyin.london/find-help-for/addiction/" target="_blank" rel="noreferrer noopener">addictions</a></li>
                </ul>
                <p>In extreme and very rare circumstances and usually, when undiagnosed, this can also lead to psychotic and sociopathic traits in adults.</p>
                <h2>What causes Attachment Disorder</h2>
                <p>An element of this disorder is based on how a caregiver helps their child relate to the world and others.</p>
                <p>During infancy and adolescence, abuse, neglect, and extreme parenting styles have been proven to create a warped sense of the world. This can affect a child’s bond with their caregiver and how they form relationships with others.</p>
                <p>Known as the <a href="https://www.simplypsychology.org/attachment.html" target="_blank" rel="noreferrer noopener">Attachment Theory</a>, from childhood, every relationship built between parents, friends, and strangers, affects the relationships we form as adults.</p>
                <p>Orphaned children are also more susceptible as they are less likely to experience the intimate bond with a caregiver which is vital in any child’s development.</p>
                <h2>How can therapy help.</h2>
                <p>Therapy is a hugely important way to understand relationships and what they mean to you. When moving into adulthood, it’s important to reflect back on your emotions and how you build on relationships with others.</p>
                <p>If you find that this is a difficult aspect of your day-to-day life, talking counselling is a practical and beneficial way of learning where your habits came from and how you can understand yourself better.</p>
                <p>Helping a child manage this disorder starts with talking counselling. This comes down to giving a child its own personal and confidential space to exhibit their own feelings and emotions away from having to feel pressured or stifled.</p>
                <h2>How <em>Therapy in London</em> can help</h2>
                <p>Our practitioner’s top priority is creating a positive and more successful future for yourself.</p>
                <p>The relationships built in your life are fundamental in that success, so we want to guide you through where you have come, what has brought you to this place, and how you can learn from this to better yourself and the company you keep.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Attachment;

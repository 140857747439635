// src/pages/meta/routeMetadata.js
export const routeMetadata = {

  // ACCOUNT
  "/portal/signin": {
    title: "Sign In - Therapy in London",
    description: "Sign in to Therapy in London",
    keywords: "sign in, therapy in london",
  },
  "/portal/signup": {
    title: "Sign Up - Therapy in London",
    description: "Sign up to Therapy in London",
    keywords: "sign up, therapy in london",
  },
  "/portal/forgot-password": {
    title: "Forgot Password - Therapy in London",
    description: "Sign up to Therapy in London",
    keywords: "sign up, therapy in london",
  },
  "/therapist/profile": {
    title: "Account - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/articles": {
    title: "Articles - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/billing": {
    title: "Billing - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/settings": {
    title: "Settings - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/profile/edit": {
    title: "Edit Profile - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/articles/available": {
    title: "Available Articles - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/articles/available/write": {
    title: "Write Article - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  "/therapist/articles/edit": {
    title: "Edit Article - Therapy in London",
    description: "Therapy in London",
    keywords: "therapy in london",
  },
  // WHY JOIN
  "/why-join-therapy-in-london": {
    title: "Why Join Therapy in London",
    description: "Sign up to Therapy in London as a Therapist to grow your reach and find new clients",
    keywords: "find a therapist, sign up, therapist account",
  },
  // HOW TOS
  "/how-to": {
    title: "The Guides to Therapy",
    description: "Therapy starts with deciding to start and then finding the right counsellor for you. If you want to know how to start therapy, here's how.",
    keywords: "find a therapist, therapy guidance, starting therapy, pricing",
  },
  "/how-to/find-a-therapist": {
    title: "How to Find a Therapist",
    description: "Learn how to find the right therapist for you. Get answers to your questions and guidance on starting your therapy journey.",
    keywords: "find a therapist, therapy guidance, starting therapy",
  },
  "/how-to/get-the-most-from-therapy": {
    title: "How to Get the Most From Therapy",
    description: "Discover ways to maximize your benefits from therapy and build a strong relationship with your therapist.",
    keywords: "get the most from therapy, therapy tips, therapeutic relationship",
  },
  "/how-to/end-therapy/": {
    title: "How to End Therapy",
    description: "Explore how to effectively end therapy and transition smoothly back into your daily life after therapy.",
    keywords: "end therapy, finishing therapy, therapy transition",
  },
  // COUNSELLING SERVICES
  "/counselling-services": {
    title: "Types of Therapy",
    description: "Whether you want to want personal, 1-to-1 counselling or family therapy, there are counselling services out there for you. Therapy in London.",
    keywords: "personal counselling, one-to-one therapy, mental health, online therapy, family counselling, couples counselling, types of therapy",
  },
  "/counselling-services/personal-counselling": {
    title: "Personal Counselling (1-to-1)",
    description: "When something doesn’t feel right in your life, having the awareness to seek help through personal counselling is a huge step towards healing.",
    keywords: "personal counselling, one-to-one therapy, mental health",
  },
  "/counselling-services/online-therapy": {
    title: "Online Therapy",
    description: "What is online therapy and will it work for me? Even if you feel stuck at home, online therapy can offer flexibility and support.",
    keywords: "online therapy, virtual therapy, remote counselling",
  },
  "/counselling-services/family-counselling": {
    title: "Family Counselling",
    description: "Family counselling can help address communication breakdowns or conflicts within the family unit, aiming for better understanding and relationships.",
    keywords: "family counselling, family therapy, relationship issues",
  },
  "/counselling-services/marriage-counselling": {
    title: "Marriage Counselling",
    description: "Marriage counselling focuses on improving relationships between partners, addressing issues that affect their commitment and unity.",
    keywords: "marriage counselling, couples therapy, relationship improvement",
  },
  "/counselling-services/couple-counselling": {
    title: "Couple Counselling",
    description: "Couple counselling aims to resolve conflicts and enhance communication between partners, addressing the highs and lows of their relationship.",
    keywords: "couple counselling, couples therapy, relationship support",
  },
  // FIND HELP FOR
  "/find-help-for": {
    title: "Find help for any issues you may have",
    description:
      "Find help for mental health issues, personal growth, and more.",
    keywords: "help, mental health, personal growth",
  },
  "/find-help-for/abuse": {
    title: "Find Help For Abuse",
    description: "Abuse is seen as regular and repeated violence, cruelty, bullying or neglect.",
    keywords: "abuse, violence, bullying, neglect",
  },
  "/find-help-for/addiction": {
    title: "Find Help For Addiction",
    description: "An addiction can be anything you feel you cannot physically stop. To be addicted means...",
    keywords: "addiction, substance abuse, dependency",
  },
  "/find-help-for/adhd": {
    title: "Find Help For ADHD",
    description: "ADHD is a condition most commonly diagnosed in children between 6-12. It can be seen...",
    keywords: "ADHD, attention deficit, hyperactivity",
  },
  "/find-help-for/anxiety": {
    title: "Find Help For Anxiety",
    description: "Understanding anxiety first begins with why it’s there in the first place...",
    keywords: "anxiety, mental health, stress",
  },
  "/find-help-for/attachment-disorder": {
    title: "Find Help For Attachment Disorder",
    description: "Attachment disorder is usually diagnosed in children between 1 – 5 years old. This is...",
    keywords: "attachment disorder, childhood issues",
  },
  "/find-help-for/bereavement": {
    title: "Find Help For Bereavement",
    description: "Bereavement is a unique experience for everyone and it comes with an all too real...",
    keywords: "bereavement, grief, loss",
  },
  "/find-help-for/bipolar": {
    title: "Find Help For Bipolar Disorder",
    description: "Bipolar disorder is a mental condition resulting in either heights of hyperactivity and energy, to...",
    keywords: "bipolar disorder, mood swings, mental health",
  },
  "/find-help-for/body-dysmorphia": {
    title: "Find Help For Body Dysmorphia",
    description: "When experiencing Body Dysmorphia, your main focus is what others might think of your appearance...",
    keywords: "body dysmorphia, appearance, self-image",
  },
  "/find-help-for/bpd": {
    title: "Find Help For BPD",
    description: "Borderline Personality Disorder (BPD) is a mental health condition that can result in a variety...",
    keywords: "BPD, borderline personality disorder, emotional instability",
  },
  "/find-help-for/commitment-issues": {
    title: "Find Help For Commitment Issues",
    description: "If you believe that you have commitment issues, you will usually find it difficult to...",
    keywords: "commitment issues, relationships, emotional problems",
  },
  "/find-help-for/confidence": {
    title: "Find Help For Confidence",
    description: "It is common to lose confidence and this can happen for many reasons. Confidence doesn’t...",
    keywords: "confidence, self-esteem, personal growth",
  },
  "/find-help-for/depression": {
    title: "Find Help For Depression",
    description: "Depression occurs in 1 of 5 of us, yet is still an issue that needs...",
    keywords: "depression, mental health, mood disorder",
  },
  "/find-help-for/therapy-for-disabled": {
    title: "Find Help For Disabled Individuals",
    description: "Being able to look after your own mental health and your mindset towards your disability...",
    keywords: "disabled individuals, mental health, therapy",
  },
  "/find-help-for/dissociation": {
    title: "Find Help For Dissociation",
    description: "Dissociation is a feeling of not being present – as if you are observing your...",
    keywords: "dissociation, mental health, detachment",
  },
  "/find-help-for/divorce": {
    title: "Find Help For Divorce",
    description: "A marriage is built on the assumption that you will be together forever. It seemed...",
    keywords: "divorce, separation, relationship issues",
  },
  "/find-help-for/eating-disorders": {
    title: "Find Help For Eating Disorders",
    description: "Eating disorders are essentially a felt need to drastically control your food intake. It becomes...",
    keywords: "eating disorders, food control, mental health",
  },
  "/find-help-for/gender-identity": {
    title: "Find Help For Gender Identity and Dysphoria",
    description: "Your own gender identity can only be decided by you as an individual. This is...",
    keywords: "gender identity, dysphoria, personal identity",
  },
  "/find-help-for/health-anxiety": {
    title: "Find Help For Health Anxiety",
    description: "Health Anxiety (or Hypochondria) means you are in regular or constant fear of being ill...",
    keywords: "health anxiety, hypochondria, mental health",
  },
  "/find-help-for/hoarding": {
    title: "Find Help For Hoarding",
    description: "Hoarding is when you feel the need to keep things no matter their value, regardless...",
    keywords: "hoarding, clutter, psychological issues",
  },
  "/find-help-for/hypervigilance": {
    title: "Find Help For Hypervigilance",
    description: "Before understanding hypervigilance, it’s important to differentiate between this and everybody’s regular brain function surrounding...",
    keywords: "hypervigilance, mental health, anxiety",
  },
  "/find-help-for/lgbtq-issues": {
    title: "Find Help For LGBTQ+ Issues",
    description: "At times, people who identify as LGBTQ+ find that the social stress of living as...",
    keywords: "LGBTQ+, social stress, mental health",
  },
  "/find-help-for/loneliness": {
    title: "Find Help For Loneliness",
    description: "Are you questioning if you are lonely? And what does being lonely actually mean? Loneliness...",
    keywords: "loneliness, social isolation, mental health",
  },
  "/find-help-for/low-self-esteem": {
    title: "Find Help For Low Self Esteem",
    description: "An addiction can be anything you feel you cannot physically stop. To be addicted means...",
    keywords: "low self-esteem, self-worth, personal growth",
  },
  "/find-help-for/lying": {
    title: "Find Help For Lying",
    description: "While most of us are taught from a young age not to lie, lying from...",
    keywords: "lying, honesty, psychological impact",
  },
  "/find-help-for/mens-mental-health": {
    title: "Find Help For Men's Mental Health",
    description: "Men have been restricted for years when it comes to discussing their own mental wellbeing...",
    keywords: "men's mental health, mental wellbeing, stigma",
  },
  "/find-help-for/neurodiversity": {
    title: "Find Help For Neurodiversity",
    description: "Neurodiversity refers to understanding the diversity of the human brain. You can uncover your strengths...",
    keywords: "neurodiversity, cognitive diversity, mental health",
  },
  "/find-help-for/ocd": {
    title: "Find Help For OCD",
    description: "Obsessive Compulsive Disorder (OCD) is a complex and personally unique ailment to just under 1...",
    keywords: "OCD, obsessive compulsive disorder, mental health",
  },
  "/find-help-for/panic-attacks": {
    title: "Find Help For Panic Attacks",
    description: "Panic attacks, (anxiety attacks, or panic disorder) is an issue resulting in severe physical symptoms...",
    keywords: "panic attacks, anxiety, mental health",
  },
  "/find-help-for/paranoia": {
    title: "Find Help For Paranoia",
    description: "Not all cases of paranoia are the same. There is a wide spectrum of cases...",
    keywords: "paranoia, mental health, anxiety",
  },
  "/find-help-for/peter-pan-syndrome": {
    title: "Find Help For Peter Pan Syndrome",
    description: "Peter Pan Syndrome refers to someone who may find it difficult to take on adult...",
    keywords: "Peter Pan Syndrome, adulthood, personal growth",
  },
  "/find-help-for/phobia": {
    title: "Find Help For Phobias",
    description: "A phobia is an anxiety disorder that causes severe, and sometimes debilitating, fear in certain...",
    keywords: "phobia, anxiety disorder, fear",
  },
  "/find-help-for/postnatal-depression": {
    title: "Find Help For Postnatal Depression",
    description: "Having a child can bring a lot of emotions to the surface. It takes an...",
    keywords: "postnatal depression, new parents, mental health",
  },
  "/find-help-for/procrastination": {
    title: "Find Help For Procrastination",
    description: "When dealing with procrastination, you are constantly fighting the urge to either do something more...",
    keywords: "procrastination, productivity, mental health",
  },
  "/find-help-for/ptsd": {
    title: "Find Help For PTSD",
    description: "Post Traumatic Stress Disorder (PTSD) occurs when a traumatic event from your past continues to...",
    keywords: "PTSD, trauma, mental health",
  },
  "/find-help-for/seasonal-affective-disorder": {
    title: "Find Help For Seasonal Affective Disorder",
    description: "Seasonal Affective Disorder (SAD) occurs at certain times of the year, usually in the winter...",
    keywords: "seasonal affective disorder, SAD, mental health",
  },
  "/find-help-for/self-harm": {
    title: "Find Help For Self Harm",
    description: "Self harm is a way of coping with or expressing emotional distress through hurting yourself...",
    keywords: "self harm, emotional distress, mental health",
  },
  "/find-help-for/sexual-issues": {
    title: "Find Help For Sexual Issues",
    description: "Sexual issues can be an impediment to your mental wellbeing and personal life, creating problems in your...",
    keywords: "sexual issues, mental health, personal relationships",
  },
  "/find-help-for/sleep-disorder": {
    title: "Find Help For Sleep Disorders",
    description: "Sleep disorders are conditions that frequently disrupt your sleep or impact its quality...",
    keywords: "sleep disorders, insomnia, sleep health",
  },
  "/find-help-for/suicide": {
    title: "Find Help For Suicide",
    description: "Suicide is the ultimate act of desperation, born from feeling like you can no longer cope...",
    keywords: "suicide, mental health crisis, support",
  },
  "/find-help-for/trauma": {
    title: "Find Help For Trauma",
    description: "Trauma refers to the emotional and psychological responses to an event or series of events...",
    keywords: "trauma, emotional responses, mental health",
  },
  "/articles": {
    title: "Articles by Therapist in London",
    description:
      "Articles written by Therapist in London covering trending topics and helping people find the right help using therapy.",
    keywords: "articles, therapy, london, blog",
  },
  // BLOG
  "/blog": {
    title: "Articles by Therapy in London",
    description:
      "Articles written by Therapy in London covering trending topics and helping people find the right help using therapy.",
    keywords: "articles, therapy, london, blogs",
  },
  "/blog/is-being-childish-a-good-or-a-bad-thing/": {
    title: "Is Being Childish a Good or a Bad Thing?",
    description:
      "Explore the pros and cons of being childish and its impact on personal growth.",
    keywords: "being childish, personal growth, pros and cons",
  },
  "/blog/childish/": {
    title: "Childish Act",
    description: "A deep dive into childish acts and their implications.",
    keywords: "childish act, psychology, behavior",
  },
  "/blog/should-we-blame-boomers-for-millennial-trauma/": {
    title: "Should We Blame Boomers for Millennial Trauma?",
    description: "An analysis of generational trauma and its origins.",
    keywords: "generational trauma, millennials, boomers",
  },
  "/blog/bluey-and-the-banned-episode-smoochy-kiss/": {
    title: "Bluey and the Banned Episode Smoochy Kiss",
    description: "Discussion on Bluey’s banned episode and its impact.",
    keywords: "Bluey, banned episode, smoochy kiss",
  },
  "/blog/therapist-reaction-to-tick-tick-boom": {
    title: "Therapist Reaction to Tick Tick Boom",
    description: "A therapist’s perspective on the movie Tick Tick Boom.",
    keywords: "Tick Tick Boom, therapist reaction, movie review",
  },
  "/blog/therapist-reacts-to-baby-reindeer": {
    title: "Therapist Reacts to Baby Reindeer",
    description: "Insights from a therapist on Baby Reindeer.",
    keywords: "Baby Reindeer, therapist insights, movie review",
  },
  "/blog/does-manifesting-and-the-law-of-attraction-work": {
    title: "Does Manifesting and the Law of Attraction Work?",
    description:
      "Exploring the effectiveness of manifesting and the law of attraction.",
    keywords: "manifesting, law of attraction, effectiveness",
  },
  "/blog/what-is-the-whale-movie-really-about": {
    title: "What is The Whale Movie Really About?",
    description: "A deep dive into the themes and messages of The Whale movie.",
    keywords: "The Whale, movie themes, film analysis",
  },
  "/blog/emotions-to-thoughts": {
    title: "Emotions to Thoughts",
    description: "Understanding the connection between emotions and thoughts.",
    keywords: "emotions, thoughts, psychological connection",
  },
  "/blog/being-an-only-child-is-there-still-a-stigma": {
    title: "Being an Only Child: Is There Still a Stigma?",
    description: "Exploring the stigma surrounding being an only child.",
    keywords: "only child, stigma, societal perceptions",
  },
  "/blog/the-endless-mental-cycle-of-the-summer-holidays": {
    title: "The Endless Mental Cycle of the Summer Holidays",
    description: "Discussing the psychological impact of summer holidays.",
    keywords: "summer holidays, mental cycle, psychological impact",
  },
  "/blog/mental-health-issues-covered-in-netflixs-beef": {
    title: "Mental Health Issues Covered in Netflix’s Beef",
    description:
      "An exploration of mental health issues depicted in Netflix’s Beef.",
    keywords: "Netflix Beef, mental health issues, TV show review",
  },
  "/blog/why-does-everyone-have-a-podcast": {
    title: "Why Does Everyone Have a Podcast?",
    description: "Investigating the rise in podcasting and its appeal.",
    keywords: "podcasting, rise in podcasts, media trends",
  },
  "/blog/therapist-reacts-to-bojack-horseman": {
    title: "Therapist Reacts to Bojack Horseman",
    description: "A therapist’s reaction to the TV show Bojack Horseman.",
    keywords: "BoJack Horseman, therapist reaction, TV show analysis",
  },
  "/blog/we-cant-all-be-neurodivergent-can-we": {
    title: "We Can’t All Be Neurodivergent, Can We?",
    description: "Examining the concept of neurodivergence and its limits.",
    keywords: "neurodivergent, neurodiversity, mental health",
  },
};

// src/app/stores/searchStore.js
import { create } from "zustand";

export const useSearchStore = create((set, get) => ({
  location: {},
  specialisms: [],
  therapyTypes: [],
  languages: [],
  sessionTypes: [],
  gender: [],
  wheelchairAccess: null,
  page: 1,
  limit: 10,
  latitude: null,
  longitude: null,
  radius: null,
  precise: false,

  setRadius: (radius) => set({ radius }),
  setLongitude: (longitude) => set({ longitude }),
  setLatitude: (latitude) => set({ latitude }),
  setLocation: (location) =>
    set({
      location,
      latitude: location.latitude,
      longitude: location.longitude,
    }),
  getDefaultRadius: () => {
    const state = get();
    if (state.precise) return 1;
    if (state.location.city) return 40;
    if (state.location.county) return 100;
    if (state.location.country) return 500;
    return 50;
  },
  setPrecise: (precise) => {
    set((state) => ({
      precise,
      radius: precise ? 1 : state.getDefaultRadius(),
    }));
  },
  setTherapyTypes: (therapyTypes) =>
    set(() => ({
      therapyTypes: Array.isArray(therapyTypes)
        ? therapyTypes
        : [therapyTypes].filter(Boolean),
    })),
  setSpecialisms: (specialisms) =>
    set(() => ({
      specialisms: Array.isArray(specialisms)
        ? specialisms
        : [specialisms].filter(Boolean),
    })),

  setLanguages: (languages) =>
    set(() => ({
      languages: Array.isArray(languages)
        ? languages
        : [languages].filter(Boolean),
    })),
  setGender: (gender) =>
    set(() => ({
      gender: Array.isArray(gender) ? gender : [gender].filter(Boolean),
    })),
  setPage: (page) => set({ page }),
  setLimit: (limit) => set({ limit }),
  setSessionTypes: (sessionTypes) =>
    set({
      sessionTypes: Array.isArray(sessionTypes) ? sessionTypes : [sessionTypes],
    }),
  setWheelchairAccess: (wheelchairAccess) => set({ wheelchairAccess }),
  resetFilters: () =>
    set({
      location: {},
      specialisms: [],
      therapyTypes: [],
      languages: [],
      sessionTypes: [],
      gender: [],
      wheelchairAccess: null,
      page: 1,
      limit: 10,
      latitude: null,
      longitude: null,
      precise: false,
    }),

  getSearchParams: () => {
    const state = get();
    const params = new URLSearchParams();

    if (state.specialisms.length > 0) {
      params.set("specialisms", state.specialisms.map((s) => s.id).join(","));
    }
    if (state.therapyTypes.length > 0) {
      params.set("typeOfTherapy", state.therapyTypes.join(","));
    }
    if (state.languages.length > 0) {
      params.set("languages", state.languages.map((l) => l.id).join(","));
    }
    if (state.gender.length > 0) {
      params.set(
        "gender",
        state.gender.map((g) => g.toLowerCase().replace(/ /g, "_")).join(",")
      );
    }

    if (state.sessionTypes.length > 0) {
      params.set("sessionTypes", state.sessionTypes.join(","));
    }
    if (state.wheelchairAccess !== null) {
      params.set("wheelchairAccess", state.wheelchairAccess.toString());
    }
    if (state.location.country) params.set("country", state.location.country);
    if (state.location.city) params.set("city", state.location.city);
    if (state.location.county) params.set("county", state.location.county);
    /*     if (state.location.postCode)
      params.set("postCode", state.location.postCode); */
    if (state.location.formattedAddress)
      params.set("formattedAddress", state.location.formattedAddress);
    if (state.latitude && state.latitude !== null) {
      params.set("latitude", state.latitude.toString());
    }
    if (state.longitude && state.longitude !== null) {
      params.set("longitude", state.longitude.toString());
    }

    if (state.precise !== null) {
      params.set("precise", state.precise.toString());
    }

    params.set("page", state.page.toString());
    params.set("limit", state.limit.toString());
    const radius = state.radius || state.getDefaultRadius();
    params.set("radius", radius.toString());
    return params;
  },
}));

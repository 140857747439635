import { useQuery } from "@tanstack/react-query";
import propTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import { getUserById } from "../../../app/api/profile/getUserByid";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import { useProfileStore } from "../../../app/stores";
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import Loader from "../loader";
import { LeftLine } from "../svgs/LeftLine";
import { ProfileScribble } from "../svgs/ProfleScribble";
import { StarThingy } from "../svgs/StarThingy";

const Main = React.forwardRef((props, ref) => {
  const {
    isTherapistAdmin,
    name,
    address,
    phone,
    email,
    qualifications,
    profilePicture,
    userId,
    color,
  } = props;
  const { profile } = useProfileStore((state) => ({
    profile: state.profile,
  }));
  const location = useLocation();
  const isResultsProfile = location.pathname.startsWith("/results/profile/");
  const userByIdQuery = useQuery({
    queryKey: ["user", userId],
    queryFn: () => (userId ? getUserById(userId) : null),
    enabled: !!userId,
  });
  const getProfileData = () => {
    if (isResultsProfile) {
      return userByIdQuery.data?.user;
    }
    if (isTherapistAdmin) {
      return profile;
    }
    return {
      name,
      address,
      phone,
      email,
      qualifications,
      profilePicture,
      id: userId,
      color,
    };
  };

  const profileData = getProfileData();

  if (userByIdQuery.isLoading) {
    return <Loader subtitle="Loading Profile" />;
  }

  return (
    <div className="profile_section_main" ref={ref}>
      <div className="userName_wrap">
        <div className="image_wrap_border">
          <div
            className="image_wrap"
            style={{
              backgroundColor:
                profileData?.profile?.profilePicture ||
                  profileData?.profilePicture
                  ? "transparent"
                  : "#000000",
            }}
          >
            {profileData?.profile?.profilePicture ||
              profileData?.profilePicture ? (
              <img
                src={getFileUrl(
                  "profilePicture",
                  profileData?.id,
                  profileData?.profile?.profilePicture ||
                  profileData?.profilePicture
                )}
                alt="Profile"
              />
            ) : (
              <img
                src="../../images/icons/Camera.svg"
                alt="Placeholder"
                className="iconPlaceholder"
              />
            )}
          </div>
        </div>

        {name && (
          <div className="userName">
            <h1>
              <StarThingy
                color={profileData?.profile?.color || profileData?.color}
              />
              {name}
            </h1>
          </div>
        )}
      </div>
      <div className="profileScribble">
        <ProfileScribble
          color={profileData?.profile?.color || profileData?.color}
        />
      </div>
      <div className="profile_image">
        <div className="name">
          <div className="title">
            {qualifications && (
              <label>
                <p>{qualifications}</p>
              </label>
            )}

            {address && (
              <div className="address">
                <LeftLine
                  color={profileData?.profile?.color || profileData?.color}
                />
                <p>{address}</p>
              </div>
            )}
            {phone && (
              <div className="address">
                <LeftLine
                  color={profileData?.profile?.color || profileData?.color}
                />
                <p>
                  <a href={`tel:${phone}`}>{phone}</a>
                </p>
              </div>
            )}
            {email && (
              <div className="address">
                <LeftLine
                  color={profileData?.profile?.color || profileData?.color}
                />
                <p>
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
});

Main.displayName = "Main";

Main.propTypes = {
  isTherapistAdmin: propTypes.bool,
  name: propTypes.string,
  address: propTypes.string,
  phone: propTypes.string,
  email: propTypes.string,
  qualifications: propTypes.string,
  profilePicture: propTypes.string,
  userId: propTypes.string,
  color: propTypes.string,
};

export default Main;

import { useCallback } from "react";
import { useProfileStore } from "../../app/stores";

export const useUpdateProfileData = (isLoading) => {
  const { updateProfile, clearStore } = useProfileStore();

  return useCallback(
    (user) => {
      if (user && user?.profile !== null && !isLoading) {
        clearStore();
        updateProfile({
          ...user.profile,
          userId: user.id,
          name: user.name,
          email: user.email,
          phoneNumber: user.profile.phoneNumber,
          color: user.profile.color,
          lamp: user.profile.lamp === 0 ? 1 : user.profile?.lamp,
          sofa: user.profile.sofa === 0 ? 1 : user.profile?.sofa,
          plant: user.profile.plant === 0 ? 1 : user.profile?.plant,
          pricePackage: user.profile.pricePackage,
          membershipBody: user.profile.membershipBody,
          languages: user.profile.languages,
          insuranceFile: user.profile.insuranceFile,
          profileInfos: user.profile.profileInfos,
          specialisms: user.profile.specialisms,
          selectedPhoto: null,
          quote: user.profile.quote,
          typesOfTherapy: user.profile.typesOfTherapy || [],
          sessionTypes: user.profile.sessionTypes || [],
          wheelchairAccess: user.profile.wheelchairAccess,
          formattedAddress: user.profile.formattedAddress,
          photo: user.profile.profilePicture,
          furniturePicture: user.profile.furniturePicture,
          article1: user.profile.article1Id ?? null,
          article2: user.profile.article2Id ?? null,
          locationObj: user.profile.location || {},
          qualifications: user.profile.qualifications,
        });
      } else if (user && !isLoading) {
        updateProfile({
          userId: user.id,
          name: user.name,
          email: user.email,
        });
      }
    },
    [updateProfile, clearStore, isLoading]
  );
};

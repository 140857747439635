import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import CTA from "../../fragments/CTA";
import { getFileUrl } from "../../../app/lib/getFileUrl"; // Import the function

function NavProfile({ name, profilePicture, phoneNumber, email, userId, showProfile = true }) {
  if (!showProfile) {
    return null; // Do not render if showProfile is false
  }

  const profilePictureUrl = getFileUrl("profilePicture", userId, profilePicture);

  return (
    <div className="profileNavWrap">
      <div className="title">
        <span>
          <img src={profilePictureUrl} alt={`${name}'s profile`} />
        </span>
        <h2>{name}</h2>
      </div>
      <div className="controls">
        <CTA type="blue" text="Call" link={`tel:${phoneNumber}`} />
        <CTA type="blue" text="Email" link={`mailto:${email}`} />
      </div>
    </div>
  );
}

export default NavProfile;

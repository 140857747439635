import("../../../styles/global.scss");
import("../../../styles/profiles.scss");
import("../../../styles/sections.scss");

import propTypes from "prop-types";

Qualifications.propTypes = {
  title: propTypes.string,
  sub: propTypes.string,
};

function Qualifications(props) {
  const abbreviation = props.title.split(",")[0].toLowerCase();
  return (
    <div className="fees_block">
      <div className="fees_content">
        <img
          src={
            `/images/logos/${abbreviation}.png` ||
            `/images/logos/${abbreviation}.jpeg` ||
            `/images/logos/${abbreviation}.jpg`
          }
          alt={props.title}
        />
        <h2>{props.title}</h2>
        <p>{props.sub}</p>
      </div>
    </div>
  );
}

export default Qualifications;

import propTypes from "prop-types";
import Overlay from "../overlay";

export const RejectOverlay = ({
  isVisible,
  onClose,
  onReject,
  statusMessage,
  setStatusMessage,
}) =>
  isVisible && (
    <Overlay
      title="Reason for Rejections"
      subtitle="Let the user know what they need to do to get approved"
      ctaBack="Back"
      ctaGo="Send"
      onBackClick={onClose}
      onAcceptClick={onReject}
      textareaValue={statusMessage}
      onTextareaChange={(e) => setStatusMessage(e.target.value)}
      isRequired={true}
    >
      {/* Content of reject overlay */}
    </Overlay>
  );

export const ApproveOverlay = ({
  isVisible,
  onClose,
  onApprove,
  statusMessage,
  setStatusMessage,
}) =>
  isVisible && (
    <Overlay
      title="Approve Profile"
      subtitle="Are you sure you want to approve this profile?"
      ctaBack="Back"
      ctaGo="Yes, Approve"
      onBackClick={onClose}
      onAcceptClick={onApprove}
      textareaValue={statusMessage}
      onTextareaChange={(e) => setStatusMessage(e.target.value)}
      isRequired={false}
    >
      {/* Content of approve overlay */}
    </Overlay>
  );

RejectOverlay.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func,
  onReject: propTypes.func,
  statusMessage: propTypes.string,
  setStatusMessage: propTypes.func,
};

ApproveOverlay.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func,
  onApprove: propTypes.func,
  statusMessage: propTypes.string,
  setStatusMessage: propTypes.func,
};

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function SAD() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Seasonal Affective Disorder (SAD)</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Seasonal Affective Disorder (SAD)</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/SAD.jpg" />
                </div>
              </div>
              <div className="content">
                <p>When you are well into winter and you have seen nothing but rain and wind for months on end, you might be wondering why it can have such an effect on your mood. This is actually a common issue known as Seasonal Affective Disorder.</p>
                <p>In the film industry, the term Pathetic Fallacy describes the use of the weather to reflect or highlight a character’s emotions. A character feeling particularly melancholy will often be accompanied by rain.</p>
                <p>In the real world, this is also true but flipped on its head. In the later, darker, and colder months, you may feel particularly sad. Seasonal Affective Disorder or SAD is the term used to describe this.</p>
                <h2>What is SAD?</h2>
                <p>When you are suffering from SAD it can be difficult to pinpoint why you are feeling the way you are feeling. Things that wouldn’t normally bother you can feel particularly tiresome and cause much stronger feelings.</p>
                <p>SAD can mirror anxiety and even depression and, in some cases, they also have similar symptoms. Except, you will find that certain times in the year can be better or worse for these symptoms, including:</p>
                <ul>
                  <li>Excessive tiredness</li>
                  <li>Feeling lethargic</li>
                  <li>Lack of enthusiasm</li>
                  <li>Wanting to eat more</li>
                  <li>Feeling irritated</li>
                </ul>
                <p>These symptoms can vary from person to person and it is unknown what the true cause is for SAD.</p>
                <p>The main theory behind it is that the lack of sunlight during colder months can affect your hypothalamus, which is the part of the brain responsible for the decisions you make throughout the day and your mood towards those decisions. Deprived of sunlight your hypothalamus cannot produce as much serotonin or melatonin. Therefore, you find less joy in the things you do.</p>
                <p>What sunlight is also vital in doing is regulating your circadian rhythm. Darker mornings and earlier evenings throw off your body clock. This will affect your sleep pattern.</p>
                <h2>How therapy can help you.</h2>
                <p>Time is what exacerbates this issue and so it may feel like you are out of control. Like any other issue, though, it can be managed.</p>
                <p>Whether this is your first time experiencing SAD, or it has become a bit of an annual pattern in your life, there are ways for you to get through these months with a more positive and manageable outlook.</p>
                <p>When undertaking therapy for any reason, you are able to take control over the issues you have been trying to hide or bury for a long time. You can use even seemingly uncontrollable feelings to your advantage. You can do this by unpacking how you are feeling, understanding why it is happening, and being willing to take action.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>It is important to remember that you can manage any issue. What our therapists aim to do is be a guide through your heightened feelings and emotions and understand the patterns around them. We will help you address your feelings no matter how they come about. Our counseling services can offer you the space to ensure that you stay on top of them.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default SAD;

import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const disableProfile = async ({ profileId }) => {
  try {
    const response = await api.post(
      `/admin/profile/${profileId}/disable`,
      {},
      await auth.currentUser.getIdToken()
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function LGBTQ() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">LGBTQ+ issues</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for LGBTQ+ issues</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/LGBT-issues.jpg" alt="LGBTQ+ issues" />
                </div>
              </div>
              <div className="content">
                <p>At times, people who identify as LGBTQ+ find that the social stress of living as a minority is a source of <a href="https://www.therapyin.london/find-help-for/stress/" target="_blank" rel="noreferrer noopener">stress</a> or <a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">anxiety</a> issues. Ultimately, though, there are people part of the community that experience life differently from their heterosexual counterparts.&nbsp;</p>
                <p>The challenges that you face may be unique to the LGBTQ+ community — even if your romantic or sexual orientation might not be the source of them.</p>
                <h2>What is LGBTQ+?</h2>
                <p>LGBTQ stands for Lesbian, Gay, Bisexual, Transgender and Queer. The + stands in place of, Intersex, Asexual, Pansexual, Agender, Gender Queer, Bigender, Gender Variant, Pangender and any other identity or orientation that people may have.</p>
                <p>Sexual or romantic attraction to someone of the same gender is a healthy and positive form of human sexuality. And gender expression or identity outside the binary is normal. Despite this, some people discriminate against LGBTQ+ people.</p>
                <h2>What causes LGBTQ+ related issues?</h2>
                <p>There have been great strides taken towards the acceptance of diverse sexual and romantic orientations and gender identifications. However, discrimination and marginalisation of LGBTQ+ people still exists. And, in some communities, they are still oppressed.&nbsp;</p>
                <p>Feeling suppressed is also still an issue for many LGBTQ+ people. Societal pressures to be someone you are not or hold back on who you want to be is common. Yet it is not openly addressed enough. There are also unique experiences for the LGBTQ+ community. Such as coming out to one’s family and determining a sense of self in the face of heteronormative societal expectations.&nbsp;</p>
                <p>Being unable to understand your own identity is why many people seek talking counselling. This therapy is a way to find out who you are and what has brought you to this point.</p>
                <p>Because of this, there are many challenges that are unique to LGBTQ+ people.&nbsp; The additional challenges that are often faced may lead to higher levels of:&nbsp;</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/">Depression</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety/">Anxiety</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/addiction/">Substance abuse</a> leading to addiction&nbsp;</li>
                </ul>
                <p>The discrimination that people in the LGBTQ+ community face can come in the form of social rejection, verbal or physical bullying and assault.&nbsp;</p>
                <p>Having said that, many of the issues and concerns for which LGBTQ+ people seek therapy are the same among all people including:&nbsp;</p>
                <ul>
                  <li>Relationship troubles</li>
                  <li>Work stress</li>
                  <li>Money troubles</li>
                </ul>
                <p>It can, however, be helpful to have a therapist that can relate specifically to your individual needs, including your gender or sexual orientation.</p>
                <h2>How therapy can help</h2>
                <p>No matter how big or small your problems are, you should seek out therapy as soon as you feel you need it. Therapy can help if you:</p>
                <ul>
                  <li>Isolate yourself from people</li>
                  <li>Have no energy and are always tired</li>
                  <li>Feel tearful</li>
                  <li>No longer want to do things that used to bring you joy</li>
                  <li>Are using alcohol or drugs to help you cope with your feelings</li>
                  <li>Harm yourself or think about harming yourself</li>
                </ul>
                <p>In certain circumstances and without the right support and understanding of what you are going through, these issues can feel all-consuming and may even lead to <a href="https://www.therapyin.london/find-help-for/suicidal-thoughts/">suicidal thoughts</a>. This is why finding support, whether that be through counselling or talking to loved ones is so important.&nbsp;</p>

                <h3>If you believe that you or a loved one are having suicidal thoughts, <a href="https://www.samaritans.org/" target="_blank" rel="noreferrer noopener">The Samaritans </a>are able to speak to you 24 hours a day, 7 days a week on: 116 123</h3>

                <p>Finding the right therapist is an important part of seeking therapy for issues associated with your sexual, romantic, or gender identity and concerns related to your mental health. It is often important that you find a <a href="https://www.therapyin.london/our-therapists">Therapist in London</a> who can help.</p>
                <p>Therapy in London takes great care to make sure that the therapist you see will have the right experience to help you with LGBTQ+ related issues. Feeling understood and safe to speak your mind is the top priority for our practitioners.&nbsp;</p>
                <p>We offer a non-judgemental and confidential space for you to talk through any issue that is troubling you. Whether it is coping with a relationship, questioning your sexual orientation, or any aspect of being LGBTQ+ and life.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default LGBTQ;

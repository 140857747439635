import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Addiction() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Addiction</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Addiction</h1>
                    <img
                      src="/images/illustrations/purpleScribble.svg"
                      className="titleScribble"
                      alt="Purple scribble decoration"
                    />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/addiction.jpg" alt="Addiction article image" />
                </div>
              </div>
              <div className="content">
                <p>
                  An addiction can be anything you feel you cannot physically
                  stop. To be addicted means completely allowing your urges to
                  take over without any consideration of the consequences.
                </p>

                <p>
                  This can sound as if addiction is something you choose to give
                  into, but having an addiction is something to be taken
                  seriously, and it takes time to rework and learn new habits
                  for the better.
                </p>

                <p>Some of the most common addictions include </p>

                <ul>
                  <li>Drinking</li>
                  <li>Smoking</li>
                  <li>Gambling</li>
                  <li>Recreational, over-the-counter, or prescription drugs</li>
                  <li>Pornography</li>
                  <li>Gaming</li>
                  <li>
                    Shopping
                    <br />
                    Even internet dating, work, food, sex, exercise and diets,
                    mobile phones and social media – the list is endless.
                    Addiction isn’t defined by what the addiction is for, it is
                    defined by how it makes you feel and what it is trying to
                    suppress or find an escape from.
                  </li>
                </ul>

                <h2>What is addiction?</h2>

                <p>
                  Many people do these things mentioned but are not addicted. So
                  how do you know when you are?
                </p>

                <p>
                  Addiction is a feeling of not being able to stop, or stopping
                  doesn’t even feel like an option. An addiction can develop if
                  you need to find an escape from negative feelings or
                  situations.
                </p>

                <p>
                  For instance, drugs offer you a short-term escape by blocking
                  out your <a href="#">anxiety</a>, <a href="#">depression</a>,{" "}
                  <a href="#">trauma</a>, or <a href="#">anger</a> – but just
                  temporarily.{" "}
                </p>

                <p>
                  Once you have had that experience, you will crave that feeling
                  of brief euphoria and bliss again and again. For example, food
                  might be your go-to for relief when you feel out of control of
                  the things around you. You may turn to food as it is one of
                  the few things you feel in control of.
                </p>

                <h2>What causes addiction?</h2>

                <p>
                  The roots of everyone’s addictions are unique. Your past
                  experiences will all lead to either a life with or without
                  addiction. However, how you process those past experiences has
                  a significant effect on your future surrounding addiction.
                </p>

                <p>
                  Once your addictive activities become normalised within your
                  life, they start becoming a part of the way you relate to
                  yourself and how others relate to you.&nbsp;
                </p>

                <p>
                  Trauma plays a large role at the root of many people’s
                  addictions. A traumatic experience is something that you are
                  unable to prepare for. Trauma gives you a feeling of a
                  complete lack of control.{" "}
                </p>

                <p>
                  This then takes a long time to process, but if you choose to
                  attempt to bury it, your brain will seek out methods of quick
                  relief. You will then quickly learn that quick relief is just
                  that – quick. And you will want to go back to that relief,
                  again and again.
                </p>

                <p>
                  Trauma is one of many other experiences or events that may
                  lead to addiction. Others include:
                </p>

                <ul>
                  <li>
                    Loss/<a href="#">Bereavement</a>
                  </li>
                  <li>
                    <a href="#">Anxiety</a> leading to{" "}
                    <a href="#">Depression</a>{" "}
                  </li>
                  <li>
                    <a href="#">Work Issues</a>
                  </li>
                  <li>Bullying</li>
                  <li>
                    <a href="#">Relationship Issues</a>
                  </li>
                </ul>

                <p>
                  Addiction has also been proven to be hereditary, but that does
                  not mean that every child of an addict will then become an
                  addict. It means instead that you are at increased risk of
                  adopting addictive tendencies, and it is something you can
                  learn to manage and deter. This is why therapy is a highly
                  beneficial process for someone not just going through
                  addiction, but also with addictive tendencies.
                </p>

                <h2>How therapy can help with addiction.</h2>

                <p>
                  It is not just about the relationship to these actions or
                  substances, but the psychological and physical effect of these
                  actions.
                </p>

                <p>
                  In gambling, the gambler gets hooked not on winning, but more
                  the rush of betting. It is the action and effect of making the
                  bet rather than the monetary gain of gambling. Consider how
                  excited you feel watching that roulette wheel spin or holding
                  your lottery ticket when the first number hasn’t even been
                  called yet. A gambling addict craves that rush and will not be
                  able to find it anywhere else.&nbsp;
                </p>

                <p>
                  This is why an addict can never ‘win’ enough. It is the
                  action, effect <i>and</i> reward from the addiction.&nbsp;
                </p>

                <h2>
                  How can <em>Therapy in London</em> help?
                </h2>

                <p>
                  It is never too early or too late to confront an addiction,
                  and <a href="#">there are many forms of counselling</a> that
                  can cater to every single unique individual. Counselling needs
                  to offer a space for any addict to feel like they are making
                  progress right from the beginning. This is why Therapy in
                  London offers a free telephone consultation to tailor your
                  therapy sessions to you.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Addiction;

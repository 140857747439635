import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../app/lib/firebaseClient";
import Loader from "../../components/elements/loader";
import LayoutSignUp from "../../components/layouts/layout-signUp";
import ForgotPassword from "../therapists/account/forgotPassword";
import SignIn from "../therapists/account/signIn";
import SignUp from "../therapists/account/signUp";

export const AuthRoutes = () => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && location.pathname !== "/portal/forgot-password") {
      navigate("/therapist/profile");
    }
  }, [user, navigate]);

  if (loading) return <Loader />;

  if (user && location.pathname !== "/portal/forgot-password") return null;
  return (
    <Routes>
      <Route element={<LayoutSignUp />}>
        {!user && <Route path="signin" element={<SignIn />} />}
        {!user && <Route path="signup" element={<SignUp />} />}
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
    </Routes>
  );
};

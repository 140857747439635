import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { routeMetadata } from "../../pages/meta/routeMetadata";

const MetaData = () => {
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/$/, "");

  const metadata = Object.keys(routeMetadata).reduce(
    (acc, path) => {
      if (
        currentPath === path ||
        (currentPath.startsWith(path) && path.length > acc.length)
      ) {
        return { ...routeMetadata[path], length: path.length };
      }
      return acc;
    },
    {
      title: "Find a Therapist - Therapy in London",
      description:
        "Connect with the right therapist today. Start your journey to growth and real change with Therapy in London.",
      keywords: "",
      length: 0,
    },
  );

  return (
    <>
      <Helmet title={metadata.title} defaultTitle="Find a Therapist - Therapy in London">
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
      </Helmet>
    </>
  );
};

export default MetaData;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function ADHD() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">ADHD</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for ADHD (Attention Deficit/Hyperactivity Disorder)</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/ADHD.jpg" alt="ADHD article image" />
                </div>
              </div>
              <div className="content">
                <p>ADHD is a condition most commonly diagnosed in children between 6-12. It can be seen a lot in children within this age range. But it’s important to differentiate between a curious/excitable child and one with ADHD.</p>
                <p>If you have suspicions that your child may have ADHD, then it is important to speak to your general practitioner for advice or a referral.</p>
                <h2>What is less-commonly focused on is ADHD in adults.</h2>
                <p>We usually associate the hyperactivity side of the disorder with Attention Deficit. This comes from a lack of ability to focus. On the surface, it seems as if you are flitting from one thing to another with high energy, but rather, your mind is attempting to focus on several things simultaneously.</p>
                <p>If you take into account the busy and demanding life that a typical adult leads, they will exhibit symptoms such as:</p>
                <ul>
                  <li>Hyperfocusing on something you enjoy and even losing perception of time.</li>
                  <li>Starting several tasks one after the other but not completing them.</li>
                  <li>Putting things down unconsciously.</li>
                  <li>‘Spacing out’ if you aren’t fully engaged in an activity or discussion.</li>
                </ul>
                <p>If you consider how these symptoms look to others they are commonly misconstrued as:</p>
                <ul>
                  <li>Forgetfulness</li>
                  <li>Inconsideration</li>
                  <li>‘Not listening’ to others</li>
                  <li>Procrastination</li>
                  <li>Messiness</li>
                </ul>
                <p>This is a disorder that once diagnosed, can be managed and even used to your advantage through therapy and counselling.</p>
                <h2>What causes ADHD.</h2>
                <p>ADHD is linked to family history- similar to many disorders. This may not be the case for many.</p>
                <p>There are no set causes for ADHD, but once diagnosed it is manageable through various forms of therapy and learning the skills and tools to manage it. If you have ADHD, your mind simply works differently from others. And anyone as an individual must learn how they can be the most productive and most successful in their life, whether you have ADHD or not.</p>
                <p>It’s important to understand that once you accept and learn to work with this disorder, you can use these traits to work with you rather than against you.</p>
                <h2>How therapy can help.</h2>
                <p>If you have ADHD, you will normally be referred to CBT (Cognitive Behavioural Therapy). This modality allows you to learn tools to control your thoughts, feelings and actions.&nbsp;</p>
                <p>This has been widely beneficial for many and very accessible throughout the UK.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>We want to not only teach you the tools to manage your ADHD, but also help you to understand how it has affected your life up until this point.</p>
                <p>As an adult diagnosed with ADHD you will likely find yourself looking back on your past actions and behaviours with a new perspective. It’s important to look back to understand your habits and how your mind works, to then use this reflection to better your life for the foreseeable future.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default ADHD;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function SelfHarm() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Self Harm</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Self Harm</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/self-harm.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Self harm affects people of all ages and can present itself in different ways.</p>
                <p>No matter if a person who is self-harming has been doing it for a long time or if it is a recent behavior, talking therapy is the most beneficial way to understand your motivations and to change that narrative for yourself.</p>
                <p>What is important from a mental health standpoint is firstly recognizing, then coming to terms with your actions. While there are many people that will suffer from self-harm, it is still something that can feel intensely private. It can, therefore, help to find a therapist and environment that you are comfortable in to address it.</p>
                <h2>What is Self Harm</h2>
                <p>Self-harm is the action of inflicting damage or injury to one’s own body such as throwing yourself against a wall or cutting yourself.</p>
                <p>It can appear in many different types of intentional actions including:</p>
                <ul>
                  <li>Cutting</li>
                  <li>Scratching</li>
                  <li>Burning</li>
                  <li>Bruising</li>
                </ul>
                <p>While it is most often thought of in the form of cutting, any act of deliberately injuring yourself is considered self-harm.</p>
                <p>It is important to also differentiate between self-harm and <a href="https://www.therapyin.london/find-help-for/suicidal-thoughts/">suicidal thoughts</a> or idealization.</p>

                <h3>If you believe you or someone you know has suicidal thoughts or idealization, you can <a href="https://www.samaritans.org/">call the Samaritans</a> 24 hours a day, 7 days a week for help and support on 116 123.</h3>

                <h2>What causes Self Harm?</h2>
                <p>Some people harm themselves as a way of dealing with difficult feelings, overwhelming situations, or painful memories.</p>
                <p>For some, turning overwhelming feelings into physical pain can be an attempt to distract from any hard-to-deal-with emotions.</p>
                <p>For others, it can be a form of punishment for any self-blame or regret from past experiences or traumas.</p>
                <p>Self-harm can be the result of not fully addressing already existing mental issues or disorders.</p>
                <p>These can range from:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">Anxiety</a> then leading to <a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">depression</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/trauma/" target="_blank" rel="noreferrer noopener">Trauma</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/loneliness/" target="_blank" rel="noreferrer noopener">Loneliness</a> or an intense feeling of ‘emptiness’</li>
                  <li><a href="https://www.therapyin.london/find-help-for/bereavement/" target="_blank" rel="noreferrer noopener">Bereavement</a></li>
                </ul>
                <p>Also, any disorders either undiagnosed or ignored can lead to a need for control through harming yourself, including:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/BPD/" target="_blank" rel="noreferrer noopener">BPD</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/ocd/" target="_blank" rel="noreferrer noopener">OCD</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/bipolar-disorder/" target="_blank" rel="noreferrer noopener">Bipolar Disorder</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/body-dysmorphia/" target="_blank" rel="noreferrer noopener">Body Dysmorphia</a></li>
                </ul>
                <p>There may be a particular event that leads to self-harm such as:</p>
                <ul>
                  <li>Feeling overwhelmed with school or work</li>
                  <li>Experiencing a traumatic event or abuse</li>
                  <li>Losing someone close to you</li>
                  <li>Losing a job</li>
                </ul>
                <p>You may even feel like there is no specific reason and some people may not know why they self-harm. This is why counseling is the first step to getting down to the root of the issue to find out where it all began.</p>
                <p>No matter what the reason, harming yourself only provides temporary relief without addressing the deeper, underlying issue.</p>
                <h2>How therapy can help.</h2>
                <p>Going to therapy can be a way of understanding the feelings and issues that lead to self-harm. A therapist can offer you support in a non-judgmental environment as you discover new ways to approach situations.</p>
                <p>For many people who have experienced this, Cognitive Behavioral Therapy (CBT) can prove very helpful as a way to create new habits and reactions to situations that may trigger self-harming.</p>
                <p>Other forms of therapy such as Integrative therapy will assist you in fully understanding why you do what you do and how you create a positive future for yourself away from self-harming. It is important, whatever you choose, that you find the therapy and <a href="https://www.therapyin.london/our-therapists/" target="_blank" rel="noreferrer noopener">therapist that is right for you.</a></p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>No matter what feelings you are experiencing that are leading to self-harm, Therapy in London provides a safe space for you to come to terms with your fears and feelings, away from judgment.</p>
                <p>Everything you share with us will be entirely confidential as we work towards guiding you to a place where you no longer feel the need to self-harm.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default SelfHarm;

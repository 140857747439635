import { api } from "../../lib/createClient";

export const getArticleById = async (articleId) => {
  try {
    const response = await api.get(`/article/${articleId}`);
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

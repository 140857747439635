import React from "react";

import parse from "html-react-parser";
export function lazyImport(factory, name) {
  return Object.create({
    [name]: React.lazy(() =>
      factory().then((module) => ({ default: module[name] }))
    ),
  });
}
export const truncateAndStripHTML = (content, length = 50) => {
  const parsed = parse(content);
  let text = "";

  const extractText = (element) => {
    if (typeof element === "string") {
      return element;
    }
    if (React.isValidElement(element)) {
      if (element.props.children) {
        return React.Children.toArray(element.props.children)
          .map(extractText)
          .join("");
      }
      return "";
    }
    return "";
  };

  if (typeof parsed === "string") {
    text = parsed;
  } else if (Array.isArray(parsed)) {
    text = parsed.map(extractText).join("");
  } else if (React.isValidElement(parsed)) {
    text = extractText(parsed);
  }

  text = text.replace(/\[object Object\],?/g, "");

  return (
    text.replace(/\s+/g, " ").trim().slice(0, length) +
    (text.length > length ? "..." : "")
  );
};

export const randomSeed = () => {
  const newSeed = Number((Math.random() * 2 - 1).toFixed(5));
  return newSeed;
};

export const setSeed = (seed) => {
  localStorage.setItem(
    "searchSeed",
    JSON.stringify({ value: seed, isNewSearch: false })
  );
};

export const getSavedSeed = () => {
  const savedSeedObj = JSON.parse(localStorage.getItem("searchSeed"));
  return savedSeedObj ? savedSeedObj.value : null;
};

export const getIsNewSearch = () => {
  const savedSeedObj = JSON.parse(localStorage.getItem("searchSeed"));
  return savedSeedObj ? savedSeedObj.isNewSearch : true;
};

export const setIsNewSearch = (value) => {
  const savedSeedObj = JSON.parse(localStorage.getItem("searchSeed")) || {};
  savedSeedObj.isNewSearch = value;
  localStorage.setItem("searchSeed", JSON.stringify(savedSeedObj));
};

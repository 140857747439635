import { Route, Routes } from "react-router-dom";
import Couple from "../typesoftherapy/articles/couple";
import Family from "../typesoftherapy/articles/family";
import Marriage from "../typesoftherapy/articles/marriage";
import Online from "../typesoftherapy/articles/online";
import Personal from "../typesoftherapy/articles/personal";
import TypesOfTherapy from "../typesoftherapy/typesoftherapy";
import ShortTerm from "../typesoftherapy/articles/shortterm";

export const CounselingRoutes = () => {
  return (
    <Routes>
      <Route index element={<TypesOfTherapy />} />
      <Route path="personal-counselling" element={<Personal />} />
      <Route path="online-therapy" element={<Online />} />
      <Route path="family-counselling" element={<Family />} />
      <Route path="marriage-counselling" element={<Marriage />} />
      <Route path="couple-counselling" element={<Couple />} />
      <Route path="short-term-therapy" element={<ShortTerm />} />
    </Routes>
  );
};

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function PTSD() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">PTSD</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for PTSD (Post Traumatic Stress Disorder)</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/PTSD.jpg" />
                </div>
              </div>
              <div className="content">
                <h2>Post Traumatic Stress Disorder (PTSD) occurs when a traumatic event from your past continues to affect your day-to-day life.</h2>
                <p>The difference between post-traumatic stress and other severe mental illnesses such as <a href="https://www.therapyin.london/find-help-for/depression" target="_blank" rel="noreferrer noopener">depression</a> is that PTSD comes up as an almost repeated reliving of that trauma.</p>
                <p>Any <a href="https://www.therapyin.london/find-help-for/trauma/" target="_blank" rel="noreferrer noopener">trauma</a> that you experience can have a drastic effect on your life and your mental health. This trauma can have a certain impact on you, resulting in PTSD. This is all dependent on each individual and your experiences.</p>
                <p>PTSD doesn’t have to be one occurrence or a constant battle throughout your life. It can ebb and flow depending on what you are doing at that very moment and also how you choose to deal with your trauma.</p>
                <p>If it is not addressed as early as possible, PTSD can continue to have an effect on your regular day to day including:</p>
                <ul>
                  <li>Flashbacks causing panic attacks.</li>
                  <li>Not addressing the trauma causing depression.</li>
                  <li>Nightmares resulting in insomnia.</li>
                  <li>Physical pain from <a href="https://www.therapyin.london/find-help-for/stress/" target="_blank" rel="noreferrer noopener">stress</a> and tension.</li>
                </ul>
                <h2>What causes PTSD</h2>
                <p>There is no way of knowing what type of trauma can cause PTSD, and to whom. Importantly, when understanding PTSD is that it can be brought on many years down the line after your traumatic experience.</p>
                <p>Post-traumatic Stress is a disorder that essentially lets you know that something needs to be addressed. Trauma is a difficult experience for any individual. The most important aspect of managing, and even preventing, PTSD, is understanding that it must be addressed as soon as possible.</p>
                <h2>How can therapy help?</h2>
                <p>This is where therapy can be the most vital part of managing this issue. How PTSD has impacted your life will always be a delicate and private issue for you to discuss. Confidential counselling is the safest way to understand how you managed your trauma and how it is affecting you today.</p>
                <p>By having these discussions you can come to a full understanding of how PTSD doesn’t have to be a hindrance in your life anymore.</p>
                <h2>How Therapy in London can help.</h2>
                <p>We believe that any experience you have had is unique, and only something that you can fully feel and come to terms with yourself. Our practitioners want to create a safe space for you to go through these tough moments. Not only to leave them in your past but also to use them to rebuild yourself in the best way possible.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default PTSD;

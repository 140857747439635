import { useState } from "react";
import PropTypes from "prop-types";
import("../../styles/global.scss");
import("../../styles/sections.scss");

function FilterOptions({
  title,
  options,
  selectedOptions,
  onChange,
  isSingleSelect,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true); // Track collapse state

  const handleChange = (option) => {
    if (isSingleSelect) {
      onChange(option);
    } else {
      const isSelected = selectedOptions.includes(option);
      onChange(option, !isSelected);
    }
  };

  const isSelected = (option) => selectedOptions.includes(option);

  return (
    <div className="filter_block">
      <h3
        className="filter_title"
        onClick={() => setIsCollapsed(!isCollapsed)} // Toggle collapse
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        {title}
        <span
          className="chevron"
          style={{
            marginLeft: "auto",
            transform: isCollapsed ? "rotate(280deg)" : "rotate(360deg)",
            transition: "transform 0.2s",
          }}
        >
          <img src="./images/icons/chevron_down.svg" />
        </span>
      </h3>

      {!isCollapsed && (
        <div className="radio_wrap">
          {options.map((option) => (
            <div
              key={option}
              className={`radio_toggle ${isSelected(option) ? "active" : ""}`}
            >
              <label>
                <input
                  type={isSingleSelect ? "radio" : "checkbox"}
                  name={title}
                  value={option}
                  checked={isSelected(option)}
                  onChange={() => handleChange(option)}
                />
                <span
                  className={isSelected(option) ? "active" : ""}
                  htmlFor={title}
                >
                  {option}
                </span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

FilterOptions.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isSingleSelect: PropTypes.bool,
};

export default FilterOptions;

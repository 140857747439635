import { useEffect, useState } from "react";
import { useProfileStore } from "../../../app/stores";
import CustomInput from "./customInput";

export const BodyMembership = ({ index, onDelete }) => {
  const { profile, updateProfile } = useProfileStore((state) => ({
    profile: state.profile,
    updateProfile: state.updateProfile,
  }));
  const [error, setError] = useState("");

  const options = [
    "BPS, The British Psychological Society",
    "UKCP, The UK Council for Psychotherapy",
    "NCPS, The National Counselling & Psychotherapy Society",
    "BABCP, The British Association for Behavioural and Cognitive Psychotherapies",
    "ACP, Association of Child Psychotherapists",
    "BACP, British Association for Counselling and Psychotherapy",
    "COSRT, College of Sexual and Relationship Therapists",
    "FDAP, Federation of Drug & Alcohol Professionals",
    "HCPC, Health & Care Professions Council",
  ];

  const [selectedBody, setSelectedBody] = useState(
    profile.membershipBody[index]?.name || ""
  );
  const [membershipNumber, setMembershipNumber] = useState(
    profile.membershipBody[index]?.number || ""
  );
  useEffect(() => {
    if (selectedBody && membershipNumber) {
      const currentMembership = profile.membershipBody[index];
      if (
        !currentMembership ||
        currentMembership.name !== selectedBody ||
        currentMembership.number !== membershipNumber
      ) {
        const updatedMembershipBody = [...profile.membershipBody];
        updatedMembershipBody[index] = {
          name: selectedBody,
          number: membershipNumber,
        };
        updateProfile({
          membershipBody: updatedMembershipBody,
        });
      }
      setError("");
    } else if (selectedBody && !membershipNumber) {
      setError("Register number cannot be empty");
    }
  }, [
    selectedBody,
    membershipNumber,
    index,
    updateProfile,
    profile.membershipBody,
  ]);

  const handleBodySelect = (body) => {
    setSelectedBody(body);
  };

  const handleMembershipNumberChange = (number) => {
    setMembershipNumber(number);
    if (!number) {
      setError("Register number cannot be empty");
    } else {
      setError("");
    }
  };

  const filteredOptions = options.filter(
    (option) =>
      option === selectedBody ||
      !profile.membershipBody.some((mb) => mb.name === option)
  );

  return (
    <div className="multipleOptionsWrap">
      <div className="selectBlock">
        <label>Membership Body</label>
        <select
          value={selectedBody}
          onChange={(e) => handleBodySelect(e.target.value)}
        >
          <option value="" disabled>
            Body
          </option>
          {filteredOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <CustomInput
        title="Register Number"
        placeholder="No'"
        value={membershipNumber}
        onChange={handleMembershipNumberChange}
      />
      {error && <span className="error-message">{error}</span>}

      {index > 0 && (
        <button
          type="button"
          onClick={() => onDelete(index)}
          className="deleteCTA"
        >
          Delete
        </button>
      )}
    </div>
  );
};

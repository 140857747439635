import propTypes from "prop-types";

export const UnderLine = ({ color }) => {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="508"
      height="86"
      viewBox="0 0 508 86"
      fill={color}
    >
      <g clipPath="url(#clip0_768_2244)">
        <path
          d="M506.22 44.4145C499.504 44.6075 493.225 44.5479 486.993 45.0123C468.86 46.3613 450.743 47.9417 432.624 49.3818C424.467 50.0241 416.289 50.5147 408.123 51.1364C402.847 51.5415 397.594 52.2086 392.314 52.5033C386.743 52.8095 381.148 52.7132 375.569 52.9988C370.944 53.2351 366.328 53.8125 361.705 54.1891C360.355 54.3034 358.988 54.2163 357.632 54.2601C344.012 54.7645 330.398 55.3395 316.774 55.7634C308.791 56.016 300.788 55.9667 292.8 56.1588C284.772 56.3484 276.749 56.7687 268.724 56.8984C257.108 57.0897 245.488 57.1906 233.866 57.171C225.4 57.1523 216.937 56.7731 208.471 56.7544C197.69 56.7384 186.914 56.9632 176.129 57.0071C171.9 57.028 167.668 56.7582 163.441 56.7292C152.525 56.6444 141.617 56.5802 130.706 56.5659C118.842 56.5511 106.98 56.6766 95.1131 56.5713C86.911 56.4992 78.7168 56.1472 70.5256 55.9055C62.635 55.6629 54.7445 55.4204 46.8577 55.1179C43.0391 54.9747 39.2232 54.6312 35.394 54.4973C32.1648 54.3816 28.9008 54.6544 25.6925 54.3697C18.5391 53.7233 11.2086 54.5986 4.26037 51.9912C3.14495 51.5694 1.86246 51.5678 0.543424 51.3534C-0.115645 48.646 0.420746 46.2052 1.72112 43.8832C2.04935 43.2929 2.58079 42.8158 2.86973 42.2129C4.66155 38.4694 4.64776 38.5286 8.53242 38.7362C30.9329 39.9139 53.3368 41.3523 75.7527 42.1301C93.1 42.7251 110.482 42.4606 127.847 42.6158C145.353 42.77 162.855 42.984 180.351 43.1275C197.307 43.2667 214.27 43.4364 231.225 43.4252C249.002 43.4162 266.782 43.3474 284.558 43.0378C300.248 42.7556 315.942 42.0928 331.624 41.6196C342.145 41.3084 352.676 41.1482 363.184 40.726C369.594 40.4633 375.994 39.8793 382.391 39.3453C403.234 37.6079 424.069 35.84 444.903 34.0721C452.51 33.4248 460.113 32.677 467.721 32.1601C470.127 31.9928 472.571 32.6396 474.991 32.5534C482.222 32.303 489.438 31.8011 496.667 31.5707C498.665 31.5077 500.852 31.627 502.647 32.4028C506.011 33.8498 507.877 38.8486 506.836 42.4497C506.616 43.2273 506.347 43.9917 506.21 44.4138L506.22 44.4145Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_768_2244">
          <rect
            width="505.32"
            height="53.07"
            fill="white"
            transform="translate(3.37671) rotate(3.64807)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

UnderLine.propTypes = {
  color: propTypes.string.isRequired,
};

import { motion } from "framer-motion";
import MetaData from "../../components/fragments/MetaData";
import("../../styles/findhelpfor.scss");
import("../../styles/global.scss");
import { useInfiniteQuery } from "@tanstack/react-query";
import { getBlogs } from "../../app/api/blogs/getBlogs";
import { useSearchParams } from "react-router-dom";
import debounce from "lodash/debounce";
import { useEffect, useCallback, useRef, useState, useMemo } from "react";
import LoaderSearch from "../../components/elements/loaderSearch";
import { ThumbnailBlog } from "../../components/elements/blog/thumbnailBlog";
import { decode } from 'html-entities';

const staggeredVariants = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};
// Define the transition variants for blocks and text
const slideUpVariant = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const createDebouncedSearch = (callback) =>
  debounce(callback, 500);

export const BlogPage = () => {
  const observer = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("query") || "");

  const updateSearchParams = useCallback((value) => {
    if (value) {
      setSearchParams({ query: value });
    } else {
      setSearchParams({});
    }
  }, [setSearchParams]);

  const debouncedSearch = useMemo(
    () => createDebouncedSearch(updateSearchParams),
    [updateSearchParams]
  );

  useEffect(() => {
    debouncedSearch(search);
    return () => debouncedSearch.cancel();
  }, [search, debouncedSearch]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["blogs", searchParams.toString()],
    queryFn: ({ pageParam = 1 }) =>
      getBlogs({
        page: pageParam,
        pageSize: 10,
        query: searchParams.get("query") || "",
      }),
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.blogs?.length === 10 ? pages.length + 1 : undefined;
    },
    enabled: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  const lastBlogRef = useCallback(
    (node) => {
      if (!node || !hasNextPage || isFetchingNextPage) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      observer.current.observe(node);
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage]
  );

  const blogItems = useMemo(() => {
    if (!data?.pages) return null;

    return data.pages.map((page, pageIndex) =>
      page.blogs.map((item, index) => {
        const isLastItem =
          pageIndex === data.pages.length - 1 &&
          index === page.blogs.length - 1;

        return (
          <ThumbnailBlog
            key={item.id}
            ref={isLastItem ? lastBlogRef : null}
            imageSrc={item.picture}
            title={decode(item.title)}
            desc={decode(item.description)}
            date={item.createdAt}
            blogId={item.id}
            slug={item.slug}
          />
        );
      })
    );
  }, [data?.pages, lastBlogRef]);

  return (
    <>
      <MetaData title="Therapy in London • Blog" />
      <div className="fhf_wrap">
        <div className="fhf">
          <div className="fhf_controls">
            <div className="alphabet_links_wrap">
              <motion.div
                className="title"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <h1>Therapy in London Blog</h1>
                {/* <img
                  src="../images/illustrations/titleWrapSquare.svg"
                  alt="Title Wrap"
                /> */}
              </motion.div>

              <motion.div
                className="articlesControls"
                initial="hidden"
                animate="show"
                variants={staggeredVariants}
              >
                <motion.div variants={itemVariants} className="searchArticles">
                  <label>Search Blog</label>
                  <input
                    value={search}
                    type="text"
                    placeholder="Search"
                    className="search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
          <div className="fhf_content_wrap">
            <div className="fhf_content">
              {isLoading ? (
                <LoaderSearch subtitle="Loading..." />
              ) : data?.pages[0]?.blogs?.length === 0 ? (
                <div className="no-results">No results found</div>
              ) : (
                blogItems
              )}
              {isFetchingNextPage && (
                <LoaderSearch subtitle="Loading more..." />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

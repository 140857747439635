import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function HowToMakeTheMost() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../how-to/">How to</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">How to Get the Most from Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>How to Get the Most from Therapy</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/howTo/get-the-most-out-of-therapy.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  You are now starting or continuing your therapeutic journey. But you want to make sure that you are getting the most from therapy and creating a bond with your therapist.
                </p>

                <p>
                  There are many questions asked about therapy and how to make it work for you. Once you are ready, however, you can create a positive, effective, and even enjoyable therapy experience.
                </p>

                <p>
                  So if you want to get the most out of therapy, there are a few things that you can focus on:
                </p>

                <h2>1. Should I like my therapist?</h2>

                <p>
                  First and foremost always comes down to the therapist you choose. Before you can even start feeling the freedom to open up, you need to feel trust and safety with your therapist.
                </p>

                <p>
                  A therapist has their own therapeutic style and their own experiences. Each therapist isn’t one-size-fits-all. You know who you are able to trust. You know the kind of people you are able to connect with.
                </p>

                <p>
                  Once you feel like you have found a strong therapeutic relationship, then you can fully utilise what therapy can offer you.
                </p>

                <h2>2. What should I expect from therapy?</h2>

                <p>
                  It is completely normal to feel you need therapy, but not know the reason why. Therapy is hugely beneficial in giving your stability, routine and uninterrupted time for just you.
                </p>

                <p>
                  That is not to say however that what you hope to achieve from therapy will happen. Your expectations matter enormously in how successful your therapy journey is. Therapy takes time to really get to grips with your past and what you want for the future, which is why short-term therapy can usually only be beneficial in the short-term, too.
                </p>

                <p>
                  The same goes for what you expect to get from your therapist. There are many common expectations about therapy including:
                </p>

                <ul>
                  <li><strong><em>You will get direct advice from your therapist</em>.</strong><br />But a therapist is there to guide and support you, not tell you what to do.</li>
                  <li><em><strong>You will leave the first session feeling better.</strong></em><br />But an unstable structure will usually need to be knocked down to be rebuilt stronger than it was before. A lot of the time, tapping into difficult emotions will be the basis for rebuilding yourself.</li>
                  <li><em><strong>A therapist is there for you whenever you want.</strong></em><br />But a large part of therapy is creating structure within your life, which includes booked-in sessions and established boundaries between therapist and client. This is the most beneficial way to achieve positive change within therapy.</li>
                </ul>

                <p>
                  So ask yourself what you think therapy will be like, and even communicate that to your therapist. By doing this you can start with a clear understanding between the two of you.
                </p>

                <h2>3. Do I need to be honest in therapy?</h2>

                <p>
                  Honesty is key in therapy, but it is still understood by many practitioners that honesty isn’t always the easiest thing for someone starting therapy.
                </p>

                <p>
                  If you are dishonest during your sessions, take a mental note of when you lie and why you did it. You will then eventually be able to communicate to your therapist about the lies and work through them together.
                </p>

                <p>
                  Lies are, in most cases, a defense mechanism so it is important to work towards being honest with your therapist. Then you can understand why you do it and how to rework the lying in your life.
                </p>

                <h2>4. What happens if I tend to word vomit in therapy?</h2>

                <p>
                  Therapists love to hear anything and everything on your mind. No one’s thoughts are chronological and we don’t expect that in sessions.
                </p>

                <p>
                  A therapist wants to hear about what you are passionate about and start to relate any themes to your recollections or thoughts.
                </p>

                <p>
                  You will never be expected to hold back. A lot can be said for voicing whatever is on your mind, rather than planning what you say.
                </p>

                <h2>5. Will there be homework in therapy?</h2>

                <p>
                  Many practitioners will end some sessions with some homework. It may reflect what you have talking about in sessions or what you’ve been building up to addressing.
                </p>

                <p>
                  This doesn’t mean that you will be scorned for not completing it. Homework is given to you as a way of you integrating what you have been discussing into your real life. It can become easy to talk about something within the security of the therapy office. But instigating it is the real challenge.
                </p>

                <p>
                  This is why, even if you find yourself unable to complete homework, still consider why you couldn’t as that will likely be the focus of your next session.
                </p>

                <h2>6. I feel good today, should I still go to therapy?</h2>

                <p><strong>Absolutely</strong>. Your good days are when you have clarity on what you have accomplished, what you love, and your goals for the future. This is the time when you are able to discuss how you can keep all the good things within your life and keep hold of that feeling.</p>

                <h2>7. I feel bad today, do I still have to go to therapy?</h2>

                <p>Again, <strong>absolutely</strong>. Everyone will have days where you feel like you do not want to talk to anyone. Your sessions are so important in these times. Therapy creates a secure space to feel however you want, which is why, on bad days, the best place to go is to your therapy session.</p>

                <p>
                  Even if you decide to sit in your session, there is no expectation to say or do anything. By attending during this time, you are embracing how you are feeling, and able to relate back to it in the future to what the trigger was, and how you can amend that for the future.
                </p>

                <h2>8. How long do I have to go to therapy?</h2>

                <p>
                  At Therapy in London, we are sticklers for therapy being ingrained in everyone’s lives. On the good days, and the bad days, therapy is able to be there throughout yourself to help you reflect and refocus on what you have and want for your life.
                </p>

                <p>
                  A bundle of sessions can be offered to help you understand the effects of a certain amount of sessions, but the most benefit is found in ongoing, regular sessions for anyone in all walks of life.
                </p>

                <p>
                  Breaks in therapy are also beneficial at times when you are ready to find out how you are able to introduce what you have learned about yourself into your life on your own. These kinds of sabbaticals can be very useful in also finding out what you would like to focus on once you are ready to restart sessions again.
                </p>

                <p>
                  Whether you are starting, continuing, or have been in therapy for years, so long as you are making the most of your sessions with an understanding of what you want from it, you will find benefit in.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default HowToMakeTheMost;

import { Route, Routes } from "react-router-dom";
import LayoutSignedIn from "../../components/layouts/layout-signedIn";
import LayoutNoNav from "../../components/layouts/layoutNoNav";
import { TherapistBilling } from "../therapists/TherapistBilling";
import TherapistAdmin from "../therapists/therapist-admin";
import TherapistAdminArticles from "../therapists/therapist-admin-articles";
import TherapistAdminSettings from "../therapists/therapist-admin-settings";
import TherapistAvailableArticles from "../therapists/therapist-availableArticles";
import TherapistProfileEdit from "../therapists/therapist-profile-edit";
import WriteArticle from "../therapists/writeArticle";

export const DashboardRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutSignedIn />}>
        <Route path="profile" element={<TherapistAdmin />} />
        <Route path="articles" element={<TherapistAdminArticles />} />
        <Route path="settings" element={<TherapistAdminSettings />} />
        <Route path="billing" element={<TherapistBilling />} />
        <Route
          path="articles/available"
          element={<TherapistAvailableArticles />}
        />
      </Route>
      <Route element={<LayoutNoNav />}>
        <Route path="profile/edit" element={<TherapistProfileEdit />} />
        <Route
          path="articles/available/write/:aid"
          element={<WriteArticle />}
        />
        <Route path="articles/edit/:id" element={<WriteArticle />} />{" "}
        {/* Add this route */}
      </Route>
    </Routes>
  );
};

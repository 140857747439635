export const LeftLine = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="6"
    viewBox="0 0 17 6"
    fill="none"
  >
    <path
      d="M1.01104 3.89466C0.692475 2.7428 0.377603 1.59924 -2.52203e-07 0.230278C0.634669 0.149068 1.22875 -0.0713587 1.79577 0.0231098C5.11179 0.575004 8.42412 1.10866 11.7734 1.15838C12.9111 1.17496 14.0513 1.35892 15.1804 1.57272C15.649 1.66222 16.1127 1.97048 16.5371 2.28041C17.093 2.6848 17.1705 3.41404 16.6478 3.82506C15.7142 4.56092 14.8274 5.39455 13.6282 5.4277C11.3047 5.49068 8.98254 5.73596 6.6628 5.96633C5.01709 6.13041 3.50298 5.70283 2.10326 4.50623C1.80438 4.251 1.44153 4.13166 1.01227 3.89632L1.01104 3.89466Z"
      fill={color}
    />
  </svg>
);

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Postnatal() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Postnatal Depression</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Postnatal Depression</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/post-natal-depression.jpg" />
                </div>
              </div>
              <div className="content">
                <h3>Having a child can bring a lot of emotions to the surface.</h3>
                <p>It takes an enormous amount of thought, planning, physical and emotional strength. This doesn’t take into account the drastic change that a woman’s body will go through to recover from all of these changes.</p>
                <p>Postnatal Depression occurs after having your child and is just as serious a form of <a href="http://therapyin.london/find-help-for/depression" target="_blank" rel="noreferrer noopener">depression</a> as outside of childbirth. Sadly, this mental issue is commonly overlooked. As a new parent, you may blame a lot of your feelings and emotions on several things including:</p>
                <ul>
                  <li>Lack of Sleep</li>
                  <li>Learning so many new things about being a parent</li>
                  <li>Your physical recovery</li>
                  <li>The pressure of having a connection and ‘instinctual love’ for your baby</li>
                </ul>
                <p>After having your child, your body has to recover from the shock of no longer needing to nurture what was once the fetus you were carrying. This includes all of the pregnancy hormones.</p>
                <p><a href="https://www.nhs.uk/conditions/baby/support-and-services/feeling-depressed-after-childbirth/" target="_blank" rel="noreferrer noopener">Baby Blues</a> is very common around the first week after childbirth. You may feel a surge of emotions as your body tries to shift from ‘pregnancy mode’ to ‘post-pregnancy mode.’ It usually lasts a day or two and is an overwhelming feeling of either, some, or all of:</p>
                <ul>
                  <li>Sadness</li>
                  <li>Love</li>
                  <li>Fear</li>
                  <li>Excitement</li>
                  <li>Melancholia</li>
                </ul>
                <p>It’s important to understand and clarify the difference between baby blues and postnatal depression. Baby blues can be a fleeting feeling of overwhelmedness, but if those feelings don’t lessen after a few weeks and you feel out of your control, then you may have postnatal depression. If this is not addressed, it can escalate into something severe (but very rare) such as <a href="https://www.nhs.uk/mental-health/conditions/post-partum-psychosis/" target="_blank" rel="noreferrer noopener">Postpartum Psychosis</a>.</p>
                <p>This is why it is important for yourself and your loved ones to always consider your mental health and to regularly check in with yourself, during and after the postnatal period.</p>
                <h2>What causes Postnatal Depression</h2>
                <p>You don’t choose to feel depressed. The cause of your depression cannot be pinpointed to one specific thing or a certain type of person.</p>
                <p>The cause of your depression does not come from your background, but rather the life you have led up to the point of feeling depressed. By taking note of what you are feeling in your depressed state and what triggers it, you can start to build a picture of what you can do to release yourself from feeling depressed.</p>
                <p>The symptoms of postnatal depression can vary depending on every mother and their unique situation, the most common symptoms include:</p>
                <ul>
                  <li>A lack of connection or love for your baby</li>
                  <li>Feeling like you are an unfit parent</li>
                  <li>Wanting, considering, or attempting to run away</li>
                  <li>Feeling sad all the time</li>
                  <li>A lack of drive and motivation</li>
                  <li>Pushing away loved ones</li>
                </ul>
                <p>You need to understand that you can work through your issues and feelings, no matter what your symptoms are during postnatal depression. You need to understand your feelings about yourself and others in terms of where they came from and what those thoughts/actions are trying to tell you.</p>
                <h2>How therapy can help you.</h2>
                <p>By seeking counselling for postnatal depression, you are taking the first step to understanding that this is something within your control.</p>
                <p>Your counsellor is a neutral presence in your life who believes that no issue is too big or too small. The stigma around being the ideal parent is a pressure that most new mothers feel, but your counselling sessions are able to give you that space to feel, say and think anything you want, in order to become the healthiest version of yourself, for you and your family.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Our practitioners want to understand and guide you through how you are feeling following the birth of your baby. Postnatal depression happens to so many but few address it. We want to provide that confidential and private space for you and you alone so that you can focus on bettering yourself in the world, and come to an understanding of what being a new parent means to you.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Postnatal;

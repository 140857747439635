import { Link } from "react-router-dom";
import("../../../styles/articles.scss");
import("../../../styles/buttons.scss");
import("../../../styles/global.scss");
import("../../../styles/sections.scss");

import PropTypes from "prop-types";

AvailableArticles.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  brief: PropTypes.string.isRequired,
  articleId: PropTypes.string.isRequired,
};
function AvailableArticles({ labels, title, brief, articleId }) {
  return (
    <div className="availableArticles_block">
      <div className="topic">
        {labels.map((label) => (
          <label key={label.name} className="label">
            {label.name}
          </label>
        ))}
      </div>

      <div className="about">
        <h2>{title}</h2>
        <p>{brief}</p>
        <Link
          className="cta_ghost_blue2"
          to={`/therapist/articles/available/write/${articleId}`}
        >
          Write Article
        </Link>
      </div>
    </div>
  );
}

export default AvailableArticles;

import { motion } from "framer-motion";
import CTA from "../../components/fragments/CTA";
import("../../styles/global.scss");
import("../../styles/sections.scss");
import("../../styles/typesoftherapy.scss");
import("../../styles/findhelpfor.scss");

// Define the transition variants for blocks and text
const slideUpVariant = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

function HowTo() {
  return (
    <div className="fhf_wrap">
      <div className="fhf">
        <div className="fhf_controls">
          <div className="alphabet_links_wrap">
            <motion.div
              className="title"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={slideUpVariant}
            >
              <h1>Your Guide on the "How to's" of Therapy</h1>
              <p>
                You will find the most benefit from therapy once you make the first move. Whatever you are ready to do from now, you can start here, where we have laid out the entire process of starting therapy.
                Therapy shouldn’t feel like something that you want to delay or push aside every week.
              </p>
            </motion.div>


          </div>
        </div>
        <div className="tot_content_wrap">
          <div className="tot_content">
            <a className="row-forward" href="../how-to/find-a-therapist">
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <div className="description">
                  <div className="title">
                    <h2>How to Find a Therapist</h2>
                    <img src="../images/illustrations/scribbleLarge.svg" alt="scribble decoration" />
                  </div>
                  <div className="content">
                    <p>If you want to start therapy but you don’t know how to find the right therapist, we are able to answer any question you have on therapy and finding a…</p>
                    <CTA type="ghost_blue" text="Learn More" link="../how-to/find-a-therapist" />
                  </div>
                </div>
              </motion.div>
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <div className="image">
                  <img src="../images/articles/howTo/thumbnails/how-to-find-a-therapist.png" alt="How to Find a Therapist" />
                </div>
              </motion.div>
            </a>
            <a className="row-forward" href="../how-to/get-the-most-from-therapy">
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <div className="description">
                  <div className="title">
                    <h2>How to Get the Most From Therapy</h2>
                    <img src="../images/illustrations/scribbleLarge.svg" alt="scribble decoration" />
                  </div>
                  <div className="content">
                    <p>You are now starting or continuing your therapeutic journey. But you want to make sure that you are getting the most from therapy and creating a bond with your therapist. …</p>
                    <CTA type="ghost_blue" text="Learn More" link="../how-to/get-the-most-from-therapy" />
                  </div>
                </div>
              </motion.div>
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <div className="image">
                  <img src="../images/articles/howTo/thumbnails/get-the-most-out-of-therapy.png" alt="How to Get the Most From Therapy" />
                </div>
              </motion.div>
            </a>
            <a className="row-forward" href="../how-to/end-therapy/">
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <div className="description">
                  <div className="title">
                    <h2>How to End Therapy</h2>
                    <img src="../images/illustrations/scribbleLarge.svg" alt="scribble decoration" />
                  </div>
                  <div className="content">
                    <p>You’ve started therapy and gone through a great journey with your therapist. Now, you are ready to see how you can manoeuvre in world without them. As you go through…</p>
                    <CTA type="ghost_blue" text="Learn More" link="../how-to/end-therapy/" />
                  </div>
                </div>
              </motion.div>
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <div className="image">
                  <img src="../images/articles/howTo/thumbnails/end-therapy.png" alt="How to End Therapy" />
                </div>
              </motion.div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowTo;

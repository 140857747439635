import { Link } from "react-router-dom";
import("../../../styles/articles.scss");
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import CTA from "../../fragments/CTA";

import propTypes from "prop-types";

AuthorWidget.propTypes = {
  author: propTypes.string,
  qual: propTypes.string,
  noArticles: propTypes.number,
  userId: propTypes.string,
  profilePicture: propTypes.string,
};

function AuthorWidget(props) {
  return (
    <div className="writerProfileWidget">
      <div className="block">
        <div className="image">
          <img src={props.profilePicture} alt="author-pp" />
        </div>

        <div className="details">
          <label>
            Written by{" "}
            <Link to={`/results/profile/${props.userId}`}>{props.author}</Link>
          </label>
          <label className="qualificationSymbols">{props.qual}</label>
          <div className="more">
            <label>
              <Link to={`/articles/${props.userId}`}>
                {props.noArticles} Articles
              </Link>
            </label>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="controls">
          <CTA
            type="blue"
            text="See Profile"
            link={`/results/profile/${props.userId}`}
          />
          {/* <CTA type="ghost" text="Contact" /> */}
        </div>
      </div>
    </div>
  );
}

export default AuthorWidget;

import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

let cachedToken = null;
let tokenExpiry = null;

const getIdToken = async () => {
  if (cachedToken && tokenExpiry && Date.now() < tokenExpiry) {
    return cachedToken;
  }

  const token = await auth.currentUser.getIdToken();
  cachedToken = token;
  tokenExpiry = Date.now() + (55 * 60 * 1000);
  return token;
};

export const getUser = async (id) => {
  const userId = id || auth.currentUser?.uid;
  
  if (!userId) {
    return null;
  }

  try {
    const token = await getIdToken();
    const response = await api.get(`/user/firebase/${userId}`, token);

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

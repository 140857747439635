import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import "react-toastify/dist/ReactToastify.css";
import { updateUser } from "../../app/api/auth/updateUser";
import { createProfile } from "../../app/api/profile/createProfile";
import { getUser } from "../../app/api/profile/getUser";
import { submitProfile } from "../../app/api/profile/submitProfile";
import { uploadCustomImage } from "../../app/api/profile/uploadCustomImage";
import { uploadInsurance } from "../../app/api/profile/uploadInsurance";
import { uploadProfilePicture } from "../../app/api/profile/uploadPP";
import useToast from "../../app/hooks/useToast";
import { queryClient } from "../../app/lib/createQueryClient";
import { useProfileStore } from "../../app/stores";
import Loader from "../../components/elements/loader";
import EditProfileDrag from "../../components/elements/profileEditDrag";
import CTA from "../../components/fragments/CTA";
import("../../styles/buttons.scss");
import("../../styles/global.scss");
import("../../styles/profiles.scss");

function TherapistProfileEdit() {
  const { notifyFailure, notifySuccess } = useToast();
  const { isSubmit, setIsSubmit } = useState(false);

  const { profile, updateProfile, clearSelectedPhoto, selectedPhoto } =
    useProfileStore((state) => ({
      profile: state.profile,
      updateProfile: state.updateProfile,
      clearSelectedPhoto: state.clearSelectedPhoto,
      selectedPhoto: state.selectedPhoto,
    }));

  const getUserQuery = useQuery({
    queryFn: async () => getUser(),
    queryKey: ["user"],
  });

  const createProfileMutation = useMutation({
    mutationFn: async (data) => createProfile(data),
    onSuccess: async () => {
      notifySuccess("Profile created successfully");
      if (isSubmit) {
        await handleSubmit();
        setIsSubmit(false);
      }
      queryClient.invalidateQueries({ queryKey: ["user"] });
      getUserQuery.refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to create profile: ${error.message}`);
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUser(),
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  const user = data?.user;
  const uploadPhotoMutation = useMutation({
    mutationFn: () => uploadProfilePicture(user.id, selectedPhoto),
    onSuccess: () => {
      notifySuccess("Profile picture uploaded successfully");
      queryClient.invalidateQueries({ queryKey: ["user"] });
      getUserQuery.refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to upload profile picture: ${error.message}`);
    },
  });

  const uploadInsuranceMutation = useMutation({
    mutationFn: (data) => {
      return uploadInsurance(user.id, data);
    },
    onSuccess: () => {
      notifySuccess("Insurance file uploaded successfully");
      queryClient.invalidateQueries({ queryKey: ["user"] });
      getUserQuery.refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to upload insurance file: ${error.message}`);
    },
  });
  const uploadCustomPhotoMutation = useMutation({
    mutationFn: () =>
      uploadCustomImage(user.id, profile.selectedFurniturePicture),
    onSuccess: () => {
      notifySuccess("Custom image uploaded successfully");
      updateProfile({ selectedFurniturePicture: null });
      queryClient.invalidateQueries({ queryKey: ["user"] });
      getUserQuery.refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to upload custom image: ${error.message}`);
    },
  });
  const updateUserMutatuion = useMutation({
    mutationFn: ({ userId, name, email, gender }) => {
      return updateUser({ userId, name, email, gender });
    },
    onSuccess: () => {
      getUserQuery.refetch();
    },
    onError: (error) => {
      notifyFailure("Failed to update profile, error:", error);
    },
  });

  const submitProfileMutation = useMutation({
    mutationFn: async () => submitProfile(user.profile.id),
    onSuccess: async () => {
      notifySuccess("Profile submitted for review successfully");

      queryClient.invalidateQueries({ queryKey: ["user"] });
      getUserQuery.refetch();
    },
    onError: (error) => {
      notifyFailure(`Failed to submit profile: ${error.message}`);
    },
  });

  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    if (user && user?.profile !== null) {
      updateProfile({
        ...user.profile,
        userId: user.id,
        name: user.name,
        email: user.email,
        phoneNumber: user.profile.phoneNumber,
        color: user.profile.color,
        lamp: user.profile.lamp === 0 ? 1 : user.profile?.lamp,
        sofa: user.profile.sofa === 0 ? 1 : user.profile?.sofa,
        plant: user.profile.plant === 0 ? 1 : user.profile?.plant,
        pricePackage: user.profile.pricePackage,
        membershipBody: user.profile.membershipBody,
        languages: user.profile.languages,
        insuranceFile: user.profile.insuranceFile,
        profileInfos: user.profile.profileInfos,
        specialisms: user.profile.specialisms,
        selectedPhoto: null,
        quote: user.profile.quote,
        typesOfTherapy: user.profile.typesOfTherapy || [],
        sessionTypes: user.profile.sessionTypes || [],
        wheelchairAccess: user.profile.wheelchairAccess,
        formattedAddress: user.profile?.location?.formattedAddress ?? "",
        photo: user.profile.profilePicture,
        furniturePicture: user.profile.furniturePicture,
        article1: user.profile.article1Id ?? null,
        article2: user.profile.article2Id ?? null,
        locationObj: user.profile.location ?? null,
        location: user?.profile?.location?.formattedAddress ?? "",
        qualifications: user.profile.qualifications,
      });
      return;
    }
    if (user) {
      updateProfile({
        userId: user.id,
        name: user.name,
        email: user.email,
      });
    }
  }, [user, updateProfile]);

  const handleColorClick = (color) => {
    updateProfile({
      color: color,
    });
  };
  const getFileSizeInKB = (file) => {
    return file.size / 1024;
  };

  const handleSave = async () => {
    const errors = [];

    profile.pricePackage.forEach((pkg, index) => {
      if (pkg.name.length > 100) {
        errors.push(
          `Price package ${index + 1} name must be 100 characters or less`
        );
      }
      if (pkg.price.length > 100) {
        errors.push(
          `Price package ${index + 1} price must be 100 characters or less`
        );
      }
    });

    if (profile.name.length > 100) {
      errors.push("Name must be 100 characters or less");
    }
    if (profile.name === "") {
      errors.push("Name cannot be empty");
    }

    if (
      profile.membershipBody.some((mb) => !mb.number || mb.number.trim() === "")
    ) {
      errors.push("All membership body numbers must be filled");
    }

    if (profile.quote && profile.quote.length > 255) {
      errors.push("Quote must be 255 characters or less");
    }

    if (selectedPhoto && selectedPhoto instanceof File) {
      const fileSizeKB = getFileSizeInKB(selectedPhoto);
      if (fileSizeKB > 500) {
        errors.push("Profile picture must be less than 500KB");
      }
    }

    if (profile.selectedFurniturePicture) {
      const fileSizeKB = getFileSizeInKB(profile.selectedFurniturePicture);
      if (fileSizeKB > 1000) {
        errors.push("Custom image must be less than 500KB");
      }
    }

    if (profile.pricePackage.length === 0) {
      errors.push("Price package is required");
    }

    if (errors.length > 0) {
      notifyFailure(errors.join(". "));
      return;
    }
    const profileData = {
      userID: profile.userId,
      lamp: profile.lamp,
      plant: profile.plant,
      sofa: profile.sofa,
      color: profile.color,
      typesOfTherapy: profile.typesOfTherapy,
      wheelchairAccess: profile.wheelchairAccess,
      languages: profile.languages,
      sessionTypes: profile.sessionTypes,
      specialisms: profile.specialisms,
      pricePackage: profile.pricePackage,
      membershipBody: profile.membershipBody,
      formattedAddress: profile.location,
      qualifications: profile.qualifications,
      quote: profile.quote,
      phoneNumber: profile.phoneNumber,
      article1: profile.article1,
      article2: profile.article2,
      profileInfos: profile.profileInfos.map((info) => ({
        id: info.dbId ? info.dbId : null,
        title: info.title,
        content: info.content,
        order: info.order,
        hidden: info.hidden,
        isThumbnail: info.isThumbnail,
      })),
      location: profile.locationObj,
    };

    // Only add profileId if it exists
    if (getUserQuery.data?.user?.profile?.id) {
      profileData.profileId = getUserQuery.data.user.profile.id;
    }

    try {
      await createProfileMutation.mutateAsync(profileData);

      if (selectedPhoto && selectedPhoto instanceof File) {
        await uploadPhotoMutation.mutateAsync();
      }
      if (profile.insuranceFile && profile.insuranceFile instanceof File) {
        await uploadInsuranceMutation.mutateAsync(profile.insuranceFile);
      }
      if (
        profile.selectedFurniturePicture &&
        profile.selectedFurniturePicture instanceof File
      ) {
        await uploadCustomPhotoMutation.mutateAsync();
      }

      if (profile.name && profile.name !== user.name) {
        await updateUserMutatuion.mutateAsync({
          userId: user.id,
          name: profile.name ? profile.name : user.name,
          email: user.email,
          gender: user.gender,
        });
      }

      notifySuccess("Profile saved successfully");
      const updatedUser = await getUserQuery.refetch();
      if (updatedUser.data) {
        updateProfile({
          ...updatedUser.data.user.profile,
          location: updatedUser.data.user.profile.location.formattedAddress,
        });
      }
    } catch (error) {
      notifyFailure(`Failed to save profile: ${error.message}`);
      throw error;
    }
  };
  const handleSubmit = async () => {
    const errors = [];
    // Validate insurance file
    if (!profile.insuranceFile) {
      errors.push("Insurance file is required");
    }
    if (
      profile.membershipBody.length === 0 ||
      profile.membershipBody === null
    ) {
      errors.push("Membership body is required");
    }

    if (
      profile.membershipBody.some((mb) => !mb.number || mb.number.trim() === "")
    ) {
      errors.push("All membership body numbers must be filled");
    }

    if (errors.length > 0) {
      notifyFailure(errors.join(". "));
      return;
    }

    try {
      await handleSave();

      await submitProfileMutation.mutateAsync();

      notifySuccess("Profile saved and submitted for review successfully");
    } catch (error) {
      notifyFailure(`Failed to save and submit profile: ${error.message}`);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="profileView_wrap profileEdit_wrap">
        <div className="profileView">
          <div className="profileView_controls_wrap editProfile_navFix">
            <div className="titleEdit">
              <h2>Edit Page</h2>
            </div>
            <div className="chooseThemeColor">
              <div className="title">
                <label>Icon Colour</label>
              </div>
              <div className="colorOptions">
                <button
                  type="button"
                  onClick={() => handleColorClick("#F6612E")}
                />
                <button
                  type="button"
                  onClick={() => handleColorClick("#D7BCED")}
                />
                <button
                  type="button"
                  onClick={() => handleColorClick("#01C476")}
                />
                <button
                  type="button"
                  onClick={() => handleColorClick("#3881f1")}
                />
              </div>
              <button
                type="button"
                className="cta_default"
                onClick={() => setShowColorPicker(!showColorPicker)}
                style={{
                  position: "relative",
                  backgroundColor: profile.color || "#F6612E",
                }}
              >
                Custom Colour
                {showColorPicker && (
                  <HexColorPicker
                    color={profile.color || "#F6612E"}
                    onChange={handleColorClick}
                  />
                )}
              </button>
            </div>
            <div className="controls">
              <CTA
                type="ghost"
                text="Return Home"
                link="/therapist/profile"
                onClick={clearSelectedPhoto}
              />
              <CTA type="blue" text="Save" onClick={handleSave} />
              {getUserQuery?.data?.user?.profile?.status === "draft" ||
                getUserQuery?.data?.user?.profile?.status === "rejected" ? (
                <CTA
                  type="blue"
                  text="Send for Review"
                  onClick={handleSubmit}
                  disabled={
                    createProfileMutation.isPending ||
                    submitProfileMutation.isPending
                  }
                />
              ) : (
                getUserQuery?.data?.user?.profile?.status === "approved"
              )}
              {getUserQuery?.data?.user?.profile?.status ===
                "awaitingApproval" && <label>Sent for review</label>}
            </div>
          </div>
          {createProfileMutation.isPending ||
            uploadPhotoMutation.isPending ||
            uploadInsuranceMutation.isPending ||
            uploadCustomPhotoMutation.isPending ? (
            <Loader subtitle="Loading..." />
          ) : (
            <EditProfileDrag />
          )}
        </div>
      </div>
    </>
  );
}

export default TherapistProfileEdit;

import("../../styles/global.scss");
import("../../styles/sections.scss");
import("../../styles/articles.scss");
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

export const Terms = () => {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../">Home</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Terms of Use</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Terms of Use</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
              </div>
              <div className="content">
                <h2>1. ABOUT THESE TERMS</h2>
                <p>1.1 These terms apply to your access and use of the Therapy in London website (<a href="https://www.therapyin.london" target="_blank">www.therapyin.london</a>). You should also review our Website Privacy Notice (<a href="https://www.therapyin.london/terms/privacy-policy" target="_blank">www.therapyin.london/terms/privacy-policy</a>) which explains what personal data we collect when you use our website, as well as our Acceptable Use Policy (<a href="https://www.therapyin.london/terms/acceptable-use" target="_blank">www.therapyin.london/terms/acceptable-use</a>) on posting content to our Website.</p>
                <p>1.2 By accessing our Website, you acknowledge that these terms are legally binding. If you don’t agree with any of these terms, you should stop accessing and using our Website.</p>

                <h2>2 ABOUT US</h2>
                <p>2.1 We are Therapy in London Partnership Limited (trading as Therapy in London), a company registered in England and Wales under company registration number 12498132. Our registered office is at Second Floor, 315 Regents Park Road, London, United Kingdom, N3 1DP. Our VAT registration number is 448 0091 02.</p>
                <p>2.2 If you have any questions about the Website, please contact us by sending an email to <a href="mailto:contact@therapyin.london">contact@therapyin.london</a>.</p>

                <h2>3 USING THE WEBSITE</h2>
                <p>3.1 We reserve the right to suspend the operation of our Website at any time. We may also restrict access to some parts of our Website to users who have registered with us.</p>
                <p>3.2 You are responsible for maintaining your own internet connection for accessing the Website.</p>
                <p>3.3 As a condition of your use of the Website, you agree to comply with our Acceptable Use Policy available at <a href="https://www.therapyin.london/terms/acceptable-use" target="_blank">www.therapyin.london/terms/acceptable-use</a> and agree not to:</p>
                <ul>
                  <li>Misuse or attack our Website by knowingly introducing viruses, trojans, worms, logic bombs or any other material which is malicious or technologically harmful (such as by way of a denial-of-service attack);</li>
                  <li>Attempt to gain unauthorized access to our Website, the server on which our Website is stored or any server, computer, or database connected to our Website;</li>
                  <li>Remove, delete, obscure, disable, modify, add to, tamper with, or circumvent any program code or data, copyright, trademark, or other proprietary notices, labels or copy protection software contained on the Website.</li>
                </ul>
                <p>3.4 We may prevent or suspend your access to the Website if you do not comply with these terms or any applicable law.</p>
                <p>3.5 We may suspend or terminate access or operation of the Website at any time as we see fit.</p>
                <p>3.6 We do not promise that the Website will be available at all times or that your use of the Website will be uninterrupted or error-free.</p>

                <h2>4 USER ACCOUNT AND PASSWORD SECURITY</h2>
                <p>4.1 If you need a username or password to access any part of our Website, you agree to:</p>
                <ul>
                  <li>Ensure that any details provided to us are accurate;</li>
                  <li>Keep your username or password secure and confidential;</li>
                  <li>Let us know promptly if you believe that your username or password has been compromised.</li>
                </ul>
                <p>4.2 We may terminate your access to any Website or any password-protected areas of our Website at any time:</p>
                <ul>
                  <li>If we believe that your use of our Website is affecting the security and stability of our Website;</li>
                  <li>If it is detrimental to other users or any information you provide is misleading or disingenuous;</li>
                  <li>If we find you using our Services to advertise anything other than your mental health practice;</li>
                  <li>If your credentials or qualifications are compromised or stripped, or your liability insurance and/or membership body registration expires.</li>
                </ul>

                <h2>5 INTELLECTUAL PROPERTY RIGHTS</h2>
                <p>5.1 The intellectual property rights in the Website and in any text, images, video, audio or other multimedia content, software or other information or material submitted to or accessible from the Website (Content) are owned by us and our licensors.</p>
                <p>5.2 We and our licensors reserve all our intellectual property rights (including, but not limited to, all copyright, trademarks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind) whether registered or unregistered anywhere in the world. This means, for example, that we remain owners of them and are free to use them as we see fit.</p>
                <p>5.3 Nothing in these terms grants you any legal rights in the Website or the Content other than as necessary for you to access it.</p>
                <p>5.4 You may not use any trademarks or trade names that are displayed on this Website, unless you have our express written permission.</p>

                <h2>6 INFORMATION ON THE WEBSITE</h2>
                <p>6.1 We try to make sure that the Website is accurate, up-to-date and free from bugs, but we do not guarantee that it will be.</p>
                <p>6.2 We do not guarantee that the Website will be fit or suitable for any particular purpose. Any reliance that you may place on the information on the Website is at your own risk.</p>

                <h2>7 THIRD PARTY SITES</h2>
                <p>7.1 We have no control over, and don’t accept any responsibility for, the content of any third-party website. If we include a link to any third-party website, this doesn’t mean that we endorse or recommend the organisation which operates it. Your use of a third-party site may be governed by the terms and conditions of that third-party site and is at your own risk.</p>

                <h2>8 LIMIT OF LIABILITY</h2>
                <p>8.1 Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury) or arising under applicable laws relating to the protection of your personal information, we are not legally responsible for any:</p>
                <ul>
                  <li>Losses that were not foreseeable to you and us when these terms were formed;</li>
                  <li>Losses that were not caused by any breach on our part;</li>
                  <li>Business losses; and</li>
                  <li>Losses to non-consumers.</li>
                </ul>

                <h2>9 VARIATION</h2>
                <p>9.1 We reserve the right to amend these terms. Our updated terms will be displayed on the Website. By continuing to use and access the Website, you agree to be bound by any future updates to these terms as and when they are updated. It is your responsibility to check these terms from time to time to verify such variations.</p>

                <h2>10 COMPLAINTS AND QUERIES</h2>
                <p>10.1 If you have any questions about our Website or have any complaints about its contents, please contact us at <a href="mailto:contact@therapyin.london">contact@therapyin.london</a>.</p>

                <h2>11 LAW AND JURISDICTION</h2>
                <p>11.1 The laws of England and Wales apply to these terms. Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales, unless you are a consumer and live in either Northern Ireland or Scotland. In which case, you can choose to bring a claim in England and Wales or in the courts of another part of the United Kingdom in which you live.</p>

              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

import propTypes from "prop-types";
import { Link } from "react-router-dom";
import("../../styles/buttons.scss");

CTA.propTypes = {
  link: propTypes.string,
  onClick: propTypes.func,
  type: propTypes.string,
  text: propTypes.string,
  buttonType: propTypes.string,
  disabled: propTypes.bool,
};

function CTA(props) {
  return (
    <Link
      type={props.buttonType}
      onClick={props.onClick}
      to={props.link}
      className={
        props.type === "green"
          ? "cta_green"
          : props.type === "ghost"
            ? "cta_ghost_blue"
            : props.type === "blue2"
              ? "cta_ghost_blue2"
              : props.type === "ghost_white"
                ? "cta_ghost_white"
                : props.type === "blue"
                  ? "cta_blue"
                  : props.type === "shadow"
                    ? "cta_shadow"
                    : props.type === "orange"
                      ? "cta_orange"
                      : "cta_default"
      }
      disabled={props.disabled}
    >
      {props.text}
    </Link>
  );
}

export default CTA;

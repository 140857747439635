import React, { memo } from 'react';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import propTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "../app/router/index.jsx";
import { ApiProvider } from "./lib/QueryClientProvider.jsx";

const Providers = memo(({ children }) => {
  return (
    <ApiProvider>
      <HelmetProvider>
        <ErrorBoundary fallbackRender={() => <div>Something went wrong</div>}>
          <BrowserRouter>
            {children}
            <ToastContainer />
            <ReactQueryDevtools initialIsOpen={false} />
          </BrowserRouter>
        </ErrorBoundary>
      </HelmetProvider>
    </ApiProvider>
  );
});

Providers.propTypes = {
  children: propTypes.node,
};

Providers.displayName = 'Providers';

const App = () => {
  return (
    <Providers>
      <Router />
    </Providers>
  );
};

App.displayName = 'App';

export default App;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import CTA from "../../../components/fragments/CTA";

import PropTypes from "prop-types";


function PostIt(props) {
  return (
    <>
      <div className="fhfPostit">
        <div className="postit_dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="postit_content">
          <h3>Start Therapy Today</h3>
          <p>
            Browse our therapists and connect with the right person to start your journey with - from today.

          </p>

          <div className="points">
            <ul>
              <li>All therapists listed are fully vetted</li>
              <li>Dedicated, passionate and honest</li>
              <li>Ready to take that first step with you</li>
            </ul>

          </div>

          <CTA type="ghost_white" text="Find a Therapist" link="/" />

        </div>

      </div>
    </>


  );
}

export default PostIt;

import { create } from "apisauce";

export const DEFAULT_API_CONFIG = {
  url: import.meta.env.VITE_API_URL,
  timeout: 30000,
};

export class Api {
  apisauce;
  config;

  constructor(config = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: config.url,
      timeout: config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
  }
  setAuthorizationHeader(token) {
    if (token) {
      this.apisauce.setHeader("Authorization", `Bearer ${token}`);
    } else {
      this.apisauce.deleteHeader("Authorization");
    }
  }
  consoleConfig() {
    console.log("API_URL", this.config.url);
  }

  post(endpoint, data, token) {
    this.setAuthorizationHeader(token);
    return this.apisauce.post(endpoint, data);
  }
  get(endpoint, token) {
    this.setAuthorizationHeader(token);
    return this.apisauce.get(endpoint);
  }
  put(endpoint, data, token) {
    this.setAuthorizationHeader(token);
    return this.apisauce.put(endpoint, data);
  }
  delete(endpoint, data, token) {
    this.setAuthorizationHeader(token);
    return this.apisauce.delete(endpoint, data);
  }
}

export const api = new Api();

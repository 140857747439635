import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function getCarouselSettings() {
  return {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
}

function FeaturedCarousel() {
  const settings = getCarouselSettings();

  return (
    <Slider {...settings}>
      <div className="row">
        <picture>
          <source srcSet="images/featured/guardian.webp" type="image/webp" />
          <img src="images/featured/guardian.png" alt="The Guardian" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>The Guardian</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/huff.webp" type="image/webp" />
          <img src="images/featured/huff.png" alt="Huffpost" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>Huffpost</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/id.webp" type="image/webp" />
          <img src="images/featured/id.png" alt="I-D" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>I-D</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/psy.webp" type="image/webp" />
          <img src="images/featured/psy.png" alt="Psychologies" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>Psychologies</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/google.webp" type="image/webp" />
          <img src="images/featured/google.png" alt="Google News" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>Google News</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/yahoo.webp" type="image/webp" />
          <img src="images/featured/yahoo.png" alt="Yahoo News" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>Yahoo News</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/gq.webp" type="image/webp" />
          <img src="images/featured/gq.png" alt="GQ" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>GQ</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/times.webp" type="image/webp" />
          <img src="images/featured/times.png" alt="Times Radio" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>Times Radio</label>
      </div>
      <div className="row">
        <picture>
          <source srcSet="images/featured/independent.webp" type="image/webp" />
          <img src="images/featured/independent.png" alt="Independent" width="150px" height="150px" loading="lazy" />
        </picture>
        <label>Independent</label>
      </div>
    </Slider>
  );
}

export default FeaturedCarousel;

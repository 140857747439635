import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Disabled() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Disabled Individuals</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Disabled Individuals</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/disablilities.jpg" alt="Disabled individuals" />
                </div>
              </div>
              <div className="content">
                <p>Being able to look after your own mental health and your mindset towards your disability is vital in your happiness and attitude towards your future.</p>
                <p>There are many reasons why you might seek therapy and counselling, no matter what your disability, including:</p>
                <ul>
                  <li>A <a href="https://www.nhs.uk/conditions/learning-disabilities/" target="_blank" rel="noreferrer noopener">learning disability</a></li>
                  <li>Physical disability</li>
                  <li>Short-term</li>
                  <li>Or permanent</li>
                </ul>
                <p>The most common reason to seek counselling is based on a focus of what you cannot do compared to others, rather than reworking your own personal narrative.</p>
                <p>Without this refocusing you can develop symptoms of:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li>Feeling like a failure and a <a href="https://www.therapyin.london/find-help-for/motivation/" target="_blank" rel="noreferrer noopener">lack of motivation</a></li>
                  <li>Pushing others away. Either feeling as if you are not understood or that you are a grievance to others</li>
                </ul>
                <p>A disability can be a physical and mental burden. This is why therapy should begin as early as possible to maintain and develop a new understanding of yourself.</p>
                <h2>What causes mental health issues for the disabled?</h2>
                <p>When anyone experiences difficult life events such as <a href="https://www.therapyin.london/find-help-for/trauma/" target="_blank" rel="noreferrer noopener">trauma</a>, health issues, or abuse, your mental health can fall by the wayside.</p>
                <p>This can also be said for many living with limitations from birth. Without any other way of understanding the world, you can create habits for yourself that limit you more than you may need to. Pushing yourself and reworking these habits can be a challenge, but it is important for anyone to create new goals for themselves and find success in new ways.</p>
                <p>Even though you instinctively do this to protect yourself from ‘danger’. These presumed dangers are what cause limitations for your future.</p>
                <h2>How can therapy help?</h2>
                <p>The reason talking counselling is the most beneficial way to find fulfilment is that it is solution-focused.</p>
                <p>Therapy for disabled individuals is a safe, controlled environment to learn about coping tools, your goals, and your own expectations for yourself.</p>
                <p>Through counselling you can start to understand what you want, what you believe is holding you back, and to make those goals a reality.</p>
                <p>Through this journey you can understand how to get what you want but in a safe and obtainable way.</p>
                <p>Your counsellor is there to take you through your own frustrations and grievances but to then bring focus on what you can do with those feelings to create success for your future.</p>
                <p>Happiness and solutions are possible for everyone and all of your hurdles or obstacles may feel unique to you, but can be overtaken, even if they feel like a barrier.</p>
                <h2>How can <em>Therapy in London</em> help?</h2>
                <p><a href="https://www.therapyin.london/our-therapists/" target="_blank" rel="noreferrer noopener">Our practitioners</a> want to be a constant guide and support through what can be a mentally challenging time for you.</p>
                <p>Being able to understand your capabilities can be a tough time for anyone. We want to be that support for you but also show you that your abilities go far beyond what you believe them to be.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Disabled;

import { auth } from "../../lib/firebaseClient";

export const createUser = async ({ email, name, fUid, gender }) => {
  const url = import.meta.env.VITE_API_URL;
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(`${url}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
        name,
        firebaseUID: fUid,
        gender,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to create user");
    }

    return response.json();
  } catch (error) {
    throw new Error("Failed to create user");
  }
};

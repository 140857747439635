import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import("../../styles/global.scss");
import("../../styles/sections.scss");

function FilterSelectString({
  title,
  options,
  selectedOptions,
  onChange,
  multiSelect = false,
  hideChevron = false, // New prop to hide or disable chevron rotation
}) {
  const [isCollapsed, setIsCollapsed] = useState(!hideChevron); // Default to open if chevron is hidden

  // Ensure collapse is always open if hideChevron is true
  useEffect(() => {
    if (hideChevron) {
      setIsCollapsed(false);
    }
  }, [hideChevron]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (multiSelect) {
      onChange(selectedValue, true);
    } else {
      onChange([selectedValue], true);
    }
  };

  const handleRemove = (optionToRemove) => {
    if (multiSelect) {
      onChange(optionToRemove, false);
    } else {
      onChange([], false);
    }
  };

  const availableOptions = options.filter(
    (option) => !selectedOptions.includes(option)
  );

  return (
    <div className="filter_block">
      <h3
        className="filter_title"
        onClick={() => !hideChevron && setIsCollapsed(!isCollapsed)} // Toggle only if chevron is shown
        style={{
          cursor: hideChevron ? "default" : "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        {title}
        {!hideChevron && (
          <span
            className="chevron"
            style={{
              marginLeft: "auto",
              transform: isCollapsed ? "rotate(280deg)" : "rotate(360deg)",
              transition: "transform 0.2s",
            }}
          >
            <img src="./images/icons/chevron_down.svg" alt="chevron icon" />
          </span>
        )}
      </h3>

      {!isCollapsed && (
        <>
          <select value="" onChange={handleChange}>
            <option value="">Select {title}</option>
            {availableOptions.map((option, index) => (
              <option key={index} value={option} style={{ color: "black" }}>
                {option}
              </option>
            ))}
          </select>
          <div className="selected-options">
            {(selectedOptions || []).map((option) => (
              <span key={option} className="selected-option">
                {option}
                <button type="button" onClick={() => handleRemove(option)}>
                  ×
                </button>
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

FilterSelectString.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
  hideChevron: PropTypes.bool, // New prop type
};

export default FilterSelectString;

import React from "react";
import { Route, Routes } from "react-router-dom";
import { BlogPage } from "../blog/BlogPage";
import { Blog } from "../blog/Blog";

export const BlogRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<BlogPage />} />
        <Route path="/:slug" element={<Blog />} />
      </Routes>
    </>
  );
};

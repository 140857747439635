import { QueryClientProvider } from '@tanstack/react-query';
import propTypes from 'prop-types';
import { queryClient } from "./createQueryClient";

export function ApiProvider({ children }) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

ApiProvider.propTypes = {
  children: propTypes.node,
};

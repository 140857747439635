import { api } from "../../lib/createClient";
export const getBlogBySlug = async ({ slug }) => {
  try {
    const response = await api.get(`/blog/${slug}`);
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

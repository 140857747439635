import { Link } from "react-router-dom";

export default function PaymentFailed() {
  return (

    <div className="status_wrap">

      <div className="status_center">
        <div className="status_block">
          <div className="title">
            <h2>No Payment Taken</h2>
            <p>Return to Billing and try again</p>
          </div>

          <div className="control">
            <Link to="/therapist/billing" className="cta_blue">Return to Billing</Link>

          </div>
        </div>
      </div>
    </div>


  );
}

import { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import("../../styles/footer.scss");

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the document is already loaded
    if (document.readyState === "complete") {
      setIsVisible(true);
    } else {
      const handleLoad = () => setIsVisible(true);
      window.addEventListener("load", handleLoad);

      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <footer className={`footer_wrap ${isVisible ? 'visible' : ''}`}>
      <img src='../../../images/borders/stampMaskBlue.svg' className='borderTop' width="100" height="50" alt="Stamp Mask" loading="lazy" />
      <div className="footer_banner">
        <div className="footer_column_wrap">
          <div className='columnLeft_wrap'>
            <div className="footer_column">
              <div className="footer_column_heading">
                <h5>Legals</h5>
              </div>
              <div className="footer_column_links">
                <ul>
                  <li>
                    <a href="/terms/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms/terms">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/terms/terms-therapists">Terms & Conditions Therapists</a>
                  </li>
                  <li>
                    <a href="/terms/cookies-policy">Cookies Policy</a>
                  </li>
                  <li>
                    <a href="/terms/acceptable-use">Acceptable Use Policy</a>
                  </li>
                  <li>
                    <a href="/terms/disclaimer">Disclaimer</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_column stacked">
              <div className="footer_stacked">
                <div className="footer_column_heading">
                  <h5>About Us</h5>
                </div>
                <div className="footer_column_links">
                  <ul>

                    <li>
                      <Link to="/how-to">Learn About Therapy</Link>
                    </li>
                    <li>
                      <Link to="/blog">The Therapy in London Blog</Link>
                    </li>
                    <li>
                      <Link to="/why-join-therapy-in-london#pricing">Pricing</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_stacked">
                <div className="footer_column_heading">
                  <h5>Services</h5>
                </div>
                <div className="footer_column_links">
                  <ul>
                    <li>
                      <a href="./">Find a Therapist</a>
                    </li>
                    <li>
                      <a href="./why-join-therapy-in-london">Sign Up as a Therapist</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="fold"></div>
            </div>
          </div>
          <div className="footer_lower_wrap">
            <div className="footer_lower">
              <div className="footer_content">
                <label>© 2024 · Therapy in London Partnership Limited</label>
                <label>Designed and Developed by <a href='www.drooldesignstudio.com'>Drool</a></label>
              </div>
            </div>
          </div>
        </div>

        <div className='footer_column_wrap'>
          <div className="footer_map_wrap">
            <div className='footerTitle'>
              <h5>Real Connection <br /> Real Change</h5>
            </div>

            <div className="social_wrap">
              <ul>
                <li>
                  <a href='https://twitter.com/therapyinldn?lang=en' target='_blank'>
                    <img loading="lazy" src="../../../images/icons/twitter.svg" width="15" height="15" alt='twitter' />
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/therapyinldn' target='_blank'>
                    <img loading="lazy" src="../../../images/icons/facebook.svg" width="15" height="15" alt='facebook' />
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/therapyinlondon/' target='_blank'>
                    <img loading="lazy" src="../../../images/icons/instagram.svg" width="15" height="15" alt='instagram' />
                  </a>
                </li>
                <li>
                  <a href='https://www.youtube.com/counsellor' target='_blank'>
                    <img loading="lazy" src="../../../images/icons/youtube.svg" width="15" height="15" alt='youtube' />
                  </a>
                </li>
              </ul>

              <div className="footer_logo">
                <img rel='preload' src="../../../images/logos/til_icon.png" width="161" height="133" alt='therapy in london logo' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

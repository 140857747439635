import PropTypes from "prop-types";
var ImageResize;
import('quill-image-resize-module-react').then((module) => {
  ImageResize = module.default;
});
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import Loader from "../loader";

var Quill, ReactQuill;
import("react-quill").then((module) => {
  Quill = module.Quill;
  ReactQuill = module.default;
  Quill.register("modules/imageResize", ImageResize);
});

function QaEdit({
  id,
  question,
  content,
  onContentChange,
  onHiddenChange,
  onThumbnailChange,
  isThumbnail,
  disableThumbnail,
  isHidden,
  dragHandleProps, // Add dragHandleProps prop
}) {
  const [editorId, setEditorId] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const answerRef = useRef(null);
  const quillRef = useRef(null);

  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        { size: [] },
        { background: [] }, // This enables background color
        { color: [] },
      ],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      [],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  useEffect(() => {
    const id = `qa_${Math.floor(Math.random() * 1000)}`;
    setEditorId(id);
  }, []);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleHiddenChange = () => {
    onHiddenChange(id, !isHidden);
  };

  const handleThumbnailChange = () => {
    onThumbnailChange(!isThumbnail);
  };

  return (
    <div className="qa_wrap">
      <div className="qa">
        <div className="titleExpand">
          {/* <span className={`chevron ${isCollapsed ? "collapsed" : "expanded"}`}>
            <img src="/images/icons/chevron_down_green.svg" alt="asd" />
          </span> */}
          <h2 onKeyDown={toggleCollapse}>{question}</h2>
        </div>

        <div
          className={`qa_answer ${isCollapsed ? "collapsed" : "expanded"}`}
          ref={answerRef}
        >
          <div className="editorWrap">
            <div className="editor qaEditors">
              {editorId && (
                <Suspense fallback={<Loader />}>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={content}
                    onChange={(newContent) => {
                      onContentChange(newContent);
                    }}
                    modules={modules}
                  />
                </Suspense>
              )}
            </div>
          </div>
        </div>
        <div className="showHide_wrap">
          {/* Render drag icon */}

          <div className="button_label_group">
            <div className="drag-icon" {...dragHandleProps}>
              <img src="/images/icons/icon-drag.svg" alt="Drag" />
            </div>
            <label>Drag</label>
          </div>

          {/* Existing show/hide and thumbnail buttons */}
          <div className="button_label_group">
            <button
              type="button"
              className="cta_default"
              onClick={handleHiddenChange}
              style={{
                backgroundColor: isHidden
                  ? "rgba(255, 255, 255, 0.1)"
                  : "#007bff",
                color: isHidden ? "#007bff" : "#fff",
              }}
            >
              <img
                src={
                  isHidden
                    ? "/images/icons/profile-show.svg"
                    : "/images/icons/profile-hide.svg"
                }
                alt="Eye Icon"
              />
            </button>
            <label>
              <strong>{isHidden ? "Show" : "Hide"}</strong> on Profile
            </label>
          </div>

          <div className="button_label_group">
            <button
              type="button"
              className={`cta_default ${disableThumbnail ? "disabled" : ""}`}
              onClick={handleThumbnailChange}
            >
              <img
                src={
                  isThumbnail
                    ? "/images/icons/eye-hide.svg"
                    : "/images/icons/eye-show.svg"
                }
                alt="Profile Icon"
              />
            </button>
            <label>
              <strong>{isThumbnail ? "Hide" : "Show"}</strong> in Search Result
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

QaEdit.propTypes = {
  id: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onContentChange: PropTypes.func.isRequired,
  onHiddenChange: PropTypes.func.isRequired,
  onThumbnailChange: PropTypes.func.isRequired,
  isThumbnail: PropTypes.bool.isRequired,
  disableThumbnail: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  dragHandleProps: PropTypes.object, // Add dragHandleProps to prop types
};

export default QaEdit;

import { Link } from "react-router-dom";

export default function PaymentSuccess() {
  return (
    <div className="status_wrap">

      <div className="status_center">
        <div className="status_block">
          <div className="title">
            <h2>Payment Successful, your profile is now live.</h2>
            <p>Clients will be able to find you by searching using the Therapy in London search engine.</p>
          </div>

          <div className="control">
            <Link to="/therapist/profile" className="cta_blue">Home</Link>

          </div>
        </div>
      </div>
    </div>
  );
}

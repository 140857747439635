import { forwardRef } from "react";
import("../../styles/global.scss");
import("../../styles/sections.scss");

import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { getFileUrl } from "../../app/lib/getFileUrl";
import { truncateAndStripHTML } from "../../utils";

const Thumbnail = forwardRef(({ user }, ref) => {
  const getThumbnailContent = (profileInfos) => {
    for (const info of profileInfos) {
      if (info.isThumbnail) {
        return truncateAndStripHTML(info.content, 150);
      }
    }
    const firstInfo = profileInfos[0];
    return firstInfo ? truncateAndStripHTML(firstInfo.content) : "";
  };

  if (user.id === "d114724a-1e89-43d8-8ab2-590c32b6056f") {
    return null; // Do not render anything if the user ID matches
  }

  return (
    <div className="therapist_block" ref={ref}>
      <Link to={`/results/profile/${user.id}`}>
        <div className="image_wrap_border">
          <div className="image_wrap">
            <img
              src={
                user.profile.profilePicture
                  ? getFileUrl(
                    "profilePicture",
                    user.id,
                    user.profile.profilePicture
                  )
                  : "/images/placeholders/noImage.jpg"
              }
              alt="Profile-picture"
            />
          </div>
        </div>

        <div className="therapist_details">
          <div className="main">
            <h3>
              {user.name}{" "}
              <span>
                <img src="/images/illustrations/tick-white.svg" alt="tick" />
                <label>User Verified</label>
              </span>
            </h3>
            <label>{user.profile.qualifications}</label>
          </div>
          <div className="custom_info">
            <h4>
              {user.profile.profileInfos.find(
                (info) => info.isThumbnail === true
              )?.title || user.profile.profileInfos[0]?.title}
            </h4>
            <p>{getThumbnailContent(user.profile.profileInfos)}</p>
          </div>
        </div>
      </Link>
    </div>
  );
});

Thumbnail.propTypes = {
  user: propTypes.shape({
    name: propTypes.string,
    id: propTypes.string,
    profile: propTypes.shape({
      quote: propTypes.string,
      profilePicture: propTypes.string,
      qualifications: propTypes.string,
      statement: propTypes.string,
      statementDetails: propTypes.string,
      profileInfos: propTypes.arrayOf(
        propTypes.shape({
          content: propTypes.string,
          isThumbnail: propTypes.bool,
          title: propTypes.string,
        })
      ),
    }),
  }),
};

Thumbnail.displayName = "Thumbnail";

export default Thumbnail;

import { Link } from 'react-router-dom';
import("../../styles/buttons.scss");

import PropTypes from "prop-types";

function Statuses(props) {
    return (

        <div className="status_wrap">
            <label className={`${props.color}`}><span></span>{props.text}</label>
        </div>

    );
}

export default Statuses;

import { motion } from "framer-motion";

const ArticleLoader = () => {
  return (
    <div className="latestArticles">
      <motion.div
        className="column"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
      >
        {[1, 2].map((i) => (
          <div key={i} className="octagon-loader">
            <div className="shimmer"></div>
          </div>
        ))}
      </motion.div>
      <motion.div
        className="column"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
      >
        {[1, 2, 3].map((i) => (
          <div key={i} className="octagon-loader">
            <div className="shimmer"></div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default ArticleLoader; 
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function OCD() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">OCD</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for OCD (Obsessive Compulsive Disorder)</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/OCD.jpg" alt="OCD" />
                </div>
              </div>
              <div className="content">
                <p>Obsessive Compulsive Disorder (OCD) is a complex and personally unique ailment to just under 1 million people in the UK.&nbsp;</p>
                <p>Rather than hone in on the different forms of OCD, this page will focus on why talking therapy is used to manage OCD and its benefits. It’s important to understand OCD, especially if you believe it is putting a negative impact on your life.</p>
                <h2>What is OCD?</h2>
                <p>Any Obsessive Compulsion is defined by the need to do something repeatedly. OCD is a common issue that can sometimes be devalued as a disorder.&nbsp;</p>
                <p>You would have heard people lightheartedly talk about their ‘OCD’ tendencies.</p>
                <p>There are several forms which you can find in more detail from the <a href="https://www.nhs.uk/mental-health/conditions/obsessive-compulsive-disorder-ocd/overview/" target="_blank" rel="noreferrer noopener">NHS</a>. No matter what form of OCD you have, it all comes from a compulsion to relieve some kind of anxiety.</p>
                <p>Anything within your life can be a trigger for OCD if you feel it gives you relief from tension, stress or anxiety.&nbsp;</p>
                <h2>What causes OCD.</h2>
                <p>You could view the ’cause’ from a couple of perspectives. Even though your OCD will be different, you can be prone to the disorder if past family members have had it.</p>
                <p>From the other perspective you need to focus on its triggers:</p>
                <p>This can come down, but not limited to, several factors:</p>
                <ul>
                  <li>A traumatic event. I.e. you have experienced a trauma and therefore when faced with an anxiety provoking situation, you take on OCD actions in an attempt to take control back.</li>
                  <li><a href="https://www.therapyin.london/find-help-for/adhd/" target="_blank" rel="noreferrer noopener">ADHD</a> had also been linked to it. Attention deficit can mean that you never feel like you are getting enough done because your struggle to focus on one thing to completion. If you do not learn how to manage ADHD, then OCD tendencies can take over to compensate for the anxiety.</li>
                </ul>
                <p>Your past, your lifestyle and your actions are what make your OCD completely unique to you.</p>
                <p>Even though – for instance, some of the most ‘well-known’ compulsions are:</p>
                <ul>
                  <li>Counting things</li>
                  <li>Cleaning</li>
                  <li>Tapping</li>
                  <li>Skin/hair picking.</li>
                </ul>
                <p>But the reason <em>why</em> you do it is based on what you are trying to compensate for – and that is the entire basis for how you overcome it.</p>
                <h2>How therapy helps.</h2>
                <p>Obsessive Compulsive Disorder is often the result of years of built-up anxiety and tension bursting to come out. </p>
                <p>Therapy and talking counselling is the ideal safe space to unravel and rework these habits.</p>
                <p>Even the thought of taking away your obsessive compulsion is a challenging journey for anyone. And so this is done with care and consideration once you are willing to start the process. </p>
                <p>In some extreme circumstances, there are rehabilitation centres that use <a href="https://www.ocduk.org/overcoming-ocd/accessing-ocd-treatment/exposure-response-prevention/" target="_blank" rel="noreferrer noopener">exposure therapy</a> (ERP), which is to the discretion of each case. They will place you in circumstances that would usually trigger your compulsion and this is where it differs from talking therapy. </p>
                <p>Talking therapy will take you through a mental journey to uncover where and when the issue began.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>We understand that everyone’s situation is unique to you, and what is important for you to understand is that your habits and compulsions do not define you. What you believe is an essential habit for you, is simply an obstacle waiting to be overcome.</p>
                <p>Our professionals are here to be your anchor within a confidential space. This a space to really test yourself and how you can put it behind you. There is no judgement, and there is no goal unobtainable if you’re ready to put your mind to it.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default OCD;

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getUserById } from "../app/api/profile/getUserByid";
import Breadcrumbs from "../components/elements/BreadCrumbs";
import ProfileArticlesView from "../components/elements/profileArticlesView";
import CTA from "../components/fragments/CTA";
import("../styles/buttons.scss");
import("../styles/global.scss");
import("../styles/profiles.scss");

function ProfileArticles() {
  const { userId } = useParams();

  const { data } = useQuery({
    queryKey: ["user"],
    queryFn: () => (userId ? getUserById(userId) : null),
    enabled: !!userId,
  });

  return (
    <>
      <div className="profileView_wrap">
        <div className="profileView">
          <div className="profileView_controls_wrap">
            <Breadcrumbs
              items={[
                { text: "Home", link: "/" },
                { text: "Find a Therapist", link: "/results" },
                { text: data?.user?.name, link: `/results/profile/${userId}` },
                { text: "Articles", link: "#" },
              ]}
            />
            <div className="controls">
              <CTA
                link={`/results/profile/${userId}`}
                type="green"
                text="Back to Profile"
              />
            </div>
          </div>

          <ProfileArticlesView isTherapistAdmin={true} userId={userId} />
        </div>
      </div>
    </>
  );
}

export default ProfileArticles;

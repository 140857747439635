import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import CTA from "../../components/fragments/CTA";
import("../../styles/global.scss");
import("../../styles/sections.scss");
import("../../styles/typesoftherapy.scss");

// Define the transition variants for blocks and text
const slideUpVariant = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

function TypesOfTherapy() {
  return (
    <>
      <div className="fhf_wrap">
        <div className="fhf">
          <div className="fhf_controls">
            <div className="alphabet_links_wrap">
              <motion.div
                className="title"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <h1>Types of Therapy</h1>
                {/* <img
                  src="../images/illustrations/titleWrapSquare.svg"
                  alt="Title Wrap"
                /> */}
              </motion.div>
            </div>
          </div>
          <div className="tot_content_wrap">
            <div className="tot_content">
              <Link
                className="row-forward"
                to="/counselling-services/personal-counselling/"
              >
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="description">
                    <div className="title">
                      <h2>Personal Counselling
                        <span>(1-to-1)
                          <img
                            src="../images/illustrations/scribbleLarge.svg"
                            alt="Scribble Decoration"
                          /></span></h2>

                    </div>
                    <div className="content">
                      <p>
                        When something doesn’t feel right in your life, having
                        the awareness to seek help through personal counselling
                        is a huge…
                      </p>
                      <CTA
                        type="ghost_blue"
                        text="Learn More"
                        link="../counselling-services/personal-counselling/"
                      />
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="image">
                    <img
                      src="../images/articles/typesOfTherapy/personal.png"
                      alt="Personal Counselling"
                    />
                  </div>
                </motion.div>
              </Link>
              <Link
                className="row-forward"
                to="/counselling-services/online-therapy/"
              >
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="description">
                    <div className="title">
                      <h2>Online


                        <span>Therapy
                          <img
                            src="../images/illustrations/scribbleLarge.svg"
                            alt="Scribble Decoration"
                          /></span>
                      </h2>
                    </div>
                    <div className="content">
                      <p>
                        What is online therapy and will it work for me? Even if
                        you feel stuck at home, or that you…
                      </p>
                      <CTA
                        type="ghost_blue"
                        text="Learn More"
                        link="../counselling-services/online-therapy/"
                      />
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="image">
                    <img
                      src="../images/articles/typesOfTherapy/online.png"
                      alt="Online Therapy"
                    />
                  </div>
                </motion.div>
              </Link>
              <Link
                className="row-forward"
                to="/counselling-services/family-counselling/"
              >
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="description">
                    <div className="title">
                      <h2>Family


                        <span>Counselling
                          <img
                            src="../images/illustrations/scribbleLarge.svg"
                            alt="Scribble Decoration"
                          /></span>
                      </h2>

                    </div>
                    <div className="content">
                      <p>
                        When considering family counselling, you are likely
                        experiencing either a breakdown in communication between
                        your family or you want to…
                      </p>
                      <CTA
                        type="ghost_blue"
                        text="Learn More"
                        link="../counselling-services/family-counselling/"
                      />
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="image">
                    <img
                      src="../images/articles/typesOfTherapy/family.png"
                      alt="Family Counselling"
                    />
                  </div>
                </motion.div>
              </Link>
              <Link
                className="row-forward"
                to="/counselling-services/marriage-counselling/"
              >
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="description">
                    <div className="title">
                      <h2>Marriage

                        <span>Counselling
                          <img
                            src="../images/illustrations/scribbleLarge.svg"
                            alt="Scribble Decoration"
                          /></span>
                      </h2>
                    </div>
                    <div className="content">
                      <p>
                        The premise of marriage is to commit to another person
                        ‘till death do us part’. When you make that decision,…
                      </p>
                      <CTA
                        type="ghost_blue"
                        text="Learn More"
                        link="../counselling-services/marriage-counselling/"
                      />
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="image">
                    <img
                      src="../images/articles/typesOfTherapy/marraige.png"
                      alt="Marriage Counselling"
                    />
                  </div>
                </motion.div>
              </Link>
              <Link
                className="row-forward"
                to="/counselling-services/couple-counselling/"
              >
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="description">
                    <div className="title">
                      <h2>Couple   <span>Counselling
                        <img
                          src="../images/illustrations/scribbleLarge.svg"
                          alt="Scribble Decoration"
                        /></span></h2>
                    </div>
                    <div className="content">
                      <p>
                        All relationships go through fluctuations of highs and
                        lows. These ebbs and flows don’t have to mean regular
                        arguments or…
                      </p>
                      <CTA
                        type="ghost_blue"
                        text="Learn More"
                        link="../counselling-services/couple-counselling/"
                      />
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="image">
                    <img
                      src="../images/articles/typesOfTherapy/couple.png"
                      alt="Couple Counselling"
                    />
                  </div>
                </motion.div>
              </Link>
              <Link
                className="row-forward"
                to="/counselling-services/short-term-therapy/"
              >
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="description">
                    <div className="title">
                      <h2>Short Term   <span>Therapy
                        <img
                          src="../images/illustrations/scribbleLarge.svg"
                          alt="Scribble Decoration"
                        /></span></h2>
                    </div>
                    <div className="content">
                      <p>
                        Short term therapy is somewhat self-descriptive, but with many underlying details that you must know before making...
                      </p>
                      <CTA
                        type="ghost_blue"
                        text="Learn More"
                        link="../counselling-services/short-term-therapy/"
                      />
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="block"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={slideUpVariant}
                >
                  <div className="image">
                    <img
                      src="../images/articles/typesOfTherapy/shorterm.png"
                      alt="Short term therapy"
                    />
                  </div>
                </motion.div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TypesOfTherapy;

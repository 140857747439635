import PostIt from "../../../components/elements/findhelpfor/postIt";
import("../../../styles/articles.scss");
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Bipolar() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Bipolar Disorder</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Bipolar Disorder</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/bipolar.jpg" alt="Bipolar Disorder image" />
                </div>
              </div>
              <div className="content">
                <p>Bipolar disorder is a mental condition resulting in either heights of hyperactivity and energy, or rage and depression.</p>
                <p>Being diagnosed with Bipolar means your emotions are far more extreme than anyone else you may know. It can be difficult to regulate and control your emotions if you do not fully understand why you feel the way you do.</p>
                <p>Bipolar episodes, or manic episodes, can last for days or even weeks.</p>
                <p>Because of this drastic scale of emotions, you are more likely to also suffer from suicidal thoughts. Therefore, once you understand that your emotions are related to bipolar, it is vital to seek professional help and guidance.</p>

                <h3>If you are having any suicidal thoughts, <a href="https://www.samaritans.org/" target="_blank" rel="noreferrer noopener">The Samaritans (116 123)</a> are available to talk to you any time of day and welcome your call.</h3>

                <p>Bipolar can also cause mania or manic episodes. As you struggle to regulate your emotions, they can build into extreme forms of either:</p>
                <ul>
                  <li>Psychosis</li>
                  <li>Depression and suicidal thoughts or idealisation</li>
                  <li>Extreme highs of motivation with little foresight</li>
                </ul>
                <h2>What causes Bipolar Disorder?</h2>
                <p>There is no one specific cause of Bipolar. In most cases, once diagnosed with the disorder, it follows either:</p>
                <ul>
                  <li>A drastic change in your life or lifestyle</li>
                  <li>An overwhelming increase in stress</li>
                  <li>A history of mental health issues in your family</li>
                </ul>
                <h2>How can therapy help?</h2>
                <p>Talking counselling is one of several forms of treatment for Bipolar disorder. Counselling is advised to be taken regularly alongside consistent visits to your doctor and, in some cases, prescription medication as well.</p>
                <p>Alongside this combination of treatments, counselling offers a grounding to your thoughts, feelings, and actions.</p>
                <p>You are given tools to recognise triggers and also guidance on what to do to avoid exacerbating those feelings.</p>
                <p>As you continue counselling, you can then come to an understanding of how you think and react in the world in order to live a balanced life. This understanding will also give you a sense of control over the disorder, rather than allowing it to control your life.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>Bipolar creates a unique outlook in which you see the world. Therefore, your therapeutic space is for you to explore all of your thoughts without judgment or fear.</p>
                <p>Even though bipolar might feel like a weight in your life, it doesn’t need to be stifled. What our practitioners want to do is work with you to learn from those heightened emotions.</p>
                <p>Once you are able to accept and understand all of those feelings, you are then able to be whoever you want to be and find real happiness in your life, just like anyone else.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Bipolar;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function Online() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../counselling-services/">Types of Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Online Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Online Therapy</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/typesOfTherapy/article/online.jpg" />
                </div>
              </div>
              <div className="content">
                <h2>What is online therapy and will it work for me?</h2>
                <p>
                  <em>
                    Even if you feel stuck at home, or that you don’t have the time to
                    take a moment and reflect on yourself and your happiness, online
                    therapy could be the ideal option for you.
                  </em>
                </p>
                <p>
                  When considering starting therapy or counselling, there is a lot to
                  decide – including location and distance. Online counselling is able
                  to take that question out of the mix. It offers you the space to talk
                  wherever and whenever you feel comfortable.
                </p>
                <h2>How does online therapy work?</h2>
                <p>
                  You can book your sessions with one of our experienced therapists to
                  arrange either a video call or telephone call for your weekly
                  appointment. We are happy to cater to your requirements and can offer:
                </p>
                <ul>
                  <li>Whatsapp video</li>
                  <li>Zoom</li>
                  <li>Google Meet</li>
                  <li>Skype</li>
                </ul>
                <p>and many other programs and apps to streamline your sessions.</p>
                <p>
                  You will be able to speak to someone in the comfort of your home. All
                  of our counsellors are highly experienced in online sessions. They
                  will ensure that you feel just as at ease and comfortable as you
                  would face-to-face.
                </p>
                <h2>What do we offer and what does online therapy help with?</h2>
                <p>
                  Online therapy can help you with any issues you may be facing and is
                  just as effective as face-to-face counselling. We cover a wide range
                  of issues from{' '}
                  <a href="https://www.therapyin.london/find-help-for/anxiety/">
                    anxiety
                  </a>
                  ,{' '}
                  <a href="https://www.therapyin.london/find-help-for/depression/">
                    depression
                  </a>
                  ,{' '}
                  <a href="https://www.therapyin.london/find-help-for/addiction/">
                    addiction
                  </a>
                  , bereavement, relationships – to name a few. We also simply offer you
                  the space to unload and reflect back on your week.
                </p>
                <p>
                  Even when you feel like you won’t have anything to talk about some
                  weeks, these sessions can be just as personally informative and
                  reflective and make just as much of a difference to your long-term
                  mental health.
                </p>
                <p>
                  Your therapist is there for you, in your own home, as a consistent and
                  structured way to check in on yourself, your feelings, and your
                  actions.
                </p>
                <h2>What can I expect?</h2>
                <p>
                  Online therapy is booked in as a weekly session, at minimum, following
                  your consultation and the first initial payment is cleared. Once you
                  and your therapist have agreed on the type of call you would like, your
                  therapist will send you a meeting link ahead of time and you are
                  required to join the call on time. As with if you visit our{' '}
                  <a href="https://www.therapyin.london/find-us">Shoreditch office</a>
                  , your start and finish time do not roll over.
                </p>
                <p>
                  But each session is your time to do as you wish, your therapist will
                  take your lead in the conversation.
                </p>
                <h2>Who will I be talking to?</h2>
                <p>
                  We ensure that all counsellors are experienced, qualified, and vetted
                  to offer the highest standard of care and are suited to the type of
                  therapy that you require.
                </p>
                <p>
                  If you would like to know more about our therapists and arrange to
                  speak to one of them to see if they are the right fit for you, then{' '}
                  <a
                    href="http://therapyin.london/our-therapists/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    take a look at our team page
                  </a>{' '}
                  and book your free consultation.
                </p>
                <h2>How do I know they are qualified?</h2>
                <p>
                  We are fully insured and uphold the BACP code of practice. This means
                  that all therapists within Therapy in London have gone through a
                  degree-level of education in mental health. They also have had
                  extensive experience with clients with a plethora of issues. If you
                  have any questions about our experience and history in mental health,
                  then we would be more than happy to talk to you.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Online;

import { api } from "../../lib/createClient";

export const getArticleByUser = async ({
  userId,
  status = "draft",
  page = 1,
  limit = 10,
}) => {
  try {
    const response = await api.get(
      `/article/user-articles/${userId}?page=${page}&pageSize=${limit}&status=${status}`
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

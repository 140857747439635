import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const deleteCustomImage = async (userId) => {
  try {
    const response = await api.delete(
      `/profile/delete-furniture-picture/${userId}`,
      {},
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};

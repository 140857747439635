import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Commitment() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Commitment Issues</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Commitment Issues</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/commitment-issues.jpg" alt="Commitment issues image" />
                </div>
              </div>
              <div className="content">
                <p>If you believe that you have commitment issues, you will usually find it difficult to remain in a long-term relationship.</p>
                <p>There is an expectation in society that you should be in a long-term, as well as, a committed relationship. However, commitment does not start and end within relationships. Finding it difficult to stay with a partner for small reasons can usually ripple into other aspects of your life too.</p>
                <p>Your issue with commitment, in general, can mean several things:&nbsp;</p>
                <ul>
                  <li>You find it difficult to make decisions and avoid them if you can</li>
                  <li>Relationships are kept at a surface level</li>
                  <li>You prefer your home comforts and to stay static in work and your personal life</li>
                  <li>You prefer routine and order and can feel out of control when your routine is interrupted</li>
                </ul>

                <h2>What causes commitment issues?</h2>
                <p>In most cases, being unable to commit usually stems from trauma that has left you attempting to avoid conflict, upset, or heartbreak in future scenarios.</p>
                <p>Trauma can be anything that has made a negative impact on the way you react to things around you.</p>
                <p>This can be anything from:</p>
                <ul>
                  <li>Your parents being estranged, abusive, or argumentative around you</li>
                  <li>A partner or crush causing heartbreak</li>
                  <li>Manipulative and cruel bullying from childhood</li>
                  <li>Feeling haunted by past failures</li>
                </ul>
                <p>Your fear of commitment is an attempt to avoid dealing with the anxiety that it may cause. In an attempt to avoid any anxiety, it has become difficult for you to make decisions, build a future with a partner, or even build a strong, successful future for yourself.</p>

                <h2>How can therapy help?</h2>
                <p>Dealing with your commitment issues starts with looking into what you are actively trying to avoid. This could be to avoid failure, avoid being upset, or avoid future trauma.</p>
                <p>During therapy, you can look back at these patterns in your life to see how you can change these habits, in order to create a clearer pathway for your future. Therapy is a safe space for you to do this as your counsellor is able to be a stable and committed guide through this. Understanding where your commitment issues come from can feel daunting, which is why having this safe space is the most beneficial way to create new, positive habits for your future.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>In order to rework any commitment issues you have, you first need a stable and trusted space to do this in. This is why we want to offer somewhere to go which fits into your routine and meets your needs for safety. From this space, you are able to focus on reworking and even committing to all aspects of your life. We encourage change, challenge, and commitments, but we know you can only get there through trust and security.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Commitment;

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useRef, useState, useEffect } from "react";
import {
  useCreateUserWithEmailAndPassword,
  useDeleteUser,
} from "react-firebase-hooks/auth";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../../app/api/auth";
import useToast from "../../../app/hooks/useToast";
import { auth } from "../../../app/lib/firebaseClient";
import Loader from "../../../components/elements/loader";
import CTA from "../../../components/fragments/CTA";
import("../../../styles/buttons.scss");
import("../../../styles/global.scss");
import("../../../styles/signup.scss");
import { signUpSchema } from "../../schemas";

function SignUp() {

  useEffect(() => {
    // Set body background color to blue for this page
    document.body.style.backgroundColor = "#01C476";

    // Cleanup function to reset background color when leaving the page
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const navigate = useNavigate();
  const { notifyFailure } = useToast();
  // eslint-disable-next-line no-unused-vars
  const [createUserWithEmailAndPassword, _, loading, error] =
    useCreateUserWithEmailAndPassword(auth);
  const [deleteUser] = useDeleteUser(auth);

  const form = useForm({
    mode: "all",
    resolver: zodResolver(signUpSchema),
  });

  const { mutate } = useMutation({
    mutationFn: ({ email, name, fUid, gender }) => {
      return createUser({ email, name, fUid, gender });
    },
    onSuccess: () => {
      navigate("/therapist/profile", {
        replace: true,
      });
    },
    onError: () => {
      notifyFailure("Failed to create user");
      deleteUser();
    },
  });

  const passwordInputRef = useRef(null);
  const [showSignUpBlock2, setShowSignUpBlock2] = useState(false);
  const [showSignUpBlock3, setShowSignUpBlock3] = useState(false);
  const [showSignUpBlock4, setShowSignUpBlock4] = useState(false);
  const [showSignUpBlock5, setShowSignUpBlock5] = useState(false);
  const [showNextButton1, setShowNextButton1] = useState(true); // Next button for signUp_wrap
  const [showNextButton2, setShowNextButton2] = useState(true); // Next button for signUp_block3
  const [termsAccepted, setTermsAccepted] = useState(false); // State for terms and conditions checkbox

  const handleNextClick1 = () => {
    if (form.getValues("name") === "") {
      form.setError("name", {
        type: "manual",
        message: "Please enter your full name",
      });
      return;
    }
    if (!form.formState.errors.name) {
      setShowSignUpBlock2(true); // Show signUp_block3
      setShowNextButton1(false); // Hide the Next button in signUp_wrap
      if (passwordInputRef.current) {
        passwordInputRef.current.focus(); // Focus on the password input
      }
    }
  };

  const handleNextClick2 = () => {
    if (form.getValues("gender") === "") {
      form.setError("gender", {
        type: "manual",
        message: "Please select a gender",
      });
      return;
    }
    if (!form.formState.errors.gender) {
      setShowSignUpBlock3(true); // show signUp_block2
      setShowNextButton1(true); // Show the Next button in signUp_wrap again
      setShowNextButton2(false); // Hide the Next button in signUp_block3
      if (passwordInputRef.current) {
        passwordInputRef.current.focus(); // Focus on the password input
      }
    }
  };

  const handleNextClick3 = () => {
    if (form.getValues("email") === "") {
      form.setError("email", {
        type: "manual",
        message: "Please enter your email",
      });
      return;
    }
    if (!form.formState.errors.email) {
      setShowSignUpBlock4(true); // show signUp_block4
      setShowNextButton1(true); // Show the Next button in signUp_wrap again
      setShowNextButton2(false); // Hide the Next button in signUp_block3
      if (passwordInputRef.current) {
        passwordInputRef.current.focus(); // Focus on the password input
      }
    }
  };

  const handleNextClick4 = () => {
    setShowSignUpBlock5(true); // show signUp_block4
    setShowNextButton1(true); // Show the Next button in signUp_wrap again
    setShowNextButton2(false); // Hide the Next button in signUp_block3
    if (passwordInputRef.current) {
      passwordInputRef.current.focus(); // Focus on the password input
    }
  };

  const handleGoBackClick = () => {
    setShowSignUpBlock3(false); // Hide signUp_block2 when "Go Back" is clicked
    setShowNextButton1(true); // Show the Next button in signUp_wrap again
    setShowNextButton2(true); // Show the Next button in signUp_block3 again
  };

  const handleGoBackClick2 = () => {
    setShowSignUpBlock2(false); // Hide signUp_block2 when "Go Back" is clicked
    setShowNextButton1(true); // Show the Next button in signUp_wrap again
    setShowNextButton2(true); // Show the Next button in signUp_block3 again
  };

  const handleGoBackClick3 = () => {
    setShowSignUpBlock4(false); // Hide signUp_block2 when "Go Back" is clicked
    setShowNextButton1(false); // Show the Next button in signUp_wrap again
    setShowNextButton2(false); // Show the Next button in signUp_block3 again
  };

  const handleGoBackClick4 = () => {
    setShowSignUpBlock5(false); // Hide signUp_block2 when "Go Back" is clicked
    setShowNextButton1(false); // Show the Next button in signUp_wrap again
    setShowNextButton2(false); // Show the Next button in signUp_block3 again
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!showSignUpBlock2) {
        handleNextClick1();
      } else {
        handleNextClick2();
      }
    }
  };

  const onSubmit = async (data) => {
    if (!termsAccepted) {
      alert("You must accept the terms and conditions to sign up.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      const fUid = userCredential.user.uid;
      await mutate({
        email: data.email,
        name: data.name,
        fUid,
        gender: data.gender,
      });
    } catch (error) {
      console.error("Error creating user on Firebase:", error);
    }
  };

  if (loading) {
    return <Loader subtitle="Creating your account..." />;
  }
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="signUp_wrap backgroundGreen">
        <div className="signUp_center">
          <div className="pageTitle">
            <h1>Sign Up</h1>
          </div>
          <div className="signUp_block">
            <div className="count">
              <label>1 of 5</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>What&apos;s your <span>Full Name?      <img
                  src="../../../images/illustrations/orangeScribble.svg"
                  alt="Scribble"
                /></span></h2>

              </div>
              <div className="input">
                <Controller
                  name="name"
                  defaultValue=""
                  control={form.control}
                  render={({ field }) => (
                    <input
                      type="text"
                      placeholder="Type Here"
                      onKeyDown={handleKeyPress}
                      {...field}
                    />
                  )}
                />
                {form.formState.errors.name && (
                  <span className="errorNote">
                    {form.formState.errors.name.message}
                  </span>
                )}
              </div>
              <div className="controls">
                {showNextButton1 && (
                  <CTA type="ghost" text="Next" onClick={handleNextClick1} />
                )}
              </div>
            </div>
          </div>

          <div
            className={`signUp_block3 ${showSignUpBlock2 ? "fade-in-3" : ""}`}
          >
            <div className="count">
              <label>2 of 5</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>and your <span>Gender?
                  <img
                    src="../../../images/illustrations/orangeScribble.svg"
                    alt="Scribble"
                  /></span></h2>

              </div>
              <div className="input type_choice">
                <Controller
                  control={form.control}
                  name="gender"
                  render={({ field }) => (
                    <select {...field}>
                      <option value="">Select Gender</option>
                      <option value="cis_male">Cis Male</option>
                      <option value="cis_female">Cis Female</option>
                      <option value="non_binary">Non Binary</option>
                      <option value="trans_male">Trans Male</option>
                      <option value="trans_female">Trans Female</option>
                      <option value="prefer_not_to_say">
                        Prefer not to say
                      </option>
                    </select>
                  )}
                  defaultValue=""
                  rules={{
                    required: "Gender selection is required",
                  }}
                />
                {form.formState.errors.gender && (
                  <span className="errorNote">
                    {form.formState.errors.gender.message}
                  </span>
                )}
              </div>

              <div className="controls">
                {showNextButton2 && (
                  <>
                    <CTA
                      type="ghost"
                      text="Go Back"
                      onClick={handleGoBackClick2}
                    />
                    <CTA type="ghost" text="Next" onClick={handleNextClick2} />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className={`signUp_block2 backgroundBlue ${showSignUpBlock3 ? "fade-in-2" : ""
              }`}
          >
            <div className="count">
              <label>3 of 5</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>Your email address?</h2>
              </div>
              <div className="input">
                <Controller
                  control={form.control}
                  name="email"
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="email"
                      placeholder="Type here"
                      onKeyDown={handleKeyPress}
                      {...field}
                    />
                  )}
                />
                {form.formState.errors.email && (
                  <span className="errorNote">
                    {form.formState.errors.email.message}
                  </span>
                )}
              </div>

              <div className="controls">
                <CTA
                  type="ghost_white"
                  text="Go Back"
                  onClick={handleGoBackClick}
                />
                <CTA
                  type="ghost_white"
                  text="Next"
                  onClick={handleNextClick3}
                />
              </div>
            </div>
          </div>

          <div
            className={`signUp_block4 backgroundOrange ${showSignUpBlock4 ? "fade-in-3" : ""
              }`}
          >
            <div className="count">
              <label>4 of 5</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>Lastly, your password?</h2>
              </div>
              <div className="input">
                <Controller
                  control={form.control}
                  name="password"
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="password"
                      placeholder="Enter Password"
                      onKeyDown={handleKeyPress}
                      {...field}
                    />
                  )}
                />
                {form.formState.errors.password && (
                  <span className="errorNote">
                    {form.formState.errors.password.message}
                  </span>
                )}
              </div>

              <div className="controls">
                <CTA
                  type="ghost_white"
                  text="Go Back"
                  onClick={handleGoBackClick3}
                />
                <CTA
                  type="ghost_white"
                  text="Next"
                  onClick={handleNextClick4}
                />
              </div>
            </div>
          </div>

          <div
            className={`signUp_block5 backgroundBlue ${showSignUpBlock5 ? "fade-in-5" : ""
              }`}
          >
            <div className="count">
              <label>5 of 5</label>
            </div>
            <div className="block">
              <div className="title">
                <h2>Confirm password?</h2>
              </div>
              <div className="input">
                <Controller
                  control={form.control}
                  name="confirmPassword"
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      onKeyDown={handleKeyPress}
                      {...field}
                    />
                  )}
                />
                {form.formState.errors.confirmPassword && (
                  <span className="errorNote">
                    {form.formState.errors.confirmPassword.message}
                  </span>
                )}
                {error && (
                  <span className="errorNote">
                    {error.message.includes("already")
                      ? "Email already in use"
                      : error}
                  </span>
                )}
              </div>

              <div className="termsInput">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label htmlFor="terms">
                  I accept the <a href="/terms/terms-therapists" target="_blank">Terms of Use</a> of this platform.
                </label>
              </div>
              <div className="controls">
                <CTA
                  type="ghost_white"
                  text="Go Back"
                  onClick={handleGoBackClick4}
                />
                <button
                  type="submit"
                  className="cta_ghost_white"
                  disabled={!form.formState.isValid || !termsAccepted}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>

          <div className="subLink">
            <label>Already have an account?</label>
            <CTA type="ghost_white" text="Sign In" link="/portal/signin" />
          </div>
        </div>
      </div>
    </form>
  );
}

export default SignUp;

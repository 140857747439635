import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAvailableArticles } from "../../app/api/admin";
import { activateAvailableArticle } from "../../app/api/admin/activateAvailableArticle";
import { deactivateAvailableArticle } from "../../app/api/admin/deactivateAvailableArticle";
import useToast from "../../app/hooks/useToast";
import { queryClient } from "../../app/lib/createQueryClient";
import Breadcrumbs from "../../components/elements/BreadCrumbs";
import("../../styles/admin.scss");
import("../../styles/buttons.scss");
import("../../styles/global.scss");
import("../../styles/profiles.scss");

const breadcrumbItems = [
  { text: "Home", link: "/" },
  { text: "Articles", link: "/admin/articles" },
  { text: "Available Articles", link: "#" },
];

function AdminAvailableArticles() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const { notifyFailure, notifySuccess } = useToast();
  const {
    data: availableArticles,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["availableArticles", statusFilter],
    queryFn: async () =>
      await getAvailableArticles({
        status: statusFilter,
      }),
  });

  const toggleArticleStatusMutation = useMutation({
    mutationFn: async ({ articleId, currentStatus }) => {
      if (currentStatus === "active") {
        await deactivateAvailableArticle({ aArticleId: articleId });
      } else {
        await activateAvailableArticle({ aArticleId: articleId });
      }
    },
    onMutate: async ({ articleId, currentStatus }) => {
      await queryClient.cancelQueries({
        queryKey: ["availableArticles", statusFilter],
      });

      const previousArticles = queryClient.getQueryData([
        "availableArticles",
        statusFilter,
      ]);

      queryClient.setQueryData(["availableArticles", statusFilter], (old) => ({
        ...old,
        result: old.result.map((article) =>
          article.id === articleId
            ? {
                ...article,
                status: currentStatus === "active" ? "inactive" : "active",
              }
            : article
        ),
      }));

      return { previousArticles };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        ["availableArticles", statusFilter],
        context.previousArticles
      );
      notifyFailure("Failed to update article status");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["availableArticles", statusFilter],
      });
      notifySuccess("Article status updated successfully");
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="profileView_wrap backgroundClear">
        <div className="profileView">
          <div className="profileView_controls_wrap">
            <Breadcrumbs items={breadcrumbItems} />
            <div className="controls" />
          </div>

          <div className="narrowColumn_wrap">
            <div className="title">
              <h1>Available Articles</h1>
              <p>Select Article you would like therapists to write about</p>
              <button
                type="button"
                onClick={() => navigate("/admin/available-articles/create")}
                className="cta_green"
              >
                Create Article
              </button>
            </div>
            <div className="searchfilter_wrap">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search articles..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="filter-container">
                <select
                  value={statusFilter}
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                    refetch();
                  }}
                >
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            <div className="availableArticles_wrap">
              {availableArticles?.result
                ?.filter(
                  (article) =>
                    article.title
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    article.specialisms.some((specialism) =>
                      specialism.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) ||
                    article.description
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                )
                .map((article) => (
                  <div className="availableArticles_block" key={article.id}>
                    <div className="topic">
                      {article.specialisms.map((specialism) => (
                        <label key={specialism.name} className="label">
                          {specialism.name}
                        </label>
                      ))}
                    </div>
                    <div className="about">
                      <h2>{article.title}</h2>
                      <p>{article.description}</p>
                      <div className="control">
                        <button
                          type="button"
                          onClick={() =>
                            navigate(
                              `/admin/available-articles/edit/${article.id}`,
                              {
                                state: {
                                  article,
                                },
                              }
                            )
                          }
                        >
                          Edit
                        </button>
                        <input
                          type="checkbox"
                          checked={article.status === "active"}
                          onChange={() =>
                            toggleArticleStatusMutation.mutate({
                              articleId: article.id,
                              currentStatus: article.status,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminAvailableArticles;

import propTypes from "prop-types";
import { useProfileStore } from "../../../app/stores/profileStore";

ArticlesEdit.propTypes = {
  className: propTypes.string,
  title: propTypes.string,
  labels: propTypes.array,
  articles: propTypes.array,
  articleNumber: propTypes.number,
};

function ArticlesEdit({ title, articleNumber, articles }) {
  const { profile, updateSelectedArticles } = useProfileStore();
  const selectedArticleId = profile[`article${articleNumber}`];
  const otherArticleId = profile[`article${articleNumber === 1 ? 2 : 1}`];

  const handleArticleChange = (e) => {
    const newArticleId = e.target.value || null;
    updateSelectedArticles(articleNumber, newArticleId);
  };

  return (
    <div className="selectBlock">
      <label>{title}</label>
      <select value={selectedArticleId || ""} onChange={handleArticleChange}>
        <option value="">
          {articles.length === 0 ? "Write more articles" : "Select an article"}
        </option>
        {articles.map((article) => (
          <option
            key={article.id}
            value={article.id}
            disabled={article.id === otherArticleId}
          >
            {article.title}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ArticlesEdit;

import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import FHF from "../../components/elements/findhelpfor/thumbnail";
import MetaData from "../../components/fragments/MetaData";
import("../../styles/findhelpfor.scss");
import("../../styles/global.scss");

const fhfData = [
  {
    linkHref: "/find-help-for/abuse",
    imageSrc: "../images/articles/findHelpFor/abuse.jpg",
    title: "Abuse",
    desc: "Abuse is seen as regular and repeated violence, cruelty, bullying or neglect. Whether this is…",
  },
  {
    linkHref: "/find-help-for/addiction",
    imageSrc: "../images/articles/findHelpFor/addiction.jpg",
    title: "Addiction",
    desc: "An addiction can be anything you feel you cannot physically stop. To be addicted means…",
  },
  {
    linkHref: "/find-help-for/adhd",
    imageSrc: "../images/articles/findHelpFor/ADHD.jpg",
    title: "ADHD",
    desc: "ADHD is a condition most commonly diagnosed in children between 6-12. It can be seen…",
  },
  {
    linkHref: "/find-help-for/anxiety",
    imageSrc: "../images/articles/findHelpFor/anxiety.jpg",
    title: "Anxiety",
    desc: "Understanding anxiety first begins with why it’s there in the first place…",
  },
  {
    linkHref: "/find-help-for/attachment-disorder",
    imageSrc: "../images/articles/findHelpFor/attachement-disorder.jpg",
    title: "Attachment Disorder",
    desc: "Attachment disorder is usually diagnosed in children between 1 – 5 years old. This is…",
  },
  {
    linkHref: "/find-help-for/bereavement",
    imageSrc: "../images/articles/findHelpFor/bereavement.jpg",
    title: "Bereavement",
    desc: "Bereavement is a unique experience for everyone and it comes with an all too real…",
  },
  {
    linkHref: "/find-help-for/bipolar",
    imageSrc: "../images/articles/findHelpFor/bipolar.jpg",
    title: "Bipolar Disorder",
    desc: "Bipolar disorder is a mental condition resulting in either heights of hyperactivity and energy, to…",
  },
  {
    linkHref: "/find-help-for/body-dysmorphia",
    imageSrc: "../images/articles/findHelpFor/body-dismorphia.jpg",
    title: "Body Dysmorphia",
    desc: "When experiencing Body Dysmorphia, your main focus is what others might think of your appearance…",
  },
  {
    linkHref: "/find-help-for/bpd",
    imageSrc: "../images/articles/findHelpFor/BPD.jpg",
    title: "BPD",
    desc: "Borderline Personality Disorder (BPD) is a mental health condition that can result in a variety…",
  },
  {
    linkHref: "/find-help-for/commitment-issues",
    imageSrc: "../images/articles/findHelpFor/commitment-issues.jpg",
    title: "Commitment Issues",
    desc: "If you believe that you have commitment issues, you will usually find it difficult to…",
  },
  {
    linkHref: "/find-help-for/confidence",
    imageSrc: "../images/articles/findHelpFor/confidence.jpg",
    title: "Confidence",
    desc: "It is common to lose confidence and this can happen for many reasons. Confidence doesn’t…",
  },
  {
    linkHref: "/find-help-for/depression",
    imageSrc: "../images/articles/findHelpFor/depression.jpg",
    title: "Depression",
    desc: "Depression occurs in 1 of 5 of us, yet is still an issue that needs…",
  },
  {
    linkHref: "/find-help-for/therapy-for-disabled",
    imageSrc: "../images/articles/findHelpFor/disablilities.jpg",
    title: "Disabled Individuals",
    desc: "Being able to look after your own mental health and your mindset towards your disability…",
  },
  {
    linkHref: "/find-help-for/dissociation",
    imageSrc: "../images/articles/findHelpFor/disassociation.jpg",
    title: "Dissociation",
    desc: "Dissociation is a feeling of not being present – as if you are observing your…",
  },
  {
    linkHref: "/find-help-for/divorce",
    imageSrc: "../images/articles/findHelpFor/divorce.jpg",
    title: "Divorce",
    desc: "A marriage is built on the assumption that you will be together forever. It seemed…",
  },
  {
    linkHref: "/find-help-for/eating-disorders",
    imageSrc: "../images/articles/findHelpFor/eating-disorder.jpg",
    title: "Eating Disorders",
    desc: "Eating disorders are essentially a felt need to drastically control your food intake. It becomes…",
  },
  {
    linkHref: "/find-help-for/gender-identity",
    imageSrc: "../images/articles/findHelpFor/Gender-identity.jpg",
    title: "Gender Identity and Dysphoria",
    desc: "Your own gender identity can only be decided by you as an individual. This is…",
  },
  {
    linkHref: "/find-help-for/health-anxiety",
    imageSrc: "../images/articles/findHelpFor/health-anxiety.jpg",
    title: "Health Anxiety",
    desc: "Health Anxiety (or Hypochondria) means you are in regular or constant fear of being ill…",
  },
  {
    linkHref: "/find-help-for/hoarding",
    imageSrc: "../images/articles/findHelpFor/hoarding.jpg",
    title: "Hoarding",
    desc: "Hoarding is when you feel the need to keep things no matter their value, regardless…",
  },
  {
    linkHref: "/find-help-for/hypervigilance",
    imageSrc: "../images/articles/findHelpFor/hypervigilance.jpg",
    title: "Hypervigilance",
    desc: "Before understanding hypervigilance, it’s important to differentiate between this and everybody’s regular brain function surrounding…",
  },
  {
    linkHref: "/find-help-for/lgbtq-issues",
    imageSrc: "../images/articles/findHelpFor/LGBT-issues.jpg",
    title: "LGBTQ+ Issues",
    desc: "At times, people who identify as LGBTQ+ find that the social stress of living as…",
  },
  {
    linkHref: "/find-help-for/loneliness",
    imageSrc: "../images/articles/findHelpFor/lonliness.jpg",
    title: "Loneliness",
    desc: "Are you questioning if you are lonely? And what does being lonely actually mean? Loneliness…",
  },
  {
    linkHref: "/find-help-for/low-self-esteem",
    imageSrc: "../images/articles/findHelpFor/low-self-esteem.jpg",
    title: "Low Self Esteem",
    desc: "An addiction can be anything you feel you cannot physically stop. To be addicted means…",
  },
  {
    linkHref: "/find-help-for/lying",
    imageSrc: "../images/articles/findHelpFor/lying.jpg",
    title: "Lying",
    desc: "While most of us are taught from a young age not to lie, lying from…",
  },
  {
    linkHref: "/find-help-for/mens-mental-health",
    imageSrc: "../images/articles/findHelpFor/mensmentalhealth.jpg",
    title: "Men's Mental Health",
    desc: "Men have been restricted for years when it comes to discussing their own mental wellbeing.…",
  },
  {
    linkHref: "/find-help-for/neurodiversity",
    imageSrc: "../images/articles/findHelpFor/neurodiversity.jpg",
    title: "Neurodiversity",
    desc: "Neurodiversity refers to understanding the diversity of the human brain. You can uncover your strengths…",
  },
  {
    linkHref: "/find-help-for/ocd",
    imageSrc: "../images/articles/findHelpFor/OCD.jpg",
    title: "OCD",
    desc: "Obsessive Compulsive Disorder (OCD) is a complex and personally unique ailment to just under 1…",
  },
  {
    linkHref: "/find-help-for/panic-attacks",
    imageSrc: "../images/articles/findHelpFor/panic-attacks.jpg",
    title: "Panic Attacks",
    desc: "Panic attacks, (anxiety attacks, or panic disorder) is an issue resulting in severe physical symptoms…",
  },
  {
    linkHref: "/find-help-for/paranoia",
    imageSrc: "../images/articles/findHelpFor/paranoid.jpg",
    title: "Paranoia",
    desc: "Not all cases of paranoia are the same. There is a wide spectrum of cases…",
  },
  {
    linkHref: "/find-help-for/peter-pan-syndrome",
    imageSrc: "../images/articles/findHelpFor/peter-pan-syndrome.jpg",
    title: "Peter Pan Syndrome",
    desc: "Peter Pan Syndrome refers to someone who may find it difficult to take on adult…",
  },
  {
    linkHref: "/find-help-for/phobia",
    imageSrc: "../images/articles/findHelpFor/phobia.jpg",
    title: "Phobias",
    desc: "A phobia is an anxiety disorder that causes severe, and sometimes debilitating, fear in certain…",
  },
  {
    linkHref: "/find-help-for/postnatal-depression",
    imageSrc: "../images/articles/findHelpFor/post-natal-depression.jpg",
    title: "Postnatal Depression",
    desc: "Having a child can bring a lot of emotions to the surface. It takes an…",
  },
  {
    linkHref: "/find-help-for/procrastination",
    imageSrc: "../images/articles/findHelpFor/procrastination.jpg",
    title: "Procrastination",
    desc: "When dealing with procrastination, you are constantly fighting the urge to either do something more…",
  },
  {
    linkHref: "/find-help-for/ptsd",
    imageSrc: "../images/articles/findHelpFor/PTSD.jpg",
    title: "PTSD",
    desc: "Post Traumatic Stress Disorder (PTSD) occurs when a traumatic event from your past continues to…",
  },
  {
    linkHref: "/find-help-for/seasonal-affective-disorder",
    imageSrc: "../images/articles/findHelpFor/SAD.jpg",
    title: "Seasonal Affective Disorder (SAD)",
    desc: "When you are well into winter and you have seen nothing but rain and wind…",
  },
  {
    linkHref: "/find-help-for/self-harm",
    imageSrc: "../images/articles/findHelpFor/self-harm.jpg",
    title: "Self Harm",
    desc: "Self harm affects people of all ages and can present itself in different ways. No…",
  },
  {
    linkHref: "/find-help-for/separation-anxiety",
    imageSrc: "../images/articles/findHelpFor/separation-anxiety.jpg",
    title: "Separation Anxiety",
    desc: "Separation anxiety is a common issue in both children and adults. In children, separation anxiety…",
  },
  {
    linkHref: "/find-help-for/separation",
    imageSrc: "../images/articles/findHelpFor/separation.jpg",
    title: "Separation",
    desc: "Separation from your partner, whether it be a long term, or short term relationship, can…",
  },
  {
    linkHref: "/find-help-for/sex-issues",
    imageSrc: "../images/articles/findHelpFor/sex-issues.jpg",
    title: "Sex Issues",
    desc: "Issues with sex starts with understanding your issues surrounding your sex life. Sex issues can…",
  },
  {
    linkHref: "/find-help-for/suicidal-thoughts",
    imageSrc: "../images/articles/findHelpFor/suicidal-thoughts.jpg",
    title: "Suicidal Thoughts",
    desc: "Suicidal thoughts can range from a fleeting feeling that people in your life would be…",
  },
  {
    linkHref: "/find-help-for/trauma",
    imageSrc: "../images/articles/findHelpFor/trauma.jpg",
    title: "Trauma",
    desc: "Trauma occurs when you experience something physically or emotionally painful and shocking. We associate the…",
  },
  {
    linkHref: "/find-help-for/womens-mental-health",
    imageSrc: "../images/articles/findHelpFor/womens-mental-health.jpg",
    title: "Women's Mental Health",
    desc: "Women’s mental health has been a heated topic for many years. The cis female body…",
  },
  {
    linkHref: "/find-help-for/work-and-career-issues",
    imageSrc: "../images/articles/findHelpFor/work-and-career-issues.jpg",
    title: "Work and Career Issues",
    desc: "Within the UK, a lot of our identity centers around your work or where you…",
  },
];
fhfData.sort((a, b) => a.title.localeCompare(b.title));

const staggeredVariants = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

function FindHelpFor() {
  const [selectedLetter, setSelectedLetter] = useState("");

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
  };

  const alphabetLinks = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter) => (
    <Link
      key={letter}
      to={`/find-help-for/${letter.toLowerCase()}`}
      onClick={(e) => {
        e.preventDefault();
        handleLetterClick(letter);
      }}
      className={selectedLetter === letter ? "active" : ""}
    >
      {letter}
    </Link>
  ));

  const filteredFHFData = selectedLetter
    ? fhfData.filter((item) => item.title.startsWith(selectedLetter))
    : fhfData;

  return (
    <>
      <MetaData title="Therapy in London • Find Help For.." />
      <div className="fhf_wrap">
        <div className="fhf">
          <div className="fhf_controls">
            <motion.div
              className="alphabet_links_wrap"
              initial="hidden"
              animate="show"
              variants={staggeredVariants}
            >
              <motion.h1 variants={itemVariants}>Find Help For..</motion.h1>
              <motion.div className="types_wrap" variants={itemVariants}>
                <div className="title">
                  <div className="label_angle" />
                </div>
                <div className="types_content">
                  <div className="types_tags">
                    <div className="alphabet-links">{alphabetLinks}</div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </div>
          <div className="fhf_content_wrap">
            <div className="fhf_content">
              {filteredFHFData.length > 0 ? (
                filteredFHFData.map((item, index) => (
                  <FHF
                    key={`${item}-${index}`}
                    linkHref={item.linkHref}
                    imageSrc={item.imageSrc}
                    title={item.title}
                    desc={item.desc}
                  />
                ))
              ) : (
                <p>No articles available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindHelpFor;

import { Route, Routes } from "react-router-dom";
import LayoutAdmin from "../../components/layouts/layout-admin";
import LayoutNoNav from "../../components/layouts/layoutNoNav";
import AdminProfileView from "../../pages/admin/profileView";
import CreateAvailableArticles from "../admin/CreateAvailableArticle";
import EditAvailableArticle from "../admin/EditAvailableArticle";
import AdminArticles from "../admin/articles";
import AdminAvailableArticles from "../admin/availableArticles";
import AdminUsers from "../admin/users";
import AdminWriteArticle from "../admin/writeArticle";

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutAdmin />}>
        <Route path="users" element={<AdminUsers />} />
        <Route path="articles" element={<AdminArticles />} />
        <Route
          path="articles/available-articles"
          element={<AdminAvailableArticles />}
        />
      </Route>
      <Route element={<LayoutNoNav />}>
        <Route path={"users/profile/:userId"} element={<AdminProfileView />} />
        <Route
          path="articles/review/:articleId"
          element={<AdminWriteArticle />}
        />
        <Route
          path="available-articles/create"
          element={<CreateAvailableArticles />}
        />
        <Route
          path="available-articles/edit/:aArticleId"
          element={<EditAvailableArticle />}
        />
      </Route>
    </Routes>
  );
};

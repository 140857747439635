// useUserData.js
import { useQuery } from "@tanstack/react-query";
import { getUser } from "../api/profile/getUser";
import { auth } from "../lib/firebaseClient";

export const useUserData = (userId) => {
  const id = userId || auth.currentUser.uid;
  return useQuery({
    queryKey: ["user", id],
    queryFn: () => getUser(id),
  });
};

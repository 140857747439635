import { useMutation } from "@tanstack/react-query";
import propTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { deleteArticle } from "../../../app/api/articles/deleteArticle";
import useToast from "../../../app/hooks/useToast";
import { queryClient } from "../../../app/lib/createQueryClient";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import { truncateAndStripHTML } from "../../../utils";

Articles.propTypes = {
  title: propTypes.string,
  desc: propTypes.string,
  status: propTypes.string,
  id: propTypes.string,
  userId: propTypes.string,
  image: propTypes.string,
  isPublic: propTypes.bool,
};

function Articles({ title, desc, status, id, userId, image, isPublic }) {
  const { notifySuccess, notifyFailure } = useToast();
  const location = useLocation(); // Get the current location

  const deleteArticleMutation = useMutation({
    mutationFn: () => {
      return deleteArticle(id);
    },
    onSuccess: () => {
      notifySuccess("Article Deleted");
      queryClient.invalidateQueries(["articles"]);
    },
    onError: () => {
      notifyFailure("Failed to delete article");
    },
  });

  const getStatusLabel = () => {
    switch (status) {
      case "approved":
        return <label className="green">Live</label>;
      case "awaitingApproval":
        return <label className="orange">Awaiting Review</label>;
      case "draft":
        return <label className="blue">Draft</label>;
      case "rejected":
        return <label className="red">Rejected</label>;
      default:
        return null;
    }
  };

  const getCTAText = () => {
    return status === "draft" ||
      status === "rejected" ||
      status === "awaitingApproval"
      ? "Edit"
      : "Read Article";
  };

  return (
    <div className="articles_block">
      {image && (
        <div className="thumbnail">
          {/*TODO:Add article image */}
          <img src={getFileUrl("article", id, image)} alt="article-thumbnail" />
        </div>
      )}
      <div className="content">
        <div className="statusWrap">{getStatusLabel()}</div>
        <div className="title">
          <h2>{title}</h2>
        </div>
        <div className="info">
          <p>{`${truncateAndStripHTML(desc, 200)}...`}</p>
          {status === "draft" ||
            status === "rejected" ||
            status === "awaitingApproval" ? (
            <>
              <Link
                className="cta_ghost_blue"
                to={`/therapist/articles/edit/${id}`}
              >
                {getCTAText()}
              </Link>
            </>
          ) : (
            <>
              <Link className="cta_blue" to={`/articles/${userId}/${id}`}>
                {getCTAText()}
              </Link>
              {location.pathname === "/therapist/articles" && !isPublic && (
                <button
                  type="button"
                  onClick={() => deleteArticleMutation.mutate()}
                  className="deleteArticle"
                >
                  <img src="../images/icons/bin.svg" alt="bin" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Articles;

import { useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import("../../styles/global.scss");
import MetaData from "../fragments/MetaData";

function LayoutNoNav() {
  const { pathname } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaData />
      <div>
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default LayoutNoNav;

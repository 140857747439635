import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import("../../styles/global.scss");
import("../../styles/loader.scss");
import LoaderAnimation from "../animations/Loader"; // Import the LoaderAnimation component
import LineBackground from "../fragments/lineBackground";

function LoaderSearch(props) {
  const textRef = useRef(null);

  useEffect(() => {
    if (!textRef.current) return;
    const text = props.subtitle;
    let index = 0;
    let forward = true;
    const typingSpeed = 100; // typing speed in ms
    const pauseDuration = 1000; // pause before restarting the loop

    function type() {
      if (!textRef.current) return;
      if (forward) {
        textRef.current.innerHTML = text.slice(0, index + 1);
        index++;
        if (index === text.length) {
          forward = false;
          setTimeout(type, pauseDuration);
          return;
        }
      } else {
        textRef.current.innerHTML = text.slice(0, index - 1);
        index--;
        if (index === 0) {
          forward = true;
          setTimeout(type, typingSpeed);
          return;
        }
      }
      setTimeout(type, typingSpeed);
    }

    type();
  }, [props.subtitle]);

  return (
    <div className="loader_search">
      <LineBackground />
      <div className="loader_center">
        <div className="loader_block">
          <div className="title">
            <LoaderAnimation /> {/* Use the LoaderAnimation component */}
            <p ref={textRef} className="typing-text"></p>
          </div>
        </div>
      </div>
    </div>
  );
}
LoaderSearch.propTypes = {
  subtitle: PropTypes.string.isRequired,
};
export default LoaderSearch;

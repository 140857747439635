import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function HowToEndTherapy() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../how-to/">How to</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">How to End Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>How to End Therapy</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/howTo/end-therapy.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  You’ve started therapy and gone through a great journey with your therapist. Now, you are ready to see how you can maneuver in the world without them. As you go through those changes and developments in your life, this is a great time to reflect on where you were and how far you have come.
                </p>

                <p>
                  If you are considering ending therapy, there are some important steps to take to ensure that this is the right time, and you can still benefit from all the progress you have made so far:
                </p>

                <h2>1. How do I tell my therapist?</h2>

                <p>
                  This is always the first step to finishing therapy. During your time in therapy, you should have created a safe and trusted relationship with your therapist. If you are considering taking a break or ending therapy, communicating that with your therapist is always the first step.
                </p>

                <p>
                  Therapy sessions are a process of looking back at upsetting and even traumatic past experiences. Before therapy, your habits, trauma, and fears are built over a long period of time. This is why the process of ending therapy shouldn’t be rushed. During this process, you also need time and space to plan how and when to step away. By being transparent, even about finishing therapy, you can plan the safest and most effective exit from your sessions.
                </p>

                <h2>2. How far have I come?</h2>

                <p>
                  Once you have discussed leaving in your session, you can start building toward doing so in the safest and most beneficial way possible. Start by considering what you have achieved since starting therapy. This is a great way to reflect on how far you have come and how you got to this point. Before leaving, it’s important to look back on how you have gotten to the place you are in now, compared to before.
                </p>

                <h2>3. What do I need or want?</h2>

                <p>
                  At the start of therapy, your goals and ambitions are usually at the forefront of your mind. When you begin to settle in therapy, you work towards achieving those goals. Ending therapy doesn’t have to mean that you have nothing left to aim for. Once deciding to end, still ask yourself (and voice it in sessions) what you would like for your future.
                </p>

                <p>
                  When talking about this, you can then establish whether the tools you have built for yourself so far can help you move forward outside of therapy.
                </p>

                <h2>4. What are my next steps?</h2>

                <p>
                  The tools we are talking about are what your therapy sessions are built on. These are how you manage stress, fear, anxiety, and even excitement. If in the past you used to run away or avoid new, scary things, these tools are what help you to face and even challenge yourself. When leaving therapy, you can start to understand what you want and establish how you would pursue it. Currently, you would have a weekly base to come back to, to help you to do those new things. So how will that go without that weekly catch-up?
                </p>

                <h2>5. Should I be happy now?</h2>

                <p>
                  One of the most common misconceptions is that you finish therapy when you are completely happy. During therapy, the main goal is to learn how to develop yourself to work towards happiness, but this doesn’t mean that you can find complete happiness all the time. Stepping away from therapy means being able to continue without the aid and stability of your therapy sessions. You can develop helpful and fulfilling skills to use in the real world, which are built to be used continually. Once you have these, you can always be growing, learning, and developing even outside of therapy. This doesn’t necessarily have to be easy or full of contentment; things can be stressful, challenging, and scary, but you now know how to take those hurdles head-on.
                </p>

                <p>
                  Once you come out of therapy with a full understanding of what you want and what you have, you can fully utilize the unlimited benefit that therapy has offered you.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default HowToEndTherapy;

import { Route, Routes, useSearchParams } from "react-router-dom";
import Profile from "../profile";
import Results from "../results";

export const ResultsRoutes = () => {
  const [searchParams] = useSearchParams();
  return (
    <Routes>
      <Route index element={<Results searchParams={searchParams} />} />
      <Route path="profile/:userId" element={<Profile />} />
      <Route path="profile-articles" element={<Profile />} />
    </Routes>
  );
};

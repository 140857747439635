import { api } from "../../lib/createClient";

export const getSpecialisms = async () => {
  try {
    const response = await api.get("/profile/specialisms");

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import { useProfileStore } from "../../../app/stores";
import { updateProfileSchemaMainEdit } from "../../../pages/schemas";
import("../../../styles/global.scss");
import("../../../styles/locationSearch.scss");
import("../../../styles/sections.scss");
import InputGoogle from "../InputGoogle";
import { LeftLine } from "../svgs/LeftLine";
import { ProfileScribble } from "../svgs/ProfleScribble";
import { StarThingy } from "../svgs/StarThingy";

function MainEdit() {
  const { profile, updateProfile, selectedPhoto, setSelectedPhoto } =
    useProfileStore((state) => ({
      profile: state.profile,
      updateProfile: state.updateProfile,
      selectedPhoto: state.selectedPhoto,
      setSelectedPhoto: state.setSelectedPhoto,
    }));

  const form = useForm({
    resolver: zodResolver(updateProfileSchemaMainEdit),
    mode: "onBlur",
    defaultValues: {
      name: profile.name || "",
      qualifications: profile.qualifications || "",
      location: profile?.location?.formattedAddress || "",
      email: profile.email || "",
      phoneNumber: profile.phoneNumber || "",
    },
  });

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg")
    ) {
      setSelectedPhoto(file);
    } else {
      alert("Please select a JPEG, PNG, or JPG file.");
    }
  };

  const photoUrl = useMemo(() => {
    if (selectedPhoto instanceof File) {
      const url = URL.createObjectURL(selectedPhoto);
      return url;
    }
    return null;
  }, [selectedPhoto]);

  useEffect(() => {
    return () => {
      if (photoUrl) {
        URL.revokeObjectURL(photoUrl);
      }
    };
  }, [photoUrl]);

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (type === "change") {
        updateProfile({ [name]: value[name] });
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch, updateProfile]);

  // Add this useEffect to set the initial location value
  useEffect(() => {
    if (profile.location?.formattedAddress) {
      form.setValue("location", profile.location.formattedAddress);
    } else if (typeof profile.location === "string") {
      form.setValue("location", profile.location);
    }
  }, [profile.location, form]);

  return (
    <form className="profile_section_main">
      <div className="userName_wrap">
        <div className="image_wrap_border">
          <div
            className="image_wrap"
            style={{
              backgroundColor: profile.photo ? "transparent" : "#000000",
            }}
          >
            {photoUrl ? (
              <img src={photoUrl} alt="Selected Profile" />
            ) : profile.photo ? (
              <img
                src={getFileUrl(
                  "profilePicture",
                  profile.userId,
                  profile.photo
                )}
                alt="Profile"
              />
            ) : (
              <img
                src="../../images/icons/Camera.svg"
                alt="Placeholder"
                className="iconPlaceholder"
              />
            )}
            <label htmlFor="profile" />
            <input
              type="file"
              id="profile"
              placeholder="Type Here"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleProfilePictureChange}
            />
          </div>
          <p className="recommended">
            Recommended image size of 400px by 400px
          </p>
        </div>
        <div className="userName">
          <StarThingy color={profile.color} />
          <Controller
            control={form.control}
            name="name"
            render={({ field }) => (
              <input
                type="text"
                className="edit_input edit_name"
                placeholder="Full Name"
                value={profile.name}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <div className="profileScribble">
        <ProfileScribble color={profile.color} />
      </div>
      <div className="profile_image">
        <div className="name">
          <div className="title titleEdit">
            <Controller
              control={form.control}
              name="qualifications"
              render={({ field }) => (
                <input
                  type="text"
                  value={profile?.qualifications}
                  className="edit_input edit_address edit_qual"
                  placeholder="Title / Qualifications"
                  {...field}
                />
              )}
            />
            <div className="address">
              <LeftLine color={profile.color} />
              <Controller
                control={form.control}
                name="location"
                render={({ field }) => (
                  <InputGoogle
                    {...field}
                    className="edit_input edit_address"
                    placeholder="Where are you located?"
                    onPlaceSelected={(place) => {
                      const addressComponents = place.address_components;
                      let city = "";

                      const localityComponent = addressComponents.find((c) =>
                        c.types.includes("locality")
                      );
                      if (localityComponent) {
                        city = localityComponent.long_name;
                      } else {
                        const adminArea4Component = addressComponents.find(
                          (c) => c.types.includes("administrative_area_level_4")
                        );
                        const postalTown = addressComponents.find((c) =>
                          c.types.includes("postal_town")
                        );
                        if (adminArea4Component || postalTown) {
                          city = adminArea4Component?.long_name
                            ? adminArea4Component?.long_name
                            : postalTown?.long_name;
                        } else {
                          const adminArea3Component = addressComponents.find(
                            (c) =>
                              c.types.includes("administrative_area_level_3")
                          );
                          const adminArea2Component = addressComponents.find(
                            (c) =>
                              c.types.includes("administrative_area_level_2")
                          );
                          city =
                            adminArea3Component?.long_name ||
                            adminArea2Component?.long_name ||
                            "";
                        }
                      }
                      const locationObj = {
                        id: profile.locationObj?.id || "",
                        country:
                          addressComponents.find((c) =>
                            c.types.includes("country")
                          )?.long_name || "",
                        city: city,
                        county:
                          addressComponents.find((c) =>
                            c.types.includes("administrative_area_level_2")
                          )?.long_name || "",
                        postCode:
                          addressComponents.find((c) =>
                            c.types.includes("postal_code")
                          )?.long_name || "",
                        formattedAddress: place.formatted_address,
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                      };

                      updateProfile({
                        locationObj: locationObj,
                        location: place.formatted_address,
                      });
                      field.onChange(place.formatted_address);
                      form.setValue("location", place.formatted_address);
                    }}
                    onClear={() => {
                      updateProfile({
                        locationObj: {
                          country: "",
                          city: "",
                          county: "",
                          postCode: "",
                          latitude: null,
                          longitude: null,
                        },
                        location: "",
                      });
                      form.setValue("location", "");
                    }}
                    onError={(hasError) => {
                      if (hasError) {
                        form.setError("location", {
                          type: "manual",
                          message: "Invalid location",
                        });
                      } else {
                        form.clearErrors("location");
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="address">
              <LeftLine color={profile.color} />
              <Controller
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <input
                    type="text"
                    className="edit_input edit_address"
                    placeholder="What is your contact number?"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="address">
              <LeftLine color={profile.color} />
              <Controller
                control={form.control}
                name="email"
                render={({ field }) => (
                  <input
                    type="text"
                    className="edit_input edit_address"
                    placeholder="What is your email address?"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default MainEdit;

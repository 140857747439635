import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const getMonthlyStripeLink = async (stripeId) => {
  try {
    const response = await api.get(
      `/payment/monthly-payment/${stripeId}`,
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};

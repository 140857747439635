import { format, parseISO } from "date-fns";
import { motion } from "framer-motion";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import("../../../styles/articles.scss");
import("../../../styles/global.scss");
import("../../../styles/sections.scss");

const staggeredVariants = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

function ArticleItem({
  title,
  content,
  userName,
  specialisms,
  picture,
  profilePicture,
  userId,
  articleId,
  createdAt,
}) {
  const getFirst200Chars = (text) => {
    return text.length > 200 ? `${text.substr(0, 200)}...` : text;
  };
  const formattedDate = format(parseISO(createdAt), "do MMMM yyyy");
  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.1 }}
        variants={staggeredVariants}
        className="article-item"
      >
        <Link to={`/articles/${userId}/${articleId}`} className="wall-post">
          <motion.div className="articleImage_wrap" variants={itemVariants}>
            <motion.img
              src={getFileUrl("article", articleId, picture)}
              alt="Article thumbnail"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
            />
          </motion.div>

          <motion.div className="topSection" variants={itemVariants}>
            <div className="date">
              <motion.label
                variants={itemVariants}
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
              >
                {formattedDate}
              </motion.label>
            </div>
            <div className="profile">
              <div className="image">
                <motion.img
                  src={getFileUrl("profilePicture", userId, profilePicture)}
                  variants={itemVariants}
                  alt="Author profile"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                />
              </div>
              <div className="author">
                <motion.label
                  variants={itemVariants}
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                >
                  Written by:
                </motion.label>
                <motion.label
                  variants={itemVariants}
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                >
                  {userName}
                </motion.label>
              </div>
            </div>
          </motion.div>
          <motion.header
            className="wall-post-header"
            variants={itemVariants}
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
          >
            <motion.h2
              variants={itemVariants}
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
            >
              {title}
            </motion.h2>
          </motion.header>
          <motion.div
            className="wall-post-content"
            variants={itemVariants}
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
          >
            <motion.p
              variants={itemVariants}
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
            >
              {getFirst200Chars(content.replace(/<[^>]*>/g, ""))}
            </motion.p>
            <motion.div
              className="bottomSection"
              variants={itemVariants}
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
            >
              <div className="postTag">
                {specialisms.map((specialism) => (
                  <motion.label
                    key={specialism.id}
                    variants={itemVariants}
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                  >
                    {specialism.name}
                  </motion.label>
                ))}
              </div>
            </motion.div>
          </motion.div>
        </Link>
      </motion.div>
    </>
  );
}

ArticleItem.propTypes = {
  title: propTypes.string.isRequired,
  content: propTypes.string.isRequired,
  userName: propTypes.string.isRequired,
  specialisms: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
    })
  ).isRequired,
  picture: propTypes.string,
  profilePicture: propTypes.string,
  userId: propTypes.string,
  articleId: propTypes.string,
};

export default ArticleItem;

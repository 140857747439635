import { useQuery } from "@tanstack/react-query";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import Loader from "../../components/elements/loader";
import { getUser } from "../api/profile/getUser";
import { auth } from "../lib/firebaseClient";
import { adminRoutes } from "./adminRoutes";
import { protectedRoutes } from "./protectedRoutes";
import { publicRoutes } from "./publicRoutes";

export default function Router() {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  const { data, isLoading: isUserDataLoading } = useQuery({
    queryKey: ["user"],
    queryFn: async () => await getUser(auth.currentUser?.uid),
    enabled: !!user,
  });

  const userData = data?.user;

  const getRoutes = () => {
    if (loading || isUserDataLoading) {
      return [
        ...publicRoutes,
        {
          path: "*",
          element: <Loader subtitle="Loading user data..." />,
        },
      ];
    }

    if (!user) {
      return [
        ...publicRoutes,
        {
          path: "*",
          element: <Navigate to="/" state={{ from: location }} replace />,
        },
      ];
    }

    if (!userData && location.pathname.includes("/admin")) {
      return [
        ...publicRoutes,
        ...adminRoutes,
        {
          path: "*",
          element: <Loader subtitle="Loading user data..." />,
        },
      ];
    }

    if (!userData) {
      return [
        ...publicRoutes,
        ...protectedRoutes,
        {
          path: "*",
          element: (
            <Navigate
              to="/therapist/profile"
              state={{ from: location }}
              replace
            />
          ),
        },
      ];
    }

    if (userData.role === "admin") {
      return [
        ...publicRoutes,
        ...adminRoutes,
        {
          path: "*",
          element: (
            <Navigate to="/admin/users" state={{ from: location }} replace />
          ),
        },
      ];
    }

    return [
      ...publicRoutes,
      ...protectedRoutes,
      {
        path: "*",
        element: (
          <Navigate
            to="/therapist/profile"
            state={{ from: location }}
            replace
          />
        ),
      },
    ];
  };

  const element = useRoutes(getRoutes());

  return element;
}

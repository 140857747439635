import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { getAllArticles } from "../app/api/articles";
import ArticleItem from "../components/elements/articles/articleItem";
import Loader from "../components/elements/loader";
import("../styles/articles.scss");
import("../styles/global.scss");
import("../styles/sections.scss");

const staggeredVariants = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  show: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

function Articles() {
  const [searchTerm, setSearchTerm] = useState("");
  const getAllArticlesMutation = useQuery({
    queryKey: ["getAllArticlesAdmin"],
    queryFn: async () => {
      const response = await getAllArticles({
        status: "approved",
        page: 1,
        limit: 10,
      });
      return response;
    },
  });

  const articles = useMemo(
    () => getAllArticlesMutation.data?.articles || [],
    [getAllArticlesMutation.data]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const filteredArticles = useMemo(
    () =>
      articles.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [articles, searchTerm]
  );

  if (getAllArticlesMutation.isLoading) return <Loader subtitle="Loading..." />;

  return (
    <>
      <div className="articlesWrap">
        <motion.div
          className="title"
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          variants={itemVariants}
        >
          <motion.h1 variants={itemVariants}>Articles</motion.h1>
          {/* <motion.img
            variants={itemVariants}
            src="../images/illustrations/titleWrapSquare.svg"
            alt="Title decoration"
          /> */}
        </motion.div>
        <motion.div
          className="articlesControls"
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
          variants={staggeredVariants}
        >
          <motion.div variants={itemVariants} className="searchArticles">
            <label>Search Articles</label>
            <input
              placeholder="Search Articles"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </motion.div>
        </motion.div>

        <div className="articlesContent">


          <motion.div
            className="articles"
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            variants={staggeredVariants}
          >
            <div className="wall">
              {filteredArticles.map((article) => {
                return (
                  <motion.div
                    key={article.id}
                    variants={itemVariants}
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                  >
                    <ArticleItem
                      title={article.title}
                      content={article.content}
                      userName={article.user.name}
                      specialisms={article.specialisms}
                      createdAt={article.createdAt}
                      userId={article.user.id}
                      picture={
                        article.picture ||
                        "../images/placeholders/articleThumbnail.png"
                      }
                      profilePicture={
                        article.user.profile.profilePicture ||
                        "../images/placeholders/philip.png"
                      }
                      articleId={article.id}
                    />
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Articles;

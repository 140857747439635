import { api } from "../../lib/createClient";

export const getAllArticles = async ({
  status = "approved",
  page = 1,
  limit = 10,
  specialisms = "",
}) => {
  try {
    const response = await api.get(
      `/article?status=${status}&page=${page}&pageSize=${limit}&specialisms=${specialisms}&status=approved`
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

export const getFileUrl = (fileType, userId, fileName) => {
  const projectId = import.meta.env.VITE_FIREBASE_ID;
  let path = "";

  switch (fileType) {
    case "profilePicture":
      path = `user/${userId}/profilePicture`;
      break;
    case "blogImage":
      path = `blog/${userId}`;
      break;
    case "furniture":
      path = `user/${userId}/furniture`;
      break;
    case "insurance":
      path = `user/${userId}/insurance`;
      break;
    case "article":
      path = `article/${userId}`;
      break;
    default:
      throw new Error("Invalid file type");
  }

  return `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/${encodeURIComponent(
    path,
  )}%2F${encodeURIComponent(fileName)}?alt=media`;
};

import React, { useEffect, useRef } from "react";
const loadLottie = import("lottie-web");

const SofaOrangeHover = ({ onReady }) => {
    const lottieRef = useRef(null);
    const animationInstance = useRef(null);

    useEffect(() => {
        loadLottie.then((module) => {
            const lottie = module.default;

            animationInstance.current = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: "svg",
                loop: false,
                autoplay: false, // Only play on hover
                path: "/images/animations/sofas/sofaOrange.json", // Animation path
            });

            if (onReady) {
                onReady({
                    play: () => animationInstance.current?.play(),
                    stop: () => animationInstance.current?.stop(),
                });
            }
        });

        return () => {
            if (animationInstance.current) {
                animationInstance.current.destroy(); // Cleanup
            }
        };
    }, [onReady]);

    return <div ref={lottieRef} className="featureImage2"></div>;
};

export default SofaOrangeHover;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function ShortTerm() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../counselling-services/">Types of Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Short-term Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Short-term Therapy</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/typesOfTherapy/article/shortterm.jpg" />
                </div>
              </div>
              <div className="content">
                <h2>What is Short term therapy?</h2>
                <p>Short term therapy is somewhat self-descriptive, but with many underlying details that you must know before making the decision to start.</p>
                <p>There are several forms of short-term therapy (CBT, IPT and SFT), but a few common factors include:</p>
                <ul>
                  <li>Goal-orientated sessions</li>
                  <li>Homework built into the structure of the sessions</li>
                  <li>Can offer coping mechanisms if needed</li>
                </ul>
                <p>All of this combined creates a set expectations for therapy which can be intensive yet effective for certain issues and ailments.</p>
                <p>That being said, many therapists will also describe 6-8 sessions of Integrative therapy as short-term therapy.</p>
                <h2>What to expect from Short term therapy.</h2>
                <p>If you are considering starting short term therapy, you must first relate it back to the issue you want to address. It is commonly believed and proven that all mental issues stem from past traumas or experiences. This means that in order to permanently diminish symptoms or bad habits, you have the dig deep to the root of the issue.</p>
                <p>This can be difficult during short term therapy and therefore doesn’t allow much time to grasp deeper issues. Which is when we would recommend long-term or open-ended therapy.&nbsp;</p>
                <p>What short-term therapy does offer very effectively, is a way of coping through current debilitating symptoms standing in your way. Short term therapy has been found to be highly effective with issues such as:&nbsp;</p>
                <ul>
                  <li>Panic attacks and anxiety attacks</li>
                  <li>Tremors</li>
                  <li>Stutters</li>
                  <li>Symptoms or ADHD</li>
                </ul>
                <p>These issues can make it difficult to function to the best of your abilities in your day to day life. Through coping mechanisms (Including: Tapping / EFT, Manifestation or Meditation)&nbsp;</p>
                <p>you are then able to gain the mental space to delve into past experiences or behaviours without symptoms or attacks interrupting your thoughts, feelings and actions.</p>
                <h2>Can I have short term therapy if I don’t have panic attacks?</h2>
                <p>Even if you do not exhibit these symptoms or issues, that doesn’t mean that short-term therapy isn’t for you. If you would like to see how you feel about therapy, many long-term therapists will also offer a batch of sessions. In these sessions you will have the space and time to delve into past experiences, but with an end in mind.</p>
                <p>All therapists aim to create a tidy and gradual end to therapy. In sessions you can feel your most vulnerable or most sensitive and in order for you to go back into the real world without the support of weekly therapy, your therapist will want to make sure things are wraps up neatly without any lingering questions or worries.</p>
                <h2>Should I start short term therapy?</h2>
                <p>If you would like support and help tackling current debilitating symptoms, then a CBT practitioner will be able to assist you in controlling those uncontrollable issues.</p>
                <p>If you would like to start therapy but are still unsure if it is for you, then a integrative therapist will be happy to discuss a way to introduce you to therapy. This way both of you have an idea of when you can discuss either finishing or continuing long-term.</p>
                <p>Therapy can feel daunting and somewhat of a commitment, but practitioners are more than happy to discuss how to make starting sessions as easy as possible for you, in the safest way possible.&nbsp;</p>
                <h2>Does Therapy in London offer short term therapy?</h2>
                <p>We prioritise you becoming the best version of yourself. This means ensuring you have the space and time to fully understand and rework your old way of life as well as any trauma or other mental issues.</p>
                <p>All of our therapists work with an Integrative method, meaning we use all forms of therapy that is best suited for you in the room. We are happy to discuss your needs in terms of timescale and what you expect from therapy. What we can also offer is our own transparency on the time you may need to seek positive results from therapy.</p>
                <p>Even if we feel that short term therapy may not be able to offer you the results you want, we are still able to talk through options with you. Including arranging a batch of sessions to start with (usually starting with 6 consecutive sessions). This is also the ideal amount of time to either help make your decision to carry on, or round off therapy in the most efficient and effective way possible.</p>

              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default ShortTerm;

import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const submitArticle = async (articleId) => {
  try {
    const response = await api.post(
      `/article/submit/${articleId}`,
      {},
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error creating profile:", error);
    throw error;
  }
};

import { Route, Routes } from "react-router-dom";
import HowToEndTherapy from "../howTo/articles/endTherapy";
import HowToFindATherapist from "../howTo/articles/findTherapist";
import HowToMakeTheMost from "../howTo/articles/mostFromTherapy";
import HowTo from "../howTo/howTo";

export const HowtoRoutes = () => {
    return (
        <Routes>
            <Route index element={<HowTo />} />
            <Route path="find-a-therapist" element={<HowToFindATherapist />} />
            <Route path="get-the-most-from-therapy" element={<HowToMakeTheMost />} />
            <Route path="end-therapy" element={<HowToEndTherapy />} />
        </Routes>
    );
};

import PropTypes from "prop-types";
import CTA from "../fragments/CTA";

const TodoItem = ({ text, isCompleted }) => (
  <li>
    <span>
      <img
        src={
          isCompleted
            ? "/images/illustrations/tick-white.svg"
            : "/images/icons/!.svg"
        }
        alt={isCompleted ? "tick" : "cross"}
      />
    </span>
    {text}
  </li>
);

TodoItem.propTypes = {
  text: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export const WizardBanner = ({ profile }) => {
  const todoItems = [
    {
      text: "Customise Profile",
      isCompleted:
        profile.profile?.id &&
        profile.profile.id !== "00000000-0000-0000-0000-000000000000",
    },
    {
      text: "Proof of Insurance & Membership Body",
      isCompleted:
        profile.profile?.insuranceFile && profile.profile.insuranceFile !== "",
    },
    {
      text: "Send for Review",
      isCompleted:
        profile.profile?.status && profile.profile.status !== "draft",
    },
    // {
    //   text: "Wait for approval",
    //   isCompleted:
    //     profile.profile?.status &&
    //     profile.profile.status !== "awaitingApproval",
    // },

    {
      text: "Complete Payment",
      isCompleted: profile.subscriptionStatus === true,
    },
  ];

  const getCtaProps = () => {
    if (
      !todoItems[0].isCompleted ||
      !todoItems[1].isCompleted ||
      !todoItems[2].isCompleted
    ) {
      return {
        to: "/therapist/profile/edit",
        text: todoItems[0].isCompleted ? "Continue" : "Get Started",
      };
    }
    if (profile.profile?.status === "awaitingApproval") {
      return { to: "", text: "Pending approval, hold tight!" };
    }
    if (profile.profile?.status === "rejected") {
      return {
        to: "/therapist/profile/edit",
        text: "Modify Profile",
      };
    }
    if (!todoItems[3].isCompleted) {
      return {
        to: "/therapist/billing",
        text: "Account Approved, Click to Subscribe",
      };
    }

    return { to: "/therapist/profile/edit", text: "Edit Profile" };
  };

  const ctaProps = getCtaProps();

  return (
    <>
      {profile.profile?.status !== "rejected" ? (
        <div className="notificationBanner_wrap">
          <div className="content">
            <div className="title">
              <h3 className="notification">Welcome to Therapy in London</h3>

              <p>
                Your account has been created, but your profile isn't published.
              </p>
              <div className="controls">
                <CTA type="blue" text={ctaProps.text} link={ctaProps.to} />
              </div>
              <img src="/images/illustrations/pen.svg" alt="pen" />
            </div>

            <div className="todoList">
              <h3>To Do List</h3>
              <ul>
                {todoItems.map((item, index) => (
                  <TodoItem
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    text={item.text}
                    isCompleted={item.isCompleted}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="notificationBanner_wrap bannerReject">
          <div className="content">
            <div className="title">
              <h3 className="notification">Your profile has been rejected</h3>

              <p>
                Your account has been rejected, you can modify and resubmit.
              </p>
              <div className="controls">
                <CTA type="blue" text={ctaProps.text} link={ctaProps.to} />
              </div>
              <img src="/images/illustrations/pen.svg" alt="pen" />
            </div>

            <div className="todoList">
              <div className="rejectedMessage">
                <h3>Message</h3>
                <p>{profile.profile?.statusMessage}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WizardBanner.propTypes = {
  profile: PropTypes.object.isRequired,
};

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Abuse() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Abuse</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Abuse</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/abuse.jpg" />
                </div>
              </div>
              <div className="content">
                <h2>Abuse is seen as regular and repeated violence, cruelty, bullying or neglect.</h2>
                <p>Whether this is physical or psychological, any suspicions of abuse are valid and should be acted on.</p>
                <p>What is important from a mental health standpoint is firstly recognising, then coming to terms and moving forward following abuse.</p>
                <p>Being abused firstly begins with understanding the different forms it can take. It can be physical violence or emotional such as:</p>
                <ul>
                  <li>Gaslighting</li>
                  <li>Manipulation</li>
                  <li>Bigotry</li>
                  <li>Intimidation</li>
                  <li>Deception</li>
                </ul>
                <p>You might hear many say that if they <em>keep</em> doing it, then it’s abuse. This is false, as any kind of cruelty or neglect from the offset is abusive if it is done intentionally.</p>
                <p>Once you have understood what is considered abuse and you are feeling victimised by someone, no matter how much you believe <em>‘they will get better’</em> – take action today for your own personal wellbeing.</p>
                <h2>What causes abuse.</h2>
                <p>The cause of abuse can be a loaded question as when becoming the victim of abuse, the most common misconception is that you deserve it, or that they don’t mean it.</p>
                <p>Acknowledging false causes is the first step to recovery from abuse. Leaving an abusive home or situation is a difficult next step to take. What can help in taking this step is to believe that you, as a victim, are not the cause of it.</p>
                <p>Trying to find reasoning in an abuser’s actions simply gives you reasoning to stay in that environment. Therefore, considering yourself and yourself alone, will help you detach from the other person’s thoughts and feelings.</p>
                <h2>How therapy can help you.</h2>
                <p>Creating a new way for you to relate to the world and a new self-worth. Therapy gives grounding to take care of your needs and what you expect of yourself. It is also a space to treat other issues resulting from the abuse such as</p>
                <h2>How Therapy in London can help.</h2>
                <p>Being the victim of abuse can feel like a lonely position to be in. No matter what your circumstances or background, you deserve to live a fulfilling and happier life. We ensure to find you a counsellor who is able to guide you through what you have been through. Their job is to help you fully understand its impact and how you can come out the other side as the best version of yourself.</p>
                <p>Growth after abuse is possible for anyone, and we can make that journey feel a little less daunting.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Abuse;

import { useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import("../../styles/global.scss");
import Footer from "../elements/footer";
import NavBar from "../elements/navbar";
import MetaData from "../fragments/MetaData";

function Layout() {
  const { pathname } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaData />
      <div>
        <NavBar />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Layout;

import { Route, Routes } from "react-router-dom";
import ADHD from "../findhelpfor/articles/ADHD";
import BDD from "../findhelpfor/articles/BDD";
import BPD from "../findhelpfor/articles/BPD";
import OCD from "../findhelpfor/articles/OCD";
import PPS from "../findhelpfor/articles/PPS";
import PTSD from "../findhelpfor/articles/PTSD";
import SAD from "../findhelpfor/articles/SAD";
import Abuse from "../findhelpfor/articles/abuse";
import Addiction from "../findhelpfor/articles/addiction";
import Anxiety from "../findhelpfor/articles/anxiety";
import Attachment from "../findhelpfor/articles/attachmentDisorder";
import Bereavement from "../findhelpfor/articles/bereavement";
import Bipolar from "../findhelpfor/articles/bipolor";
import Commitment from "../findhelpfor/articles/commitmentIssues";
import Confidence from "../findhelpfor/articles/confidence";
import Depression from "../findhelpfor/articles/depression";
import Disabled from "../findhelpfor/articles/disabledIndividuals";
import Dissociation from "../findhelpfor/articles/dissociation";
import Divorce from "../findhelpfor/articles/divorce";
import EatingDisorder from "../findhelpfor/articles/eatingDisorder";
import GenderIdentity from "../findhelpfor/articles/genderIdentity";
import HealthAnxiety from "../findhelpfor/articles/healthAnxiety";
import Hoarding from "../findhelpfor/articles/hoarding";
import Hypervigiliance from "../findhelpfor/articles/hypervigilance";
import LGBTQ from "../findhelpfor/articles/LGBTQ";
import Loneliness from "../findhelpfor/articles/loneliness";
import LowSelfEsteem from "../findhelpfor/articles/lowSelfEsteem";
import Lying from "../findhelpfor/articles/lying";
import MenMentalHealth from "../findhelpfor/articles/mensMentalHealth";
import Neurodiversity from "../findhelpfor/articles/neurodiversity";
import PanicAttacks from "../findhelpfor/articles/panicAttacks";
import Paranoia from "../findhelpfor/articles/paranoia";
import Phobia from "../findhelpfor/articles/phobia";
import Postnatal from "../findhelpfor/articles/postnatalDepression";
import Procrastination from "../findhelpfor/articles/procrastination";
import SelfHarm from "../findhelpfor/articles/selfHarm";
import SeperationAnxiety from "../findhelpfor/articles/serpatationAnxiety";
import Seperation from "../findhelpfor/articles/serperation";
import SexIssues from "../findhelpfor/articles/sexIssues";
import SuicidalThoughts from "../findhelpfor/articles/suicidalThoughts";
import Trauma from "../findhelpfor/articles/trauma";
import WomenMentalHealth from "../findhelpfor/articles/womensMentalHealth";
import WorkIssues from "../findhelpfor/articles/workIssues";
import FindHelpFor from "../findhelpfor/findhelpfor";

export const FindHelpForRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FindHelpFor />} />
      <Route path="abuse" element={<Abuse />} />
      <Route path="addiction" element={<Addiction />} />
      <Route path="adhd" element={<ADHD />} />
      <Route path="anxiety" element={<Anxiety />} />
      <Route path="attachment-disorder" element={<Attachment />} />
      <Route path="body-dysmorphia" element={<BDD />} />
      <Route path="bipolar" element={<Bipolar />} />
      <Route path="bereavement" element={<Bereavement />} />
      <Route path="bpd" element={<BPD />} />
      <Route path="commitment-issues" element={<Commitment />} />
      <Route path="confidence" element={<Confidence />} />
      <Route path="depression" element={<Depression />} />
      <Route path="therapy-for-disabled" element={<Disabled />} />
      <Route path="dissociation" element={<Dissociation />} />
      <Route path="divorce" element={<Divorce />} />
      <Route path="eating-disorders" element={<EatingDisorder />} />
      <Route path="gender-identity" element={<GenderIdentity />} />
      <Route path="health-anxiety" element={<HealthAnxiety />} />
      <Route path="hoarding" element={<Hoarding />} />
      <Route path="hypervigilance" element={<Hypervigiliance />} />
      <Route path="lgbtq-issues" element={<LGBTQ />} />
      <Route path="loneliness" element={<Loneliness />} />
      <Route path="low-self-esteem" element={<LowSelfEsteem />} />
      <Route path="lying" element={<Lying />} />
      <Route path="mens-mental-health" element={<MenMentalHealth />} />
      <Route path="neurodiversity" element={<Neurodiversity />} />
      <Route path="ocd" element={<OCD />} />
      <Route path="panic-attacks" element={<PanicAttacks />} />
      <Route path="paranoia" element={<Paranoia />} />
      <Route path="phobia" element={<Phobia />} />
      <Route path="postnatal-depression" element={<Postnatal />} />
      <Route path="peter-pan-syndrome" element={<PPS />} />
      <Route path="procrastination" element={<Procrastination />} />
      <Route path="ptsd" element={<PTSD />} />
      <Route path="seasonal-affective-disorder" element={<SAD />} />
      <Route path="self-harm" element={<SelfHarm />} />
      <Route path="separation-anxiety" element={<SeperationAnxiety />} />
      <Route path="separation" element={<Seperation />} />
      <Route path="sex-issues" element={<SexIssues />} />
      <Route path="suicidal-thoughts" element={<SuicidalThoughts />} />
      <Route path="trauma" element={<Trauma />} />
      <Route path="womens-mental-health" element={<WomenMentalHealth />} />
      <Route path="work-and-career-issues" element={<WorkIssues />} />
    </Routes>
  );
};

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function BDD() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Body Dysmorphia (BDD)</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Body Dysmorphia (BDD)</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/BDD.jpg" alt="BDD image" />
                </div>
              </div>
              <div className="content">
                <p>When experiencing Body Dysmorphia, your main focus is what others might think of your appearance and what you personally want to change.</p>
                <p>What you see in the mirror will usually be vastly different from what others see. In extreme cases, personal goals can become unhealthy and unobtainable.</p>
                <p>When experiencing Body Dysmorphia, you see a distorted perception of yourself. As BDD develops, you can also take on compulsive behaviors to give yourself a sense of control over what you see in the mirror.</p>
                <p>It can affect the way you act and think as you find it more difficult to not let your appearance affect your decisions and day-to-day life.</p>
                <p>Symptoms of this disorder vary from person to person. What is common throughout is a felt need for control over your body or certain aspects of your body. This can develop into symptoms such as:</p>
                <ul>
                  <li>Picking/plucking at skin, hair, nails, etc., to make them ‘smooth’ or ‘perfect’.</li>
                  <li>Difficulty in not thinking about your appearance or flaws</li>
                  <li>Constant dieting</li>
                  <li>Constant exercising</li>
                  <li>Avoiding social situations because of your appearance</li>
                </ul>
                <p>In extreme cases and if not treated, it can cause:</p>
                <ul>
                  <li>Anorexia</li>
                  <li>Bulimia</li>
                  <li>Self-harm or even suicidal thoughts</li>
                  <li>Depression</li>
                </ul>
                <p>When BDD is left ignored, you can be left never seeing change no matter what you do. You can constantly hope to see a better version of yourself, yet will not see it. This can lead to extreme cases, such as hospitalization or rehabilitation.</p>
                <h2>What causes Body Dysmorphia?</h2>
                <p>When trying to understand any mental health issue, usually the focus will turn to what you are seeking to gain control over.</p>
                <p>Many past issues can lead to BDD if you intend to gain back control either from a traumatic event or an abusive past. Whether these issues seem big or small to you, they will still affect the way you see yourself and what you think you need to improve on.</p>
                <p>Common experiences that can lead to this issue include:</p>
                <ul>
                  <li>Bullying at home or at school</li>
                  <li>Abusive parents, including high achievers or lack of guidance</li>
                  <li>Lack of body positivity in your past</li>
                </ul>
                <p>These are just some of the reasons why you can be affected by BDD. The cause is also vital in finding the right treatment for you. Once you understand this, you can gauge what you really want and see past the need for perfection.</p>
                <h2>How therapy can help.</h2>
                <p>When understanding how to deal with your BDD, you must first look at when it started and what you were/are looking to gain from it. This is where talking therapy will help you delve into your goals and how you can achieve them in the safest yet most realistic way possible.</p>
                <p>Your thoughts and feelings are always valid, and therapy will help you create a new reality and narrative for those feelings.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>You create your own reality, and having others understand your reality can seem like a challenge. What our practitioners are here to do is be a constant in your narrative and create a future for yourself that is healthy, happy, and successful.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default BDD;

import propTypes from 'prop-types';


const Spinner = ({ size = 24, color = '#007bff', borderWidth = 3 }) => {
  const spinnerStyle = {
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
    border: `${borderWidth}px solid ${color}`,
    borderRadius: '50%',
    borderTopColor: 'transparent',
    animation: 'spin 1s linear infinite',
  };

  return (
    <div style={spinnerStyle}>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};
Spinner.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
  borderWidth: propTypes.number,
};
export default Spinner;

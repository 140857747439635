import { motion } from "framer-motion";
import("../../../styles/articles.scss");
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

function HealthAnxiety() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img
              src="/images/illustrations/underline.svg"
              alt="Underline decoration"
            />
          </div>
          <img
            src="/images/icons/chevron_right.svg"
            className="chevron"
            alt="Chevron right"
          />
          <div className="breadcrumb current_page">
            <a href="#">Health Anxiety (Hypochondria)</a>
            <img
              src="/images/illustrations/underline.svg"
              alt="Underline decoration"
            />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Health Anxiety (Hypochondria)</h1>
                    <img
                      src="/images/illustrations/purpleScribble.svg"
                      className="titleScribble"
                      alt="Purple scribble decoration"
                    />
                  </div>
                </div>
                <div className="featured_image">
                  <img
                    src="/images/articles/findHelpFor/health-anxiety.jpg"
                    alt="Health Anxiety"
                  />
                </div>
              </div>
              <div className="content">
                <p>
                  Health Anxiety (or Hypochondria) means you are in regular or
                  constant fear of being ill or developing an illness.
                </p>
                <p>
                  Even with health anxiety, you can function day-to-day without
                  it having a negative impact on your life. Many, however, can
                  find that their thoughts are focused on whether they are
                  unwell or will become unwell.
                </p>
                <p>
                  Whilst living with health anxiety, you can find yourself
                  turning to several habits and routines:
                </p>
                <ul>
                  <li>
                    Being paranoid of aches, pains, bumps and bodily marks
                  </li>
                  <li>Thinking you are ill regularly</li>
                  <li>Trying new health trends or diets</li>
                  <li>Doing many tests and checks for illnesses</li>
                  <li>
                    Taking regular preventative measures such as daily ibuprofen
                    and paracetamol
                  </li>
                </ul>
                <p>
                  Some of these can be harmless, but it is important to
                  recognise and reassess habits. This is because they can
                  potentially complicate and impact your physical health and
                  lifestyle. You can, for example:
                </p>
                <ul>
                  <li>
                    Cause real health issues from diets or preventive medication
                  </li>
                  <li>
                    Lose faith in doctors and other professionals’ advice due to
                    your own paranoia
                  </li>
                  <li>
                    Become distant from friends and family due to them not
                    believing you
                  </li>
                  <li>
                    Impact your work life balance from taking too many sick days
                    or doctors appointments
                  </li>
                </ul>
                <h2>What causes Health Anxiety?</h2>
                <p>Any anxiety develops from a lack of control in your life.</p>
                <p>
                  Health anxiety specifically, forms habits around your health
                  in the hopes of feeling ‘safe’. As things in your life - like
                  everyone – either fail or change, it can feel all-consuming
                  and as if you have lost your way.
                </p>
                <p>
                  This is when hypochondria becomes a way of dealing with the
                  one thing you believe you can consistently control – your
                  body.
                </p>
                <h2>How therapy can help.</h2>
                <p>
                  Once you establish what hypochondria is attempting to fill in
                  your life, you can then work on how to rework your health
                  anxiety habits.
                </p>
                <p>
                  Therapy is a way for you to relive what you feel and think
                  when you are living through these anxieties and what those
                  triggers are.
                </p>
                <p>
                  This process during therapy means being able to understand
                  what hypochondria is trying to protect you from and what you
                  can do instead to create a happier life, away from that
                  anxiety.
                </p>
                <h2>
                  How <em>Therapy in London</em> can help.
                </h2>
                <p>
                  As your focus has been on fears that others aren’t
                  experiencing, that can feel quite lonely. Our practitioners
                  want to fully understand what you are experiencing. Therapy
                  creates an environment with someone who can wholly listen to
                  and understand everything you are feeling and thinking.
                </p>
                <p>
                  Within that safe space, you can use the time to explore who
                  can be away from your anxieties. Not just by forgetting them,
                  but by using them to your advantage. We want you to be able to
                  see a life away from those fears yet where they have made you
                  stronger than ever.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default HealthAnxiety;

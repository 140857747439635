import { api } from "../../lib/createClient";

export const getBlogs = async ({ query = "", page = 1, pageSize = 10 }) => {
  try {
    const searchParams = new URLSearchParams();
    if (query) searchParams.set("query", query);
    searchParams.set("page", page.toString());
    searchParams.set("pageSize", pageSize.toString());

    const response = await api.get(`/blog?${searchParams.toString()}`);
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting blogs:", error);
    throw error;
  }
};

import propTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import("../../styles/global.scss");
import("../../styles/loader.scss");
import LoaderAnimation from "../animations/Loader";
import LineBackground from "../fragments/lineBackground";

Loader.propTypes = {
  subtitle: propTypes.string,
};

function Loader({ subtitle = "Loading" }) {
  const textRef = useRef(null);
  const [text] = useState(subtitle);

  useEffect(() => {
    let index = 0;
    let forward = true;
    const typingSpeed = 100; // typing speed in ms
    const pauseDuration = 1000; // pause before restarting the loop
    let timeoutId;

    function type() {
      if (forward) {
        textRef.current.innerHTML = text.slice(0, index + 1);
        index++;
        if (index === text.length) {
          forward = false;
          timeoutId = setTimeout(type, pauseDuration);
          return;
        }
      } else {
        textRef.current.innerHTML = text.slice(0, index - 1);
        index--;
        if (index === 0) {
          forward = true;
          timeoutId = setTimeout(type, typingSpeed);
          return;
        }
      }
      timeoutId = setTimeout(type, typingSpeed);
    }

    type();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [text]);

  return (
    <div className="loader_wrap">
      <LineBackground />
      <div className="loader_center">
        <div className="loader_block">
          <div className="title">
            <LoaderAnimation />
            <p ref={textRef} className="typing-text" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;

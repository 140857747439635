import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const updateArticle = async ({
  articleId,
  title,
  content,
  specialisms,
}) => {
  try {
    const response = await api.put(
      `/article/${articleId}`,
      {
        title,
        content,
        specialisms,
      },
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

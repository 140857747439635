import { toast } from "react-toastify";

const useToast = () => {
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const notifyFailure = (message) => {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  return { notifySuccess, notifyFailure };
};

export default useToast;

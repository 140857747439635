import { auth } from "../../lib/firebaseClient";

export const uploadProfilePicture = async (userId, image) => {
  try {
    if (!userId || !image) {
      return;
    }
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("image", image);

    const token = await auth.currentUser.getIdToken();

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/profile/picture`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};

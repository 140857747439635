import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({ items }) => {
  return (
    <div className="breadcrumbs_wrap">
      {items.map((item, index) => (
        <React.Fragment
          key={`breadcrumb${
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            index
          }`}
        >
          <div
            className={`breadcrumb ${
              index === items.length - 1 ? "current_page" : ""
            }`}
          >
            <Link to={item.link}>{item.text}</Link>
            <img
              src="../../images/illustrations/underline.svg"
              alt="underline"
            />
          </div>
          {index < items.length - 1 && (
            <img
              src="../../images/icons/chevron_right.svg"
              className="chevron"
              alt="chevron"
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Breadcrumbs;

import { create } from "zustand";

const initialSections = [
  {
    id: "quote",
    dbId: null,
    title: "My favourite quote is...",
    content: "",
    order: 1,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "therapist",
    dbId: null,
    title: "I became a Therapist because..",
    content: "",
    order: 2,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "help",
    dbId: null,
    title: "I am going to help you by..",
    content: "",
    order: 3,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "sessions",
    dbId: null,
    title: "In our sessions we will...",
    content: "",
    order: 4,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "impactful",
    dbId: null,
    title: "My most impactful experience was..",
    content: "",
    order: 5,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "reading",
    dbId: null,
    title: "I'm currently reading..",
    content: "",
    order: 6,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "watching",
    dbId: null,
    title: "I'm currently watching..",
    content: "",
    order: 7,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "listening",
    dbId: null,
    title: "I'm currently listening to...",
    content: "",
    order: 8,
    hidden: false,
    isThumbnail: false,
  },
  {
    id: "color",
    dbId: null,
    title: "My favourite colour is...",
    content: "",
    order: 9,
    hidden: false,
    isThumbnail: false,
  },
];

const defaultPricePackage = [
  { name: "", price: "" },
  { name: "", price: "" },
  { name: "", price: "" },
];

export const useProfileStore = create((set) => ({
  profile: {
    userId: "",
    lamp: 1,
    plant: 1,
    sofa: 1,
    color: "#F6612E",
    phoneNumber: "",
    email: "",
    name: "",
    qualifications: "",
    typesOfTherapy: [],
    wheelchairAccess: null,
    languages: [],
    sessionTypes: [],
    specialisms: [],
    insuranceFile: null,
    photo: null,
    quote: "",
    furniturePicture: null,
    selectedFurniturePicture: null,
    selectedPhoto: null,
    locationObj: {
      id: "",
      country: "",
      city: "",
      county: "",
      postCode: "",
      latitude: null,
      longitude: null,
    },
    location: "",
    profileInfos: initialSections,
    pricePackage: defaultPricePackage,
    membershipBody: [],
    article1: null,
    article2: null,
  },

  initializeFromBackend: (backendData) =>
    set((state) => {
      let pricePackage;

      if (
        Array.isArray(backendData.pricePackage) &&
        backendData.pricePackage.length > 0
      ) {
        pricePackage = backendData.pricePackage.map((pkg) => ({
          name: pkg.name || "",
          price: pkg.price || "",
        }));

        // Ensure we always have exactly 3 price packages
        while (pricePackage.length < 3) {
          pricePackage.push({ name: "", price: "" });
        }
      } else {
        pricePackage = defaultPricePackage;
      }
      const combinedProfileInfos = initialSections.map((initialSection) => {
        const backendSection = backendData.profileInfos?.find(
          (section) => section.title === initialSection.title
        );
        return backendSection
          ? { ...initialSection, ...backendSection }
          : initialSection;
      });
      // biome-ignore lint/complexity/noForEach: <explanation>
      backendData.profileInfos?.forEach((backendSection) => {
        if (
          combinedProfileInfos.some(
            (section) => section.title === backendSection.title
          )
        ) {
          combinedProfileInfos.push(backendSection);
        }
      });

      return {
        profile: {
          ...state.profile,
          ...backendData,
          qualifications: backendData.qualifications || "",
          location:
            backendData.profile.location?.formattedAddress ||
            backendData.profile.location ||
            "",
          phoneNumber: backendData.phoneNumber || "",
          typesOfTherapy: backendData.typesOfTherapy || [],
          languages: backendData.languages || [],
          sessionTypes: backendData.sessionTypes || [],
          specialisms: backendData.specialisms || [],
          membershipBody: backendData.membershipBody || [],
          pricePackage: pricePackage,
          profileInfos: combinedProfileInfos,
          locationObj: backendData.profile.location || {
            country: "",
            city: "",
            county: "",
            postCode: "",
            latitude: null,
            longitude: null,
          },
        },
      };
    }),

  updateProfile: (newProfile) =>
    set((state) => {
      const updatedProfile = { ...state.profile };

      // biome-ignore lint/complexity/noForEach: <explanation>
      Object.keys(newProfile).forEach((key) => {
        if (key === "profileInfos") {
          const existingProfileInfosMap = new Map(
            newProfile.profileInfos.map((info) => [info.title, info])
          );
          const mergedProfileInfos = initialSections.map((defaultInfo) => {
            const existingInfo = existingProfileInfosMap.get(defaultInfo.title);
            if (existingInfo) {
              return {
                ...defaultInfo,
                ...existingInfo,
                id: existingInfo.id,
                title: defaultInfo.title,
              };
            }
            return {
              ...defaultInfo,
              id: defaultInfo.id,
              content: "",
              order:
                Math.max(
                  ...(newProfile.profileInfos.map((i) => i.order) || [0])
                ) + 1,
            };
          });
          updatedProfile.profileInfos = mergedProfileInfos.sort(
            (a, b) => a.order - b.order
          );
        } else if (newProfile[key] !== state.profile[key]) {
          updatedProfile[key] = newProfile[key];
        }
      });

      return { profile: updatedProfile };
    }),

  setSelectedPhoto: (photo) => set({ selectedPhoto: photo }),
  updateProfileInfoContent: (id, content) =>
    set((state) => ({
      profile: {
        ...state.profile,
        profileInfos: state.profile.profileInfos.map((info) =>
          info.id === id ? { ...info, content } : info
        ),
      },
    })),

  setThumbnail: (id) =>
    set((state) => ({
      profile: {
        ...state.profile,
        profileInfos: state.profile.profileInfos.map((info) =>
          info.id === id
            ? { ...info, isThumbnail: true }
            : { ...info, isThumbnail: false }
        ),
      },
    })),

  addSpecialism: (newSpecialism) =>
    set((state) => ({
      profile: {
        ...state.profile,
        specialisms: [...state.profile.specialisms, newSpecialism],
      },
    })),

  removeSpecialism: (id) =>
    set((state) => ({
      profile: {
        ...state.profile,
        specialisms: state.profile.specialisms.filter(
          (specialism) => specialism.id !== id
        ),
      },
    })),

  clearStore: () =>
    set(() => ({
      profile: {
        userID: "",
        lamp: 1,
        plant: 1,
        sofa: 1,
        color: "#F6612E",
        phoneNumber: "",
        email: "",
        name: "",
        qualifications: "",
        typesOfTherapy: [],
        wheelchairAccess: false,
        languages: [],
        sessionTypes: [],
        specialisms: [],
        insuranceFile: null,
        photo: null,
        furniturePicture: null,
        selectedFurniturePicture: null,
        selectedPhoto: null,
        locationObj: {
          country: "",
          city: "",
          county: "",
          latitude: null,
          longitude: null,
        },
        location: "",
        profileInfos: initialSections,
        pricePackage: [
          { name: "", price: "" },
          { name: "", price: "" },
          { name: "", price: "" },
        ],
        membershipBody: [],
      },
    })),
  updatePricePackage: (index, field, value) =>
    set((state) => {
      const updatedPricePackage = [...state.profile.pricePackage];
      updatedPricePackage[index] = {
        ...updatedPricePackage[index],
        [field]: value,
      };
      return {
        profile: {
          ...state.profile,
          pricePackage: updatedPricePackage,
        },
      };
    }),
  // In profileStore.js
  updateSelectedArticles: (articleNumber, articleId) =>
    set((state) => {
      return {
        profile: {
          ...state.profile,
          [`article${articleNumber}`]: articleId || null,
        },
      };
    }),
  clearSelectedPhoto: () =>
    set(() => ({
      selectedPhoto: null,
    })),
}));

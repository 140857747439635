import("../../styles/global.scss");
import("../../styles/sections.scss");
import("../../styles/articles.scss");
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

export const AcceptableUse = () => {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../">Home</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Acceptable Use Policy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Acceptable Use Policy</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
              </div>
              <div className="content">
                <h2>1. ABOUT THIS POLICY</h2>
                <p>1.1 This Policy sets out the standards that apply when you use, upload and view our website <a href="https://www.therapyin.london" target="_blank">https://www.therapyin.london</a> (Website).</p>
                <p>1.2 By using our Website, you are agreeing to follow the terms of this policy. If you do not agree with this policy, you must not use our Website.</p>
                <p>1.3 We may amend this policy from time to time if the needs of our business change. Please check back regularly to check for any updates.</p>
                <p>1.4 This policy should be read alongside our website terms and conditions <a href="https://www.therapyin.london" target="_blank">https://www.therapyin.london</a> (Website Terms of Use) and our privacy notice found here <a href="https://www.therapyin.london" target="_blank">https://www.therapyin.london</a>.</p>

                <h2>2 WHO ARE WE</h2>
                <p>2.1 We are Therapy in London Partnership Limited registered in [England and Wales] with company number 12498132 whose registered address is Second Floor, 315 Regents Park Road, London, United Kingdom, N3 1DP (we/us/our).</p>

                <h2>3 USING OUR WEBSITE</h2>
                <p>3.1 When using our Website, you must not use our Website:</p>
                <ul>
                  <li>3.1.1 in any way that breaches any applicable local, national or international law or regulation;</li>
                  <li>3.1.2 in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect;</li>
                  <li>3.1.3 for the purpose of harming or attempting to harm minors in any way;</li>
                  <li>3.1.4 to bully, insult, intimidate or humiliate any person;</li>
                  <li>3.1.5 to send, knowingly receive, upload, download, use or re-use any material which does not comply with our terms of User Generated Content below;</li>
                  <li>3.1.6 to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);</li>
                  <li>3.1.7 to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;</li>
                  <li>3.1.8 to upload terrorist content;</li>
                  <li>3.1.9 to advertise Services outside of the scope of your mental health practice.</li>
                </ul>

                <h2>4 YOUR RESPONSIBILITIES</h2>
                <p>4.1 When using our website you must:</p>
                <ul>
                  <li>4.1.1 notify us immediately if you suspect that any use of the Services or Website is, or will lead to activity that is, fraudulent or unlawful;</li>
                  <li>4.1.2 notify us immediately of any circumstances where any intellectual property rights or any other rights of any third party may have been infringed;</li>
                  <li>4.1.3 not do or promote anything likely to impair, interfere with or damage our platform or electronic communications network, or cause harm, harassment or distress to any persons;</li>
                  <li>4.1.4 not do anything which involves the transmission of junk mail, chain letters, unsolicited mass mailing, instant messaging, “spimming” or “spamming”;</li>
                  <li>4.1.5 not misuse or attack our networks or platforms by knowingly introducing viruses, trojans, worms, logic bombs or any other material which is malicious or technologically harmful (such as by way of a denial-of-service attack);</li>
                  <li>4.1.6 not use our Website in any way which contravenes our Website Terms of Use, including:</li>
                  <ul>
                    <li>reproducing, duplicating, copying or re-selling any part of the Website;</li>
                    <li>accessing any of the Website without our authority; or</li>
                    <li>damaging or disrupting any part of the Website.</li>
                  </ul>
                </ul>

                <h2>5 USER GENERATED CONTENT</h2>
                <p>5.1 Where our Website enables you to post messages, profiles or any other form of communication on the Website (User Generated Content), you must not:</p>
                <ul>
                  <li>5.1.1 promote racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
                  <li>5.1.2 harass or advocate harassment of another person;</li>
                  <li>5.1.3 display pornographic or sexually explicit material;</li>
                  <li>5.1.4 promote any conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                  <li>5.1.5 promote any illegal activities;</li>
                  <li>5.1.6 provide instructional information about illegal activities, including violating someone else’s privacy;</li>
                  <li>5.1.7 create computer viruses or implement any form of software or scripts onto the Website that have the appearance of coming from a user or candidate (for the avoidance of doubt, this shall not apply to API use);</li>
                  <li>5.1.8 promote or contain information that you know or believe to be inaccurate, false or misleading;</li>
                  <li>5.1.9 engage in the promotion of contests, sweepstakes and pyramid schemes, without our prior written consent;</li>
                  <li>5.1.10 exploit people in a sexual or violent manner;</li>
                  <li>5.1.11 invade or violate any third party’s right to privacy.</li>
                </ul>
                <p>5.2 We reserve the right to refuse to publish any User Generated Content or links to third party content, or to at any time remove or edit User Generated Content (in whole or in part) or any link to third party content, if we have reason to believe that a user has breached this policy.</p>

                <h2>6 BREACH OF THIS POLICY</h2>
                <p>6.1 Failure to follow the terms of this policy will be considered a material breach of our Website Terms of Use. Where we consider a breach has occurred, we may (but are not limited to):</p>
                <ul>
                  <li>6.1.1 issue a warning to you;</li>
                  <li>6.1.2 suspend your use of all or part of the Website;</li>
                  <li>6.1.3 remove any User Generated Content you have made on the Website;</li>
                  <li>6.1.4 close or suspend any user account details you have with us;</li>
                  <li>6.1.5 disclose any User Generated Content to law enforcement authorities as appropriate or as required by law; and/or</li>
                  <li>6.1.6 take legal action against you.</li>
                  <li>6.1.7 notify your relevant Registered Body</li>
                </ul>

                <h2>7 COMPLAINTS</h2>
                <p>7.1 If you wish to complain about our Website, or query any content uploaded by other users, including any User Generated Content or third party content, please contact us at <a href="mailto:contact@therapyin.london">contact@therapyin.london</a>.</p>

                <h2>8 LAW AND JURISDICTION</h2>
                <p>8.1 Please note that the laws of England and Wales apply to the terms of this policy. Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales, unless you are a consumer and live in either Northern Ireland or Scotland. In which case, you can choose to bring a claim in England and Wales or in the courts of another part of the United Kingdom.</p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Seperation() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Separation</a>
            <img src="/images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Separation</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/separation.jpg" />
                </div>
              </div>
              <div className="content">
                <p>Separation from your partner, whether it be a long-term or short-term relationship, can feel emotionally exhausting.</p>
                <p>Wanting to find a partner is commonplace, and we do our best to take care of our emotions and well-being within that relationship. What is easy to forget is that separation takes time to heal. Emotions shouldn’t be hidden, and absorbing what is happening does not mean you are weak or co-dependent.</p>
                <p>A separation means a new understanding of who you are and your life, which is why therapy can bring to light what you understood your relationship to be and where to focus your emotions from now on.</p>
                <h2>What causes separation?</h2>
                <p>Separation in a relationship can come up for a variety of reasons. Whatever the reason, it will always be for the well-being of one or both of you, which is important to remember.</p>
                <p>Whether this is from:</p>
                <ul>
                  <li>A mutual agreement</li>
                  <li>A change in interests/clashing</li>
                  <li><a href="#">Adultery</a></li>
                  <li><a href="#">Abuse</a></li>
                  <li>Loss of romance and love</li>
                  <li>Manipulation and gaslighting</li>
                </ul>
                <p>Reasoning aside, your separation is something that should be processed rather than brushed aside.</p>
                <p>As phrases such as <em>‘Move on’</em> or <em>‘There are plenty more fish in the sea’</em> are thrown around, you can find yourself believing that you shouldn’t have to take the time to process your emotions and what the relationship meant to you.</p>
                <h2>How therapy can help.</h2>
                <p>Separation can mean a large change in your lifestyle, even in relationships where you spent little time together towards the end. The shift in who you talk to, where you live, or even your own identity, changes significantly.</p>
                <p>When you start therapy, this can be either during your consideration to separate or right after. Either is very beneficial in preparation for your future.</p>
                <p>During therapy, you will not only go through your plans for your future but also your place in that relationship.</p>
                <p>Harbouring your memories of your ex can create a stigma around the relationship. This is why talking through your experiences (good and bad) is so important to you being prepared to move forward.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>We understand that separation is a delicate subject for anyone. Your experiences are unique to you, and we want to gather a full understanding of your path to provide the support system you need.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Seperation;

import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useProfileStore } from "../../../app/stores";
import("../../../styles/global.scss");
import("../../../styles/sections.scss");

function TypesSearch({ title, labels, arrayKey, className, dropdownStyle }) {
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const { updateProfile, profile } = useProfileStore();
  const selectedLabels = profile[arrayKey] || [];

  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
    setShowDropdown(true);
  }, []);

  const handleLabelSelect = useCallback(
    (label) => {
      const newLabel =
        typeof label === "string" ? label : { id: label.id, name: label.name };
      updateProfile({
        [arrayKey]: [...selectedLabels, newLabel],
      });
      setInputValue("");
      setShowDropdown(false);
    },
    [arrayKey, selectedLabels, updateProfile]
  );

  const handleLabelRemove = useCallback(
    (labelToRemove) => {
      updateProfile({
        [arrayKey]: selectedLabels.filter((label) =>
          typeof label === "string"
            ? label !== labelToRemove
            : label.id !== labelToRemove
        ),
      });
    },
    [arrayKey, selectedLabels, updateProfile]
  );

  const filteredLabels = useMemo(() => {
    return labels.filter((label) => {
      const labelName = typeof label === "string" ? label : label.name;
      const labelId = typeof label === "string" ? label : label.id;
      return (
        labelName.toLowerCase().includes(inputValue.toLowerCase()) &&
        !selectedLabels.some((selectedLabel) =>
          typeof selectedLabel === "string"
            ? selectedLabel === labelName
            : selectedLabel.id === labelId
        )
      );
    });
  }, [labels, inputValue, selectedLabels]);

  return (
    <div className={`types_wrap ${className || ""}`}>
      <div className="title">
        <div className="label_angle" />
        <label>{title}</label>
      </div>
      <div className="types_content types_content_edit">
        <div className="types_tags">
          {selectedLabels.map((label, index) => (
            <div
              key={typeof label === "string" ? `${label}-${index}` : label.id}
              className="types_tag_custom"
            >
              {typeof label === "string" ? label : label.name}
              <button
                type="button"
                onClick={() =>
                  handleLabelRemove(
                    typeof label === "string" ? label : label.id
                  )
                }
              >
                <img src="../../../images/icons/x.svg" />
              </button>
            </div>
          ))}
        </div>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
          placeholder="Search..."
        />
        {showDropdown && inputValue.length > 0 && (
          <ul className="dropdown" style={dropdownStyle}>
            {filteredLabels.length > 0 ? (
              filteredLabels.map((label) => (
                <li
                  style={{ cursor: "pointer" }}
                  key={typeof label === "string" ? label : label.id}
                  onMouseDown={() => handleLabelSelect(label)}
                >
                  {typeof label === "string" ? label : label.name}
                </li>
              ))
            ) : (
              <li className="empty-state">No matches found</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
TypesSearch.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  arrayKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  dropdownStyle: PropTypes.object,
};

TypesSearch.defaultProps = {
  dropdownStyle: {
    position: "absolute",
    zIndex: 9999,
  },
};

export default TypesSearch;

import { Route, Routes } from "react-router-dom";
import ArticleView from "../articleView";
import Articles from "../articles";
import ProfileArticles from "../profile-articles";

export const ArticlesRoutes = () => {
  return (
    <Routes>
      <Route index element={<Articles />} />
      <Route path="/:userId/:articleId" element={<ArticleView />} />
      <Route path="/:userId" element={<ProfileArticles />} />
    </Routes>
  );
};

import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const rejectProfile = async ({ profileId, statusMessage }) => {
  try {
    const response = await api.post(
      `/admin/profile/${profileId}/reject`,
      { statusMessage },
      await auth.currentUser.getIdToken()
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error rejecting profile:", error);
    throw error;
  }
};

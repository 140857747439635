import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function Couple() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../counselling-services/">Types of Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Couple Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Couple Therapy</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/typesOfTherapy/article/couple.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  All relationships go through fluctuations of highs and lows. These ebbs
                  and flows don’t have to mean regular arguments or feeling you are
                  drifting apart. Couple counselling can give your relationship a fresh
                  perspective of each other.
                </p>
                <p>
                  Each of you within your relationship will go through changes, and it can
                  feel difficult to not let those changes impact on how you see each
                  other.
                </p>
                <p>
                  What this does mean is ensuring that you are both growing in your own
                  way, but are still due to meet again within this growth. You do this by
                  seeing the other as their own person, yet as someone who also
                  compliments who you want to be too.
                </p>
                <p>
                  If you are interested in couple counselling, this can mean that you are
                  at a point where you need help in understanding each other, or you want
                  to maintain the connection you have. Either way, couple counselling is
                  the most beneficial way to continue communication with one another.
                </p>
                <h2>Does couple counselling work?</h2>
                <p>
                  As with all counselling, you are opening up within the therapeutic
                  space. What can seem more daunting is the idea that your partner will be
                  in that room too.
                </p>
                <p>
                  This is why your professional counsellor is there to direct the
                  sessions. They will ensure that each has expressed their own thoughts,
                  and that you both feel safe to express how you feel. Doing this outside
                  of your home gives you a space away from the stresses and distractions
                  from your life that might interfere with conversations you’ve been
                  meaning to have.
                </p>
                <p>
                  There doesn’t have to be a specific issue centred around yourselves as a
                  couple, but this experience even strengthens who you are as an
                  individual. Once you learn about what your partner expects, what you
                  expect, and what you want of yourselves, you can build a new foundation
                  of trust and motivation for the future.
                </p>
                <h2>Do we need therapy?</h2>
                <p>
                  With all that being said, counselling usually begins with a catalyst
                  and this can a range of issues including:
                </p>
                <ul>
                  <li>Adultery</li>
                  <li>
                    <a
                      href="https://www.therapyin.london/find-help-for/sex-issues/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Sex
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.therapyin.london/find-help-for/relationship-issues/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Communication
                    </a>{' '}
                    issues
                  </li>
                  <li>Trust issues</li>
                  <li>
                    <a
                      href="https://www.therapyin.london/find-help-for/lying/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Lying
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.therapyin.london/find-help-for/work-and-career-issues/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Work-life balance
                    </a>
                  </li>
                  <li>Money</li>
                </ul>
                <p>
                  There is no issue too small to begin counselling and there doesn’t have
                  to be a specific reason to feel like you need counselling. Sometimes –
                  especially in long-term relationships – a couple can feel as if their
                  interests do not align anymore. You may also feel as if you cannot open
                  up to your partner the way you used it. There isn’t always a concrete
                  cause to start therapy, you can just have a desire to strengthen what
                  you have.
                </p>
                <h2>How Therapy in London can help us?</h2>
                <p>
                  When beginning counselling with your partner, the first step is to
                  understand what each other wants.
                </p>
                <p>
                  Feeling off-balance in a relationship is a difficult thing to pin point
                  and what our specialist counsellors understand, is you both needing the
                  time to really understand what brought you to therapy.
                </p>
                <p>
                  Within your sessions you are able to really listen to each other and
                  open up in ways you may never have done before. Finding a new balance
                  and a fresh perspective is possible once you are both ready to hear from
                  one another.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Couple;

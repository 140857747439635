import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";
/**
 * Creates a new article
 * @param {Object} params - The parameters for creating an article
 * @param {string} params.userId - The ID of the user creating the article
 * @param {string} params.title - The title of the article
 * @param {string} params.content - The content of the article
 * @param {Array<{name: string, id: string}>} params.specialisms - An array of specialism objects, each with a name and id
 * @returns {Promise<Object>} The created article data
 * @throws {Error} If there's an error creating the article
 */
export const createArticle = async ({
  userId,
  title,
  content,
  specialisms,
}) => {
  try {
    const data = {
      userID: userId,
      title,
      content,
      specialisms,
    };

    const response = await api.post(
      "/article",
      data,
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error creating profile:", error);
    throw error;
  }
};

import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import Spinner from "../fragments/spinner";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const InputGoogle = forwardRef(function InputStandardGoogle(props, ref) {
  const {
    placeholder,
    onPlaceSelected,
    autoComplete,
    value,
    onChange,
    onClear,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstInput, setIsFirstInput] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({
    apiKey: import.meta.env.VITE_GOOGLE_KEY,
    options: {
      componentRestrictions: { country: "gb" },
    },
    language: "EN",
    debounce: 500,
  });

  useEffect(() => {
    if (ref.current) {
      if (ref.current.value.length > 150) {
        ref.current.value = ref.current.value.slice(0, 150);
        ref.current.error = "Input cannot exceed 150 characters";
        props.onError(true);
      } else {
        ref.current.error = "";
        props.onError(false);
      }
    }
  }, [ref, props.onError]);

  const handlePlaceSelected = (placeId) => {
    setIsLoading(true);
    placesService?.getDetails({ placeId }, (placeDetails) => {
      setIsLoading(false);
      if (placeDetails && onPlaceSelected) {
        onPlaceSelected(placeDetails);
      }
      // Clear the place predictions to close the list
      getPlacePredictions({ input: "" });
      setIsDropdownOpen(false);
    });
  };

  const debouncedGetPlacePredictions = useCallback(
    debounce((input) => {
      setIsLoading(false);
      getPlacePredictions({ input });
      setIsDropdownOpen(true);
    }, 500),
    [],
  );

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setIsLoading(true);
    if (ref.current) {
      ref.current.value = inputValue;
    }
    if (ref.current) {
      if (inputValue.length > 150) {
        ref.current.error = "Input cannot exceed 150 characters";
        props.onError(true);
      } else {
        ref.current.error = "";
        props.onError(false);
      }
    }
    onChange({ target: { value: inputValue } });

    if (isFirstInput) {
      getPlacePredictions({ input: inputValue });
      setIsFirstInput(false);
      setIsDropdownOpen(true);
    } else {
      debouncedGetPlacePredictions(inputValue);
    }
  };

  const handleClear = () => {
    if (ref.current) {
      ref.current.value = "";
    }
    onClear();
    getPlacePredictions({ input: "" });
    setIsFirstInput(true);
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (e, placeId) => {
    if (e.key === "Enter" || e.key === " ") {
      handlePlaceSelected(placeId);
    }
  };

  const shouldShowClearButton = !(
    window.location.pathname === "/therapist/profile/edit" ||
    window.location.pathname.includes("/admin/users/profile/")
  );

  return (
    <div
      className="input-google-wrapper"
      style={{ width: "100%", position: "relative" }}
    >
      <div className="inputs_block">
        <input
          autoComplete={autoComplete}
          ref={ref}
          className="input_default"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          {...rest}
        />
        {/* CLEAR */}
        {shouldShowClearButton && (
          <button type="button" className="searchClear" onClick={handleClear}>
            <img src="../../images/icons/x.svg" alt="Clear" />
          </button>
        )}
        {ref.current?.error && (
          <span className="error-message">{ref.current.error}</span>
        )}
      </div>
      {isDropdownOpen && placePredictions.length > 0 && (
        <div
          ref={dropdownRef}
          className="pac-container"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
          }}
        >
          {isLoading || isPlacePredictionsLoading ? (
            <div
              className="pac-item pac-item-loading"
              style={{
                color: "black",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Spinner size={20} color="#007bff" borderWidth={2} />
              Loading...
            </div>
          ) : (
            placePredictions.map((prediction) => (
              <div
                key={prediction.place_id}
                className="pac-item"
                onClick={() => handlePlaceSelected(prediction.place_id)}
                onKeyDown={(e) => handleKeyDown(e, prediction.place_id)}
                role="button"
                tabIndex={0}
                style={{
                  color: "black",
                }}
              >
                {prediction.description}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
});

InputGoogle.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  onPlaceSelected: PropTypes.func,
  country: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  onClear: PropTypes.func,
  onError: PropTypes.func,
};

export default InputGoogle;

/* eslint-disable react/prop-types */
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion"; // Import Framer Motion
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { getAllArticles } from "../app/api/articles";
import { searchTherapists } from "../app/api/search/searchTherapists";
import useIsMobile from "../app/hooks/useIsMobile";
import { getFileUrl } from "../app/lib/getFileUrl";
import Filters from "../components/elements/filters";
import LoaderSearch from "../components/elements/loaderSearch";
import SearchResultsArticles from "../components/elements/searchResultsArticles";
import Thumbnail from "../components/elements/thumbnail";
import CTA from "../components/fragments/CTA";
import("../styles/buttons.scss");
import("../styles/global.scss");
import("../styles/search_results.scss");
import("../styles/sections.scss");
import {
  getIsNewSearch,
  getSavedSeed,
  randomSeed,
  setIsNewSearch,
  setSeed,
} from "../utils";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const THERAPISTS_PER_PAGE = 10; // Adjust this based on your API's page size
function Results() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const observer = useRef();
  const previousLocation = useRef(location);
  const isMobile = useIsMobile();
  const [filtersVisible, setFiltersVisible] = useState(isMobile);

  const handleShowFiltersClick = () => {
    setFiltersVisible(true);
  };

  const handleHideFiltersClick = () => {
    setFiltersVisible(false);
  };


  const seed = useMemo(() => {
    const savedSeed = getSavedSeed();
    if (savedSeed !== null && !getIsNewSearch()) {
      return savedSeed;
    }
    const newSeed = randomSeed();
    setSeed(newSeed);
    setIsNewSearch(false);
    return newSeed;
  }, []);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["searchTherapists", searchParams.toString(), seed],
    queryFn: async ({ pageParam = 0 }) => {
      const results = await searchTherapists({
        params: searchParams.toString(),
        page: pageParam,
        limit: THERAPISTS_PER_PAGE,
        seed,
      });
      return results;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.result?.length === THERAPISTS_PER_PAGE
        ? allPages?.length
        : undefined;
    },
  });

  const hasResults = useMemo(() => {
    return data?.pages?.some(
      (page) => page?.result && page?.result?.length > 0
    );
  }, [data]);

  const {
    data: allTherapistsData,
    fetchNextPage: fetchNextAllTherapistsPage,
    hasNextPage: hasNextAllTherapistsPage,
    isFetchingNextPage: isFetchingNextAllTherapistsPage,
  } = useInfiniteQuery({
    queryKey: ["allTherapists", seed],
    queryFn: async ({ pageParam = 0 }) => {
      const results = await searchTherapists({
        params: "",
        page: pageParam,
        limit: THERAPISTS_PER_PAGE,
        seed,
      });
      return results;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.result?.length === THERAPISTS_PER_PAGE
        ? allPages?.length
        : undefined;
    },
    enabled: !hasResults,
  });

  const {
    data: articleData,
    isLoading: isLoadingArticles,
    refetch: refetchArticles,
  } = useQuery({
    queryKey: ["relevantArticles", searchParams],
    queryFn: async () => {
      const articles = await getAllArticles({
        limit: 3,
        specialisms:
          searchParams.get("specialisms") !== null
            ? searchParams.get("specialisms")
            : "",
      });

      if (!articles || articles?.articles?.length === 0) {
        return getAllArticles({ limit: 3 });
      }

      return articles;
    },
  });

  const handleSearch = useCallback(() => {
    if (location.search === previousLocation.current.search) {
      return;
    }
    const newSeed = randomSeed();
    setSeed(newSeed);
    setIsNewSearch(true);
    refetch();
    refetchArticles();
  }, [refetch, refetchArticles]);


  const lastTherapistRef = useCallback(
    (node) => {
      if (isFetchingNextPage || isFetchingNextAllTherapistsPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (hasResults && hasNextPage) {
            fetchNextPage();
          } else if (!hasResults && hasNextAllTherapistsPage) {
            fetchNextAllTherapistsPage();
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    [
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      fetchNextAllTherapistsPage,
      hasNextAllTherapistsPage,
      isFetchingNextAllTherapistsPage,
      hasResults,
    ]
  );

  useEffect(() => {
    if (location.search !== previousLocation.current.search) {
      refetch();
      refetchArticles();
      previousLocation.current = location;
    }
  }, [location, refetch, refetchArticles]);

  if (status === "loading" || isLoadingArticles) {
    return <LoaderSearch subtitle="Searching..." />;
  }
  if (status === "error") {
    return <div>Error: Unable to fetch data</div>;
  }

  return (
    <div className="search_results_wrap">
      <div className={`search_results_filters ${filtersVisible ? 'show' : ''}`}>

        <div className="postit_dots">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <button className="close-filters-mobile" onClick={handleHideFiltersClick}>
          <img src="../images/icons/x.svg" />
        </button>

        <Filters
          onHideFiltersClick={handleHideFiltersClick}
          onSearch={handleSearch}
        />
      </div>

      <motion.div
        className="search_results_therapists"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <Link to="/">Find a Therapist</Link>
            <img src="../images/illustrations/underline.svg" alt="underline" />
          </div>
          <img src="../images/icons/chevron_right.svg" alt="chevron right" />
          <div className="breadcrumb current_page">
            <Link to="#">Search Results</Link>
            <img src="../images/illustrations/underline.svg" alt="underline" />
          </div>
        </div>

        <div className="filtersToggle">
          <CTA
            className="showFiltersMobile"
            type="ghost"
            text="Filters"
            onClick={handleShowFiltersClick}
          />
        </div>

        <motion.div
          className="search_results_articles"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
        >
          <h2>Relevant Discussions</h2>
          <div className="wrap">
            {articleData?.articles.slice(0, 3).map((article) => {
              return (
                <SearchResultsArticles
                  key={article.id}
                  link={`/articles/${article.user.id}/${article.id}`}
                  imgSrc={
                    article.user?.profile?.profilePicture
                      ? getFileUrl(
                        "profilePicture",
                        article.user?.id,
                        article.user?.profile?.profilePicture
                      )
                      : "../images/placeholders/noImage.png"
                  }
                  title={article.title}
                  author={article?.user?.name}
                  authorId={article?.user?.id}
                />
              );
            })}
          </div>
        </motion.div>

        <motion.div
          className="search_results_profiles"
          variants={fadeInVariants}
        >
          <div className="titleWrap">
            <h1>Therapists</h1>
            <img
              src="../images/illustrations/titleWrapCircle.svg"
              alt="wrapCircle"
            />
          </div>

          {!hasResults && (
            <div className="no-results-message">
              <p>
                No therapists match your search criteria. Showing all therapists
                instead.
              </p>
            </div>
          )}

          <motion.div className="profiles" variants={fadeInVariants}>
            {(hasResults ? data : allTherapistsData)?.pages?.map((page) =>
              page.result?.map((item, index, array) => (
                <Thumbnail
                  key={item.id}
                  user={item}
                  ref={
                    index === array.length - 1 &&
                      page ===
                      (hasResults ? data : allTherapistsData).pages?.[
                      data?.pages?.length - 1
                      ]
                      ? lastTherapistRef
                      : null
                  }
                />
              ))
            )}
          </motion.div>

          {(isFetchingNextPage || isFetchingNextAllTherapistsPage) && (
            <LoaderSearch subtitle="Loading more..." />
          )}

          {(status === "pending" ||
            isFetchingNextPage ||
            isFetchingNextAllTherapistsPage) && (
              <LoaderSearch subtitle="Searching" />
            )}
        </motion.div>

      </motion.div>
    </div>
  );
}

export default Results;

import {
  getIsNewSearch,
  getSavedSeed,
  randomSeed,
  setIsNewSearch,
  setSeed,
} from "../../../utils";
import { api } from "../../lib/createClient";

export const searchTherapists = async ({ page = 0, limit = 10, params }) => {
  const safetyPage = Math.max(1, Math.floor(page) + 1); // Ensure page is at least 1
  let seed = getSavedSeed();
  const isNewSearch = getIsNewSearch();

  if (isNewSearch || seed === null) {
    seed = randomSeed();
    setSeed(seed);
    setIsNewSearch(false);
  }

  const url = `/search?page=${safetyPage}&limit=${limit}${
    params ? `&${params}` : ""
  }&seed=${seed}`;

  try {
    const response = await api.get(url);

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error searching therapists:", error);
    throw error;
  }
};

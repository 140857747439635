import { DashboardRoutes } from "../../pages/routes/DashboardRoutes";
import PaymentFailed from "../../pages/therapists/PaymentFailed";
import PaymentSuccess from "../../pages/therapists/PaymentSuccess";

export const protectedRoutes = [
  {
    path: "/therapist/*",
    element: <DashboardRoutes />,
  },
  {
    path: "/payment/success",
    element: <PaymentSuccess />,
  },
  {
    path: "/payment/failed",
    element: <PaymentFailed />,
  },
];

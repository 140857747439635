import { z } from "zod";

const signInSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(6, { message: "Password must be at least 6 characters" }),
});

const signUpSchema = z
  .object({
    email: z.string().email(),
    name: z.string(1, { message: "Name is required" }),
    password: z
      .string()
      .min(6, { message: "Password must be at least 6 characters" }),
    confirmPassword: z
      .string()
      .min(6, { message: "Password must be at least 6 characters" }),
    gender: z.enum(
      [
        "cis_male",
        "cis_female",
        "non_binary",
        "trans_male",
        "trans_female",
        "prefer_not_to_say",
      ],
      { message: "Please select a gender" }
    ),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        path: ["confirmPassword"],
        message: "Passwords do not match",
      });
    }
  });

const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

const updateProfileSchemaMainEdit = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z.string().email({ message: "Please enter a valid email address" }),
  location: z.string().min(1, { message: "Location is required" }),
  phoneNumber: z.string().min(1, { message: "Phone number is required" }),
  qualifications: z.string().min(1, { message: "Qualifications is required" }),
  photo: z
    .instanceof(File)
    .refine(
      (file) => {
        if (file.size > 1000000) {
          return false;
        }
        return true;
      },
      { message: "File size must be less than 1MB" }
    )
    .refine(
      (file) => {
        if (!file.type.includes("image")) {
          return false;
        }
        return true;
      },
      { message: "File must be an image" }
    )
    .refine(
      (file) => {
        if (file === null) {
          return false;
        }
        return true;
      },
      { message: "Please select a file" }
    )
    .refine(
      (file) => {
        if (file === undefined) {
          return false;
        }
        return true;
      },
      { message: "Please select a file" }
    ),
});

export { forgotPasswordSchema, signInSchema, signUpSchema, updateProfileSchemaMainEdit };


import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

/**
 * Rejects an article with a given status message.
 *
 * @param {Object} params - The parameters for rejecting the article.
 * @param {string} params.articleId - The ID of the article to reject.
 * @param {string} params.statusMessage - The reason for rejecting the article.
 * @returns {Promise<Object>} The response data from the server.
 * @throws {Error} If there's an error rejecting the article.
 */
export const rejectArticle = async ({ articleId, statusMessage }) => {
  try {
    const response = await api.post(
      `/admin/article/${articleId}/reject`,
      { statusMessage },
      await auth.currentUser.getIdToken()
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error rejecting article:", error);
    throw error;
  }
};

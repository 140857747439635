import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getUser } from "../../app/api/profile/getUser";
import { queryClient } from "../../app/lib/createQueryClient";
import { auth } from "../../app/lib/firebaseClient";
import { useProfileStore } from "../../app/stores";
import("../../styles/nav.scss");
import Logo from "../animations/Logo";

NavBar.propTypes = {
  isSignUp: propTypes.bool,
};

const headerVariants = {
  hidden: { y: -100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
};

function NavBar({ isSignUp }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const [navVisible, setNavVisible] = useState(true);
  const [user, loading] = useAuthState(auth);
  const { clearStore } = useProfileStore((state) => {
    return {
      clearStore: state.clearStore,
    };
  });
  const { data: userData, isLoading: isUserDataLoading } = useQuery({
    queryKey: ["user"],
    queryFn: async () => await getUser(auth.currentUser?.uid),
    enabled: !!user,
  });

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setNavVisible(prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      if (typeof handleScroll != 'undefined' && handleScroll != null) window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLinkClick = (path) => {
    if (path === location.pathname) {
      window.scrollTo(0, 0); // Scroll to the top when the current page is clicked
    } else {
      if (window.innerWidth < 1160) {
        setMenuVisible(false);
      }
    }
  };

  return (
    <motion.header
      className={`navBar_wrap ${isSignUp ? "navBar_signUp" : navVisible ? "show" : ""
        }`}
      variants={headerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="navBar_center">
        <div className="navBar_logo">
          <Link to="/" onClick={() => handleLinkClick("/")}>
            {isSignUp ? (
              <>
                <img
                  src="../../../images/logos/logo_white.svg"
                  alt="logo"
                  width="120px"
                  height="52px"
                />
              </>
            ) : (
              <>
                <Logo />  {/* Display Lottie animation */}
              </>
            )}
          </Link>
        </div>

        {!isSignUp && (
          <>
            <div className="navBar_mobile">
              <button type="button" onClick={toggleMenu}>
                <img src="../../images/icons/burger.svg" alt="burger" />
              </button>
            </div>

            <div className={`navBar_links ${menuVisible ? "visible" : ""}`}>
              <ul>
                <li>
                  <div className="link_wrap">
                    <NavLink
                      to="/find-help-for"
                      onClick={() => handleLinkClick("/find-help-for")}
                    >
                      Find Help For..
                      <span className="underline-container">
                        <img
                          src="../../../images/illustrations/underline.svg"
                          alt="underline"
                          width="150px"
                          height="20px"
                        />
                      </span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className="link_wrap">
                    <NavLink
                      to="/counselling-services"
                      onClick={() => handleLinkClick("/counselling-services")}
                    >
                      Types of Therapy
                      <span className="underline-container">
                        <img
                          src="../../../images/illustrations/underline.svg"
                          alt="underline"
                          width="150px"
                          height="20px"
                        />
                      </span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className="link_wrap">
                    <NavLink
                      to="/articles"
                      onClick={() => handleLinkClick("/articles")}
                    >
                      Articles by Therapists
                      <span className="underline-container">
                        <img
                          src="../../../images/illustrations/underline.svg"
                          alt="underline"
                          width="150px"
                          height="20px"
                        />
                      </span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className="link_wrap">
                    <NavLink to="/" onClick={() => handleLinkClick("/")}>
                      Find a Therapist
                      <span className="underline-container">
                        <img
                          src="../../../images/illustrations/underline.svg"
                          alt="underline"
                          width="150px"
                          height="20px"
                        />
                      </span>
                    </NavLink>
                  </div>
                </li>
              </ul>
              {(loading || isUserDataLoading) ? (
                <div className="navBar_main">
                  <p>Loading...</p>
                </div>
              ) : (
                <>
                  <div className="navBar_main">
                    <div className="cta_nav">
                      <Link
                        to={
                          user
                            ? userData?.user?.role === "admin"
                              ? "/admin/users"
                              : "/therapist/profile"
                            : "/why-join-therapy-in-london"
                        }
                        onClick={handleLinkClick}
                      >
                        {user
                          ? userData?.user?.role === "admin"
                            ? "Dashboard"
                            : "View Profile"
                          : "I'm a Therapist"}
                      </Link>
                    </div>
                  </div>
                  {user && (
                    <button
                      className="logOut"
                      type="button"
                      onClick={async () => {
                        await localStorage.clear();
                        await clearStore();
                        await queryClient.clear();
                        await auth.signOut();
                      }}
                    >
                      Logout
                    </button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>

    </motion.header>
  );
}

export default NavBar;

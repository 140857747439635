import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Hoarding() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Hoarding</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Hoarding</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/hoarding.jpg" alt="Hoarding" />
                </div>
              </div>
              <div className="content">
                <p>Hoarding is when you feel the need to keep things no matter their value, regardless of their use or if you have the space for them. We all have possessions that are special to us, have a specific use or that bring us joy. Nowadays, though, we have a lot more access to things than in decades before.</p>

                <p>This leads us to need to clear out things and throw them away in order to keep our houses tidy and manageable.</p>

                <p>For some people, though, the thought of getting rid of their things can lead to feelings of sadness and anxiety — these feelings are often related to hoarding disorder.</p>

                <h2>What is hoarding?</h2>

                <p>Hoarding is a disorder where you acquire an excessive number of items. These items can be of very little value.</p>

                <p>If you hoard, you might have felt that:</p>

                <ul>
                  <li>You won’t cope with how you feel if you throw your things away</li>
                  <li>You need to keep things for the future</li>
                  <li>Harm could come to the environment or other people if you throw things away</li>
                  <li>Your belongings are keeping you safe or making you happy</li>
                  <li>All your items are unique and special despite them being commonplace and very similar</li>
                </ul>

                <h2>What causes hoarding?</h2>

                <p>Hoarding can be a symptom of other mental health disorders. But it is also recognised as a disorder in its own right.</p>

                <p>People with a hoarding disorder are more likely to</p>

                <ul>
                  <li>live alone</li>
                  <li>have had a deprived childhood</li>
                  <li>have a family history of hoarding</li>
                </ul>

                <p>The mental health issues that are usually associated with hoarding include:</p>

                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/">Depression</a></li>
                  <li>Schizophrenia and other related psychotic disorders</li>
                  <li><a href="https://www.therapyin.london/find-help-for/ocd/">Obsessive compulsive disorder (OCD)</a></li>
                  <li>Trauma or abuse</li>
                </ul>

                <p>For many, the basis of hoarding comes down to a want for control — this can be if something has felt out of your control in your past. Or your hoarding comes down to a want for control over other aspects of your life.</p>

                <p>If you feel as if your existing mental issues are taking control over your life, then keeping and hoarding possessions can seem like an easy way to take back some kind of control over the decisions you make.</p>

                <p>In other instances, if control was something that you lacked in your own life, then many can develop hoarding tendencies as a way of seeking sense and order — and in this case keeping and storing various objects.</p>

                <h2>How therapy can help?</h2>

                <p>The main treatment used for breaking a hoarding disorder is Cognitive Behavioural Therapy (CBT). This is combined with talking therapy and practical tools that you can use to break your hoarding tendencies.</p>

                <p>A therapist will help you understand why it is that you have difficulty throwing things away.</p>

                <p>CBT aims to change how you think and how you act — you will be encouraged to understand your thoughts and feelings and how they impact your actions.</p>

                <h2>How <em>Therapy in London</em> can help.</h2>

                <p>When understanding your hoarding tendencies, long-term psychotherapy will help you to look back at how these habits began and what these patterns are rooted in. From this, you can find a new, positive way to relate to your past rather than returning to hoarding.</p>

                <p>We want to be there as your support and really show you we understand how difficult it can be to work towards change. Therapy in London will guarantee that you are matched with a therapist that will be patient and non-judgemental. They will take a holistic approach that will address the underlying causes of your condition. With the goal of creating lasting behavioural changes.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Hoarding;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function BPD() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">BPD</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for BPD (Borderline Personality Disorder)</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/BPD.jpg" alt="BPD image" />
                </div>
              </div>
              <div className="content">
                <h2>Borderline Personality Disorder (BPD) is a mental health condition that can result in a variety of symptoms.&nbsp;</h2>
                <p>These symptoms circle back to a similar outcome: Extreme emotions and actions towards things in your life.</p>
                <p>It is something that can develop over years depending on your past, Borderline Personality Disorder appears in several ways:</p>
                <ul>
                  <li>Paranoia</li>
                  <li>Obsession with someone or something</li>
                  <li>Pushing away someone or something for fear for your own safety</li>
                  <li>Rage</li>
                </ul>
                <p>In extreme cases it can also cause:&nbsp;</p>
                <ul>
                  <li>Hallucinations</li>
                  <li>Hearing voices</li>
                  <li>Suicidal thoughts or even attempted suicide</li>
                  <li>Thoughts, threats, or even trying to harm others</li>
                </ul>
                <h3>If you believe yourself or another is unsafe, call 999 or The Samaritans right now.</h3>
                <p>When suffering from Borderline Personality Disorder, the first step is understanding that your mind processes everything around you in a very unique way.</p>
                <p>This is why talking therapy becomes a crucial part of understanding your BPD. With such thoughts, it is always best to explore and relive these in a professional and safe space.</p>
                <h2>What causes BPD?</h2>
                <p>It can usually be traced back to either a traumatic childhood, a significant event, or a history of mental illness in your family. </p>
                <p>BPD can develop from your traumatic past like:</p>
                <ul>
                  <li>Not feeling trusted</li>
                  <li>Cannot trust others</li>
                  <li>You have felt neglected or unloved</li>
                  <li>A traumatic event out of your control</li>
                </ul>
                <p>These unfortunate experiences lead to feeling as if you have to commit your thoughts and actions to take back control of your life. Whenever something feels as if it is not going the way you expected, you will do anything to change things in your favour.</p>
                <p>You’ll usually act with little to no thought or foresight, as you have prioritized your expectations above all else.</p>
                <p>This leads to extreme behavior and often impulsive actions.&nbsp;</p>
                <h2>How therapy can help.</h2>
                <p>Counselling is a long term way of managing your BPD. Through counselling, you are able to not only trace back your past experiences and actions to understand them but also have time for yourself to reflect and process everything you’re thinking and feeling.</p>
                <p>This is an important space if you have been diagnosed with BPD. Your impulsive, unconscious thoughts are then given the freedom to evolve and for you to digest them.</p>
                <p>With BPD, you can find it difficult to understand consequences or others’ feelings following your actions. Therapy is an important part of acknowledging your mindset and others’ around you.&nbsp;</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>As soon as our practitioners learn of your issue, you are still seen as who you are within the world, not by what BPD defines you as.&nbsp;</p>
                <p>The way you think, feel, and act is unique, but that could be said for anyone. Our priority is understanding your thoughts and feelings so that we can both relate to them and be part of the control you seek over your life for the better.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default BPD;

// src/components/AccountStatus.js
import React from 'react';
import PropTypes from 'prop-types';

const AccountStatus = ({ status }) => {
  const statusLabel = {
    approved: 'Live',
    awaitingApproval: 'Awaiting Approval',
    default: 'Draft',
  };

  return (
    <div className="account-status">
      <p>{statusLabel[status] || statusLabel.default}</p>
    </div>
  );
};

AccountStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default AccountStatus;

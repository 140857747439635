import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

var DragDropContext, Draggable, Droppable;
import("react-beautiful-dnd").then((module) => {
  DragDropContext = module.DragDropContext;
  Draggable = module.Draggable;
  Droppable = module.Droppable;
});

import Files from "react-files";
import { getArticleByUser } from "../../app/api/articles";
import { getLanguages } from "../../app/api/profile/getLanguages";
import { getSpecialisms } from "../../app/api/profile/getSpecialisms";
import { typesOfTherapy } from "../../app/data/typesOftherapy";
import { getFileUrl } from "../../app/lib/getFileUrl";
import { useProfileStore } from "../../app/stores";
import("../../styles/buttons.scss");
import("../../styles/global.scss");
import("../../styles/inputs.scss");
import("../../styles/profiles.scss");
import Loader from "./loader";
import { BodyMembership } from "./profiles/BodyMembership";
import TypesSearch from "./profiles/TypesSearch";
import ArticlesEdit from "./profiles/articlesEdit";
import FeaturedImage from "./profiles/featuredImage";
import FeesEdit from "./profiles/feesEdit";
import MainEdit from "./profiles/mainEdit";
import QaEdit from "./profiles/qaEdit";
import Types from "./profiles/types";
import { UnderLine } from "./svgs/Underline";

function EditProfileDrag() {
  const { profile, updateProfile, setThumbnail, updateProfileInfoContent } =
    useProfileStore((state) => ({
      profile: state.profile,
      updateProfile: state.updateProfile,
      setThumbnail: state.setThumbnail,
      updateProfileInfoContent: state.updateProfileInfoContent,
    }));
  const [quoteError, setQuoteError] = useState("");
  const { profileInfos } = profile;
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [insuranceFileName, setInsuranceFileName] = useState(
    profile.insuranceFilePath
      ? profile.insuranceFilePath.split("/").pop()
      : null
  );

  const sortedProfileInfos = [...profileInfos].sort(
    (a, b) => a.order - b.order
  );

  const { isPending, data } = useQuery({
    queryKey: ["specialisms"],
    queryFn: () => getSpecialisms(),
  });

  const userArticlesQuery = useQuery({
    queryKey: ["userArticles"],
    queryFn: async () =>
      getArticleByUser({
        userId: profile?.userId,
        status: "approved",
        page: 1,
        limit: 10,
      }),
    enabled: !!profile.userId,
  });

  const { isPending: languagesIsPending, data: languagesData } = useQuery({
    queryKey: ["languages"],
    queryFn: () => getLanguages(),
  });

  const deleteMembershipBody = (index) => {
    updateProfile({
      membershipBody: profile.membershipBody.filter((_, i) => i !== index),
    });
  };

  useEffect(() => {
    if (insuranceFile) {
      updateProfile({ insuranceFile });
    }
  }, [insuranceFile, updateProfile]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedSections = Array.from(sortedProfileInfos);
    const [removed] = updatedSections.splice(result.source.index, 1);
    updatedSections.splice(result.destination.index, 0, removed);

    const updatedProfileInfo = updatedSections.map((section, index) => ({
      ...section,
      order: index + 1,
    }));

    updateProfile({ profileInfos: updatedProfileInfo });
  };

  const addMembershipBody = () => {
    updateProfile({
      membershipBody: [...profile.membershipBody, { name: "", number: "" }],
    });
  };

  useEffect(() => {
    if (insuranceFile) {
      updateProfile({ insuranceFile });
    }
  }, [insuranceFile, updateProfile]);

  const handleInsuranceFileChange = (files) => {
    const file = files[0];
    if (file && file.type === "application/pdf") {
      setInsuranceFile(file);
      setInsuranceFileName(file.name);
    } else {
      alert("Please select a PDF file.");
    }
  };

  const renderArticlesSection = () => {
    if (userArticlesQuery.isLoading) {
      return <p>Loading articles...</p>;
    }

    if (userArticlesQuery.isError) {
      return <p>Error loading articles: {userArticlesQuery.error.message}</p>;
    }

    const articles = userArticlesQuery.data?.articles || [];
    const approvedArticles = articles.filter(
      (article) => article.status === "approved"
    );

    if (approvedArticles.length === 0) {
      return (
        <div className="no-articles-message">
          <p>
            You don&apos;t have any approved articles yet. Please create and
            publish articles so you can have highlighted articles on your
            profile.
          </p>
        </div>
      );
    }

    return (
      <>
        <ArticlesEdit
          title="Article 1"
          articleNumber={1}
          articles={approvedArticles}
        />
        <ArticlesEdit
          title="Article 2"
          articleNumber={2}
          articles={approvedArticles}
        />
      </>
    );
  };

  if (isPending || languagesIsPending)
    return <Loader subtitle="Loading profile" />;

  return (
    <div className="profile_wrap">
      <div className="profilePage_split">
        <div className="profile_section">
          <MainEdit />
        </div>

        <div className="profile_section">
          <div className="profile_section_custom">
            <div className="section_drag">
              <FeaturedImage isProfileEditDrag={true} />
            </div>
          </div>
          <div className="sloganWrap">
            <label>In 1 sentence tell us about your practice</label>
            <input
              type="text"
              placeholder="Start Typing here.."
              onChange={(e) => {
                const newQuote = e.target.value;
                updateProfile({ quote: newQuote });
                setQuoteError(
                  newQuote.length > 255
                    ? "Quote must be 255 characters or less"
                    : ""
                );
              }}
              value={profile.quote}
            />
            {quoteError && <p className="error">{quoteError}</p>}
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="profile_section_custom"
                >
                  {sortedProfileInfos?.map(
                    ({ id, title, component }, index) => (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`section_drag ${
                              snapshot.isDragging ? "dragging" : ""
                            }`}
                          >
                            {component ? (
                              component
                            ) : (
                              <QaEdit
                                id={id}
                                question={
                                  profileInfos.find((info) => info.id === id)
                                    ?.title || title
                                }
                                content={
                                  profileInfos.find((info) => info.id === id)
                                    ?.content || ""
                                }
                                onContentChange={(content) =>
                                  updateProfileInfoContent(id, content)
                                }
                                onHiddenChange={(id, hidden) =>
                                  updateProfile({
                                    profileInfos: profile.profileInfos.map(
                                      (info) =>
                                        info.id === id
                                          ? { ...info, hidden }
                                          : info
                                    ),
                                  })
                                }
                                onThumbnailChange={(isThumbnail) => {
                                  if (isThumbnail) {
                                    setThumbnail(id);
                                  } else {
                                    updateProfile({
                                      profileInfos: profile.profileInfos.map(
                                        (info) =>
                                          info.id === id
                                            ? { ...info, isThumbnail: false }
                                            : info
                                      ),
                                    });
                                  }
                                }}
                                isThumbnail={
                                  profileInfos.find((info) => info.id === id)
                                    ?.isThumbnail || false
                                }
                                isHidden={
                                  profileInfos.find((info) => info.id === id)
                                    ?.hidden || false
                                }
                                dragHandleProps={provided.dragHandleProps} // Pass dragHandleProps here
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    )
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {/* Additional Droppable (droppable2) */}
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="profile_section_custom"
                >
                  <div className={`section_drag ${snapshot}`}>
                    <FeesEdit />
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className="profile_section_fullWidth" id="showContact">
        <div className="profile_section_types">
          <TypesSearch
            className="types_orange"
            title="Types of Therapy"
            arrayKey="typesOfTherapy"
            labels={typesOfTherapy}
            selectedLabels={profile.typesOfTherapy || []}
          />
          <TypesSearch
            className="types_blue"
            title="Types of Specialisms"
            labels={data.specialisms.map((specialism) => specialism) || []}
            arrayKey="specialisms"
            selectedLabels={profile.typesOfSpecialisms || []}
          />
          <TypesSearch
            className="types_green"
            title="Languages"
            labels={languagesData.languages.map((language) => language) || []}
            arrayKey="languages"
            selectedLabels={profile.languages || []}
          />
          <Types
            className="types_purple"
            title="Wheelchair Accessible"
            labels={["Yes", "No"]}
            selectedLabels={[profile.wheelchairAccess ? "Yes" : "No"]}
          />
          <Types
            className="types_black"
            title="Session Types"
            labels={["In Person", "Online", "Telephone"]}
            selectedLabels={profile.sessionTypes || []}
          />
        </div>
        <div className="profile_section_articles">
          <div className="articles_wrap">
            <div className="chooseArticle">
              <div className="title">
                <div className="wrapIllustration2">
                  <h2>Insurance</h2>
                  <UnderLine color={profile.color} />
                </div>
                <p>
                  Upload proof of insurance, we won&apos;t show this on your
                  profile, but we need it to approve your account.
                </p>
              </div>
              <div className="articleSelect">
                <Files
                  className="uploadFileWrap"
                  onChange={handleInsuranceFileChange}
                  accepts={["application/pdf"]}
                  multiple={false}
                >
                  <button type="button" className="cta_blue">
                    Upload Insurance PDF
                  </button>
                </Files>
                {(insuranceFileName || profile.insuranceFile) && (
                  <div className="filePreview">
                    {/* <img src="/path/to/pdf-icon.png" alt="PDF Icon" /> */}
                    <span>
                      {insuranceFileName ||
                        profile.insuranceFile.split("/").pop()}
                    </span>
                    {profile.insuranceFile && !insuranceFile && (
                      <a
                        href={getFileUrl(
                          "insurance",
                          profile.userId,
                          profile.insuranceFile
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta_blue"
                      >
                        View File
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="profile_section_articles">
          <div className="articles_wrap">
            <div className="chooseArticle">
              <div className="title">
                <div className="wrapIllustration">
                  <h2>Membership Body</h2>
                  <UnderLine color={profile.color} />
                </div>
                <p>
                  Please Select your Membership Body and input your Membership
                  Number
                </p>
              </div>
              <div className="articleSelect">
                {profile.membershipBody.map((_, index) => (
                  <BodyMembership
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    index={index}
                    onDelete={deleteMembershipBody}
                  />
                ))}
                <button
                  type="button"
                  className="cta_blue"
                  onClick={addMembershipBody}
                >
                  Add Another Membership Body
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="profile_section_articles">
          <div className="articles_wrap">
            <div className="chooseArticle">
              <div className="title">
                <div className="wrapIllustration2">
                  <h2>Articles</h2>
                  <UnderLine color={profile.color} />
                </div>
                <p>Choose 2 Articles to show on your profile</p>
              </div>
              <div className="articleSelect">{renderArticlesSection()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfileDrag;

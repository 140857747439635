import React, { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
var ImageResize;
import('quill-image-resize-module-react').then((module) => {
  ImageResize = module.default;
});

var Quill, ReactQuill;
import("react-quill").then((module) => {
  Quill = module.Quill;
  ReactQuill = module.default;
  Quill.register("modules/imageResize", ImageResize);
});

import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  createArticle,
  submitArticle,
  updateArticle,
} from "../../app/api/articles";
import { deleteArticle } from "../../app/api/articles/deleteArticle";
import { getArticleById } from "../../app/api/articles/getArticleById";
import { getAvailableArticleById } from "../../app/api/articles/getAvailableArticle";
import { getUser } from "../../app/api/profile/getUser";
import useToast from "../../app/hooks/useToast";
import { queryClient } from "../../app/lib/createQueryClient";
import Loader from "../../components/elements/loader";
import CTA from "../../components/fragments/CTA";
import Statuses from "../../components/fragments/statuses";
import("../../styles/buttons.scss");
import("../../styles/editor.scss");
import("../../styles/global.scss");
import("../../styles/profiles.scss");
import("../../styles/status.scss");

function WriteArticle() {
  const [content, setContent] = useState("");
  const { notifyFailure, notifySuccess } = useToast();
  const { id, aid } = useParams();
  const isEditMode = !!id;
  const location = useLocation();
  const navigate = useNavigate();
  const isAvailableArticleRoute = location.pathname.includes(
    "/therapist/articles/available/write",
  );
  const isEditArticleRoute = location.pathname.includes(
    "/therapist/articles/edit",
  );

  const { data: userData } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUser(),
  });

  const {
    data: availableArticleData,
    isLoading: isAvailableArticleLoading,
    refetch: refetchAvailableArticle,
  } = useQuery({
    queryKey: ["availableArticle", aid],
    queryFn: () => getAvailableArticleById(aid),
    enabled: isAvailableArticleRoute && !!aid,
  });

  const deleteArticleMutation = useMutation({
    mutationFn: () => {
      return deleteArticle(id);
    },
    onSuccess: () => {
      notifySuccess("Article Deleted");
      queryClient.invalidateQueries(["articles"]);
      navigate("/therapist/articles");
    },
    onError: () => {
      notifyFailure("Failed to delete article");
    },
  });

  const {
    data: articleData,
    isLoading: isArticleLoading,
    refetch: refetchArticle,
  } = useQuery({
    queryKey: ["article", id],
    queryFn: () => getArticleById(id),
    enabled: isEditArticleRoute && !!id,
    onSuccess: (data) => {
      setContent(data.content);
    },
  });

  const articleInfo = isEditArticleRoute
    ? articleData?.article
    : availableArticleData?.result;

  const mutation = useMutation({
    mutationFn: async () => {
      if (isEditArticleRoute) {
        return updateArticle({
          articleId: articleInfo.id,
          title: articleInfo.title,
          content,
          specialisms: articleInfo.specialisms,
        });
      }
      return createArticle({
        userId: userData.user.id,
        title: articleInfo?.title,
        content,
        specialisms: articleInfo?.specialisms,
      });
    },
    onSuccess: (data) => {
      notifySuccess(
        isEditMode
          ? "Article updated successfully!"
          : "Article created successfully!",
      );
      if (!isEditMode) {
        navigate(`/therapist/articles/edit/${data.article.id}`);
      }
    },
    onError: () => {
      notifyFailure(
        isEditMode ? "Error updating article." : "Error creating article.",
      );
    },
  });

  const articleReviewMutation = useMutation({
    mutationFn: async () => {
      return submitArticle(articleInfo.id);
    },
    onSuccess: () => {
      refetchArticle();
      refetchAvailableArticle();
      notifySuccess("Article submitted successfully!");
    },
    onError: () => {
      notifyFailure("Error submitting article.");
    },
  });

  useEffect(() => {
    if (isEditMode && articleData && articleData.article) {
      setContent(articleData.article.content);
    }
  }, [isEditMode, articleData]);

  const onContentChange = (e) => {
    setContent(e);
  };

  if (isEditArticleRoute && isArticleLoading) {
    return <Loader subtitle="Loading article..." />;
  }
  if (isAvailableArticleRoute && isAvailableArticleLoading) {
    return <Loader subtitle="Loading available article..." />;
  }
  return (
    <>
      <div className="profileView_wrap backgroundClear">
        <div className="profileView">
          <div className="profileView_controls_wrap">
            <div className="titleEdit">
              <h2>
                {isEditArticleRoute ? "Edit Article" : "Write New Article"}
              </h2>
            </div>
            <div className="controls">
              {isEditArticleRoute && (
                <button
                  type="button"
                  onClick={() => deleteArticleMutation.mutate()}
                  className="deleteArticle"
                >
                  Delete Article
                </button>
              )}
              <CTA type="ghost" text="Return Home" link="/therapist/articles" />
            </div>
          </div>
          <div className="narrowColumn_wrap">
            <div className="title">
              <h1>
                Specialism:{" "}
                {isEditMode
                  ? articleData?.article.specialisms
                      .map((s) => s.name)
                      .join(", ") || "N/A"
                  : articleInfo?.specialisms.map((s) => s.name).join(", ") ||
                    "N/A"}
              </h1>
              <h1>{articleInfo?.title}</h1>

              {/* {articleInfo?.specialisms.map((label) => (
                <Statuses
                  key={label.id}
                  text={label.name}
                  color={`status_${
                    label.name === "live"
                      ? "green"
                      : label.name === "awaitingReview"
                      ? "orange"
                      : "blue"
                  }`}
                />
              ))} */}
              <p>{articleInfo?.description}</p>
              {/* <p>
                {isEditMode
                  ? "Edit your article below."
                  : "Write your article below."}
              </p> */}

              {isEditMode && (
                <Statuses
                  text={
                    articleData?.article.status === "awaitingApproval"
                      ? "Awaiting Approval"
                      : articleData?.article.status.charAt(0).toUpperCase() +
                          articleData?.article.status.slice(1).toLowerCase() ||
                        "Unknown"
                  }
                  color={`status_${
                    articleData?.article.status === "live"
                      ? "green"
                      : articleData?.status === "awaitingReview"
                        ? "orange"
                        : "blue"
                  }`}
                />
              )}
            </div>
            <div className="editorWrap">
              <div className="editor articleEditor">
                <Suspense fallback={<div>Loading editor...</div>}>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={(newContent) => onContentChange(newContent)}
                    modules={{
                      toolbar: [
                        [
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          { size: [] },
                          { background: [] },
                          { color: [] },
                        ],
                        [{ align: [] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        // [{ indent: "-1" }, { indent: "+1" }],
                        ["link", "image", "video"],
                        [],
                      ],
                      imageResize: {
                        parchment: Quill.import("parchment"),
                        modules: ["Resize", "DisplaySize"],
                      },
                    }}
                  />
                </Suspense>
              </div>

              <div className="editorControls">
                <CTA
                  type="blue2"
                  text={isEditMode ? "Update" : "Create"}
                  onClick={() => mutation.mutate()}
                />
                {isEditMode && articleInfo.status === "draft" && (
                  <CTA
                    type="blue2"
                    text="Send for Review"
                    onClick={() => articleReviewMutation.mutate()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WriteArticle;

import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";
/**
 * Creates an available article.
 * @param {Object} params - The article parameters.
 * @param {string} params.title - The title of the article.
 * @param {string} params.description - The description of the article.
 * @param {Array<{id: string, name: string}>} params.specialisms - An array of specialism objects, each with an id and name.
 * @returns {Promise<Object>} The created article data.
 */
export const createAvailableArticle = async ({
  title,
  description,
  specialisms,
}) => {
  try {
    const data = {
      title,
      description,
      specialisms,
    };

    const response = await api.post(
      "/admin/available-article",
      data,
      await auth.currentUser.getIdToken()
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

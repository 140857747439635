import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function Personal() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../counselling-services/">Types of Therapy</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">Personal Counselling (1 to 1)</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Personal Counselling (One-to-One)</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/typesOfTherapy/article/personal.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  When something doesn’t feel right in your life, having the awareness to seek help through personal counselling is a huge first step in bettering yourself and developing as a person.
                </p>
                <p>
                  We all change in one way or another, and embracing those changes can be daunting. This is why private one-to-one counselling is a hugely beneficial way to grow for the better.
                </p>
                <p>
                  Personal counselling is a confidential and candid way to deal with past and present issues, fears, and bad habits.
                </p>
                <p>
                  A mental health counsellor offers a safe space to discuss all of these issues, why they have brought you to this point and what you can learn from them.
                </p>
                <h2>What personal counselling can help you with.</h2>
                <p>
                  Counselling is beneficial in taking stock of your life and building on the ability to grow from your past experiences and create healthy habits for the future. Because of this, anyone can benefit from personal counselling.
                </p>
                <h2>Do I need to have something wrong with me?</h2>
                <p>
                  Mental health counselling is there to help anyone going through any issues, whether that be:
                </p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety/" target="_blank" rel="noreferrer noopener">Anxiety</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/" target="_blank" rel="noreferrer noopener">Depression</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/addiction/" target="_blank" rel="noreferrer noopener">Addiction</a></li>
                  <li>Fears or Phobias</li>
                  <li>Stress</li>
                  <li>or lack of <a href="https://www.therapyin.london/find-help-for/motivation/" target="_blank" rel="noreferrer noopener">Motivation</a>.</li>
                </ul>
                <p>
                  Any mental health issue you may have is treatable, no matter how big or small. When dealing with these issues, your counsellor will help you to uncover how the issue began, where it has come from, and from that, you can learn how to manage it and even tackle it completely.
                </p>
                <p>
                  That being said, there is no need to wait to have a prominent and debilitating issue to begin counselling. By simply having a regular and secure space to talk about anything you wish, you can retain a healthy mind and lifestyle.
                </p>
                <h2>What will counselling be like?</h2>
                <p>
                  You may have a specific idea of what you think counselling will be like, but also what you expect from it. It is worth understanding the fundamentals of therapy and counselling, and from there you can find the right therapeutic style suited to you.
                </p>
                <p>
                  If you would like someone to be there to listen more, then you may be interested in <a href="#">psychodynamic therapy</a>, for instance.
                </p>
                <p>
                  But, if you would like a more action-focused and short-term approach you may consider <a href="#">Cognitive Behavioural Therapy (CBT)</a>.
                </p>
                <p>
                  Please also consider that many therapeutic styles are suited to more issues than others, and we are always <a href="https://www.therapyin.london/find-us" target="_blank" rel="noreferrer noopener">happy to answer any questions</a> you have to ensure you find the right match for you for the most results.
                </p>
                <h2>Meeting your counsellor.</h2>
                <p>
                  When starting counselling, the first step is deciding on who you would like to see. Knowing off the bat who you would like to open up to is a difficult choice – and whether that be gender or background, your choice is valid because being comfortable and able to be yourself should be your top priority.
                </p>
                <p>
                  Luckily, many counsellors offer a <a href="https://www.therapyin.london/find-us" target="_blank" rel="noreferrer noopener">free consultation</a>, which means that you are able to arrange a casual chat with the practitioner to help decide if they are the right person for you.
                </p>
                <p>
                  The main thing is for you to feel safe and comfortable with that practitioner, therefore we ensure that all of our counsellors are highly qualified, experienced and all you need to consider is if you would like them to be part of your journey.
                </p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>
                  Choosing to start sessions is the first step. Deciding on the practitioner is the next step – and that can be a difficult choice to make.
                </p>
                <p>
                  Therapy in London was established to ensure that there is a select group of experienced and qualified counsellors available for you. We have done so much of the research for you in what constitutes a successful and astute counsellor, that all you need to consider is if you would like to take that personal journey with them.
                </p>
                <p>
                  Each counsellor is different in their therapeutic models and specialisms, so ensuring that you find your match is Therapy in London’s number one priority.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Personal;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Lying() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Lying</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Lying</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/lying.jpg" alt="Lying" />
                </div>
              </div>
              <div className="content">
                <p>While most of us are taught from a young age not to lie, lying from time to time is quite common.</p>
                <p>Telling a lie can have different outcomes. Sometimes lies can be small deceptions that don’t seem to affect anyone. At other times, lies can become layered as you have to lie to hold up previous lies.</p>
                <p>At times you have reason to lie and white lies can be an empathetic response to a situation — we sometimes even call these ‘white lies’. However, repeatedly deceiving people around you and saying things that aren’t true out of habit can create barriers in your life and lead to common mental issues such as:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety">Anxiety</a> from attempting to keep up with constant lying</li>
                  <li><a href="https://www.therapyin.london/find-help-for/confidence">Confidence</a> issues</li>
                  <li>Feelings of <a href="https://www.therapyin.london/find-help-for/stress/">stress</a> from maintaining lies or being ‘found out’</li>
                </ul>
                <p>While you may lie to avoid difficult situations, habitually lying will, most likely, create difficulties in your relationships, career and family.</p>
                <p>If you find yourself repeatedly lying, it is natural to be worried and can start to negatively impact aspects of your life or others around you. Learning to understand why you are lying and getting to the root of it will help to start the journey to unlearning this behaviour.</p>
                <h2 id="what-causes-lying">What causes lying?</h2>
                <p>There are different reasons why people lie. In some circumstances, people may lie in order to hide other underlying mental issues including:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/confidence/">Confidence issues</a> such as social anxiety</li>
                  <li>An <a href="https://www.therapyin.london/find-help-for/addiction">addiction</a> such as drug usage or gambling.</li>
                  <li><a href="https://www.therapyin.london/find-help-for/eating-disorders/">Eating disorders</a> such as bulimia, anorexia or overeating.</li>
                  <li><a href="https://www.therapyin.london/find-help-for/depression/">Depression</a> as well as self-harm or <a href="https://www.therapyin.london/find-help-for/suicidal-thoughts/">suicidal thoughts</a>.</li>
                </ul>
                <p>In other cases, people may feel like they have very little control over their lying and lie for no reason at all. This is considered compulsive or pathological lying.</p>
                <p>In most cases, compulsive or pathological lying is rooted in personality disorders which can be officially diagnosed by your doctor.</p>
                <p>These include:</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/bpd/">BPD</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/adhd/">ADHD</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/attachment-disorder/">Attachment disorder</a></li>
                  <li><a href="https://www.ocduk.org/related-disorders/obsessive-compulsive-personality-disorder/">Obsessive Compulsive Personality Disorder</a> (linked to <a href="https://www.therapyin.london/find-help-for/ocd/">OCD</a>)</li>
                </ul>
                <p>Lying compulsively often starts during childhood. It is used as a way to cope with feelings of anxiety or shame. If you have grown up in an emotionally unsafe environment, you may have developed the habit of lying as a way to cope.</p>
                <p>It can also be a way to cope with insecurities and the deep-seated feeling that you are flawed. In this case, you may use lying as a way to win the affection or approval of others.</p>
                <h2 id="how-therapy-can-help">How therapy can help.</h2>
                <p>Treating habitual lying is not simple but one of the first steps to recovery is recognising it and seeking help from a therapist.</p>
                <p>Your therapist will be able to work with you in order to understand why you lie and how it affects you and those around you. They are able to provide you with a safe and confidential environment for you to rework any negative habits causing you to lie, and create new ones for you in your day-to-day life.</p>
                <p>At the start of therapy, you are not expected to drop your lying habits right away. Your therapist understands that this is a process and is there to understand your reality. Lying rarely benefits the therapeutic process but it is a journey for you to take until you are able to understand the difference between your real life and what you hope from your life.</p>
                <p>Once you understand the difference you will be able to step back from the false reality you have created for yourself.</p>
                <h2 id="how-therapy-in-london-can-help">How Therapy in London can help.</h2>
                <p>Therapy in London provides a safe and judgment-free environment for you to discover the root of your problem and work to make lasting behavioural changes.</p>
                <p>When understanding the reason behind your lying, it can be challenging to not slip back into these habits. Our practitioners want to understand and be a part of your journey. This is vital in being able to create a successful future for yourself. Once you establish trust with someone else, you are then able to build an honest and realistic reality for yourself.</p>
                <p>We are proud to support the LGBTQ+ community and to encourage constant change, understanding and acceptance.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Lying;

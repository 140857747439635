import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Loneliness() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="../images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Loneliness</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Loneliness</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/lonliness.jpg" alt="Loneliness" />
                </div>
              </div>
              <div className="content">
                <h2>Are you questioning if you are lonely? And what does being lonely actually mean?</h2>
                <p>Loneliness is a common mental health issue, yet not regularly discussed as such.</p>
                <p>Experiencing loneliness can occur over a long period of time, or be a sudden need for company or affection. Loneliness is an issue experienced by many, yet it can be difficult to voice your feelings or see past any stigma around your loneliness.</p>
                <p>The stigma around loneliness is, are you <em>actually</em> lonely?: <em>‘You just need a hobby, a pet, or to connect with friends and family.’</em></p>
                <p>This couldn’t be further from the truth. Loneliness can become a long-term issue and can result in other mental health issues.</p>
                <ul>
                  <li><a href="https://www.therapyin.london/find-help-for/anxiety" target="_blank" rel="noreferrer noopener">Anxiety</a></li>
                  <li>Overwhelment</li>
                  <li>Low <a href="https://www.therapyin.london/find-help-for/confidence" target="_blank" rel="noreferrer noopener">confidence</a></li>
                  <li><a href="https://www.therapyin.london/find-help-for/motivation" target="_blank" rel="noreferrer noopener">Lack of motivation</a> and drive</li>
                  <li>Even <a href="https://www.therapyin.london/find-help-for/depression" target="_blank" rel="noreferrer noopener">depression</a></li>
                </ul>
                <p>So as you come to understand what loneliness means to you, you can also learn how to approach it, then how to solve and prevent it.</p>
                <h2>What causes loneliness?</h2>
                <p>Loneliness doesn’t come down to simply finding new friends or a new hobby. When you feel lonely, you are craving something that you don’t currently have.</p>
                <p>What you are lacking can be anything from:</p>
                <ul>
                  <li>A change in your routine or lifestyle</li>
                  <li>Being able to say and do things without being questioned about it</li>
                </ul>
                <p>These are just a few of many, but they all boil down to your loneliness telling you that something needs to change in your day-to-day life.&nbsp;</p>
                <h3><strong>It is commonly said that standing in a crowd can be the loneliest place on earth.</strong></h3>
                <p>Friends don’t necessarily solve the issue of loneliness. We all need seemingly simple things in life, but they are not so easy to gain and maintain:</p>
                <ul>
                  <li>Being able to express yourself</li>
                  <li>Feeling confident in yourself</li>
                  <li>Feeling proud of your achievements (big or little!)</li>
                </ul>
                <p>When you don’t have these, you can find yourself with not much to say and not many people to talk to. This is why making a change for yourself is the key to dealing with feeling lonely. As we constantly evolve and others in your life are doing the same, you can go from feeling on top of the world to feeling like no one understands you.</p>
                <h2>How therapy can help loneliness.</h2>
                <p>Loneliness can be a complex issue and is unique to you and your situation.&nbsp;</p>
                <p>This is why therapy is hugely beneficial in reorganizing your thoughts, wants, and needs into something obtainable.&nbsp;</p>
                <p>You’re then able to gain a clear perspective on where you have come, where you are, and where you want to be. Being lonely can become the marker for you taking action in creating a happier and more fulfilling future for yourself.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>At Therapy in London, our practitioners are matched to your exact needs and with who you feel confident confiding in.&nbsp;</p>
                <p>Even though right now may feel like a lonely time for you, we want to be your support and guide into the next phase of your life. Loneliness doesn’t have to be a wall to your happiness, it can be a hurdle encouraging you to leap further than you thought you ever could.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Loneliness;

import React, { useEffect, useRef } from "react";
const loadLottie = import("lottie-web");

const Room = () => {
    const lottieRef = useRef(null);

    useEffect(() => {
        let lottie, lottiePlayer, observer;
        loadLottie.then((module) => {
            lottie = module.default;

            lottiePlayer = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg', // Choose the renderer
                loop: false,
                autoplay: false,
                path: '/images/animations/room/room.json'
            });

            observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        lottiePlayer.play();
                    } else {
                        lottiePlayer.pause();
                    }
                });
            }, { threshold: 0.5 }); // Adjust threshold as needed

            observer.observe(lottieRef.current);
        });

        return () => {
            if (observer) observer.disconnect();
        };
    }, []);

    return (
        <div ref={lottieRef}></div>
    );
};

export default Room;

import { useMutation, useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { deleteCustomImage } from "../../../app/api/profile/deleteCustomImage";
import { getUser } from "../../../app/api/profile/getUser";
import { getUserById } from "../../../app/api/profile/getUserByid";
import { queryClient } from "../../../app/lib/createQueryClient";
import { getFileUrl } from "../../../app/lib/getFileUrl";
import { useProfileStore } from "../../../app/stores";
import("../../../styles/global.scss");
import("../../../styles/sections.scss");

function FeaturedImage({ isProfileEditDrag = false }) {
  const { userId } = useParams();
  const location = useLocation();
  const isResultsProfile = location.pathname.startsWith("/results/profile/");
  const { profile, updateProfile } = useProfileStore((state) => ({
    profile: state.profile,
    updateProfile: state.updateProfile,
  }));

  const userQuery = useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUser(userId),
  });

  const userByIdQuery = useQuery({
    queryKey: ["user", userId],
    queryFn: () => (userId ? getUserById(userId) : null),
    enabled: !!userId,
  });

  const userById = userByIdQuery.data?.user;

  const deleteCustomImageMutation = useMutation({
    mutationFn: () => deleteCustomImage(userQuery.data.user.id),
    onSuccess: () => {
      updateProfile({ selectedFurniturePicture: null });
      queryClient.invalidateQueries(["user"]);
    },
  });

  const user = userQuery.data?.user;

  const handleNext = (key, index, max) => {
    const newIndex = index < max ? index + 1 : 1;
    updateProfile({ [key]: newIndex });
  };

  const handlePrev = (key, index, max) => {
    const newIndex = index > 1 ? index - 1 : max;
    updateProfile({ [key]: newIndex });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg")
    ) {
      updateProfile({ selectedFurniturePicture: file });
    } else {
      alert("Please select a JPEG, PNG, or JPG file.");
    }
  };
  useEffect(() => {
    if (deleteCustomImageMutation.isSuccess) {
      updateProfile({ furniturePicture: null });
    }
  }, [deleteCustomImageMutation.isSuccess, updateProfile]);
  const getProfileData = () => {
    if (isResultsProfile) {
      return userById;
    }
    if (isProfileEditDrag) {
      return profile;
    }
    return user;
  };

  const renderFurnitureImages = () => {
    const profileData = getProfileData();
    if (profileData?.selectedFurniturePicture) {
      return (
        <div className="featuredImage featuredLayout">
          <img
            src={URL.createObjectURL(profile.selectedFurniturePicture)}
            alt="Selected Furniture"
          />
          {isProfileEditDrag && (
            <button
              type="button"
              onClick={() => updateProfile({ selectedFurniturePicture: null })}
            >
              Use Illustrations
            </button>
          )}
        </div>
      );
    }
    if (
      profileData?.profile?.furniturePicture ||
      profileData?.furniturePicture
    ) {
      return (
        <div className="featuredImage featuredLayout">
          <img
            src={getFileUrl(
              "furniture",
              profileData?.userId || profileData?.id,
              profileData?.profile?.furniturePicture ||
              profileData?.furniturePicture
            )}
            alt="Furniture"
          />
          {isProfileEditDrag && (
            <button
              type="button"
              onClick={() => deleteCustomImageMutation.mutate()}
            >
              Use Illustrations
            </button>
          )}
        </div>
      );
    }
    return (
      <div className="featuredImage">
        <div className="section lamp">
          <img
            src={`/images/featuredImage/LAMPS/lamp${profileData?.profile?.lamp || profileData?.lamp || 1
              }.png`}
            alt="lamp"
          />

          {isProfileEditDrag && (
            <div className="selectImage_wrap">
              <button
                type="button"
                onClick={() => handlePrev("lamp", profile.lamp, 5)}
              >
                <img src="/images/icons/chevron_right.svg" alt="asd" />
              </button>
              <label>Lamp {profile.lamp}</label>
              <button
                type="button"
                onClick={() => handleNext("lamp", profile.lamp, 5)}
              >
                <img alt="asda" src="/images/icons/chevron_right.svg" />
              </button>
            </div>
          )}
        </div>
        <div className="section sofa">
          <img
            src={`/images/featuredImage/SOFAS/sofa${profileData?.profile?.sofa || profileData?.sofa || 1
              }.png`}
            alt="sofa"
          />

          {isProfileEditDrag && (
            <div className="selectImage_wrap">
              <button
                type="button"
                onClick={() => handlePrev("sofa", profile.sofa, 5)}
              >
                <img src="/images/icons/chevron_right.svg" alt="asd" />
              </button>
              <label>Sofa {profile.sofa}</label>
              <button
                type="button"
                onClick={() => handleNext("sofa", profile.sofa, 5)}
              >
                <img src="/images/icons/chevron_right.svg" alt="asd" />
              </button>
            </div>
          )}
        </div>
        <div className="section plant">
          <img
            src={`/images/featuredImage/PLANTS/plant${profileData?.profile?.plant || profileData?.plant || 1
              }.png`}
            alt="plant"
          />

          {isProfileEditDrag && (
            <div className="selectImage_wrap">
              <button
                type="button"
                onClick={() => handlePrev("plant", profile.plant, 5)}
              >
                <img src="/images/icons/chevron_right.svg" alt="asd" />
              </button>
              <label>Plant {profile.plant}</label>
              <button
                type="button"
                onClick={() => handleNext("plant", profile.plant, 5)}
              >
                <img src="/images/icons/chevron_right.svg" alt="asd" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="featuredImage_wrap">
      {renderFurnitureImages()}
      <div className="customImage_wrap">
        {isProfileEditDrag && (
          <div className="chooseImage">
            <label htmlFor="customImage">Upload Custom Image</label>
            <input
              type="file"
              id="customImage"
              onChange={handleImageChange}
              accept="image/png, image/jpeg, image/jpg"
            />
          </div>
        )}
        {isProfileEditDrag && (
          <label>Recommended image size of 800px by 300px</label>
        )}
      </div>
    </div>
  );
}

FeaturedImage.propTypes = {
  isProfileEditDrag: PropTypes.bool,
};

export default FeaturedImage;

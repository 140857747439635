import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

const removeId = (arr) => arr.map(({ id, ...rest }) => rest);
export const updateProfile = async ({
  userID,
  lamp,
  plant,
  sofa,
  color,
  typesOfTherapy,
  wheelchairAccess,
  languages,
  sessionTypes,
  specialisms,
  profileInfos,
  location,
  profileId,
  phoneNumber,
  qualifications,
  pricePackage,
  quote,
  membershipBody,
  article1,
  article2,
}) => {
  const sanitizedLocation = location ? { ...location } : {};
  if (sanitizedLocation.id === null || sanitizedLocation.id === "") {
    // biome-ignore lint/performance/noDelete: <explanation>
    delete sanitizedLocation.id;
  }
  try {
    const payload = {
      userID,
      lamp,
      plant,
      sofa,
      quote,
      color,
      typesOfTherapy: typesOfTherapy || [],
      wheelchairAccess,
      languages: languages || [],
      sessionTypes: sessionTypes || [],
      specialisms: specialisms || [],
      profileInfos: (profileInfos || []).filter(
        (info) => info.content && info.content.trim() !== ""
      ),
      location: sanitizedLocation,
      phoneNumber: phoneNumber || "",
      qualifications: qualifications || "",
      pricePackage: removeId(pricePackage || []),
      membershipBody: removeId(membershipBody || []),
      article1Id: article1 || null,
      article2Id: article2 || null,
    };
    const response = await api.put(
      `/profile/${profileId}`,
      payload,
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function Divorce() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Divorce</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Divorce</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/divorce.jpg" alt="Divorce" />
                </div>
              </div>
              <div className="content">
                <h2>A marriage is built on the assumption that you will be together forever.</h2>
                <p>It seemed like an easy decision at the time. But you likely didn’t consider the journey that both of you will go on separately, as well as together.</p>
                <p><a href="https://graziadaily.co.uk/life/real-life/10-questions-to-ask-before-getting-divorced/">Divorce</a> is a difficult, challenging and mentally exhausting process. No matter where you are in the divorce process, it is important to ensure that you are safe. Your mental health should be at a point where you can plan and consider a positive future for yourself.</p>
                <p>What is important to consider are the upcoming changes to your life. When building on a marriage, everything is built with a balance of both your and your partner’s wants and needs. When this stops, it can be hard to know what to do next without that other person there to answer half of your questions.</p>
                <h2>What causes divorce?</h2>
                <p>If you believe that your marriage has reached the point where divorce is the best solution, then that is a valid cause for divorce – no matter what your relationship has been like previously.</p>
                <p>The consideration to separate only comes when you believe that your partnership is no longer beneficial to both of you. The most common causes include:</p>
                <ul>
                  <li>A change in passions</li>
                  <li>No longer able to calmly and rationally communicate</li>
                  <li>Adultery</li>
                  <li>Deception</li>
                  <li>Domestic abuse</li>
                  <li>Finding another partner</li>
                  <li>Changes in work or career goals</li>
                </ul>
                <p>These are common issues, and some can be confused with other issues that may lead to a rift in the relationship, but not wholly the reason for divorce. This includes:</p>
                <ul>
                  <li>Money<br />Finance worries can cause relationship issues, but wouldn’t be the sole reason for divorce, it may just heighten other issues.</li>
                  <li>Sex<br />A lower sex drive or less sex in a marriage can be a result of other, deeper issues in the partnership.</li>
                </ul>
                <p>The differences between these issues are vast but can all be seen as valid reasons for divorce.</p>
                <h2>How can therapy help with divorce.</h2>
                <p>A divorce can bring up a lot of emotions and feelings towards your partner. So the first thing to understand is where those feelings come from, what are their roots and how you can relate back to it.</p>
                <p>By doing this you can fully come to terms with the decision for divorce, rather than dwelling on every issue within the marriage. Understanding this is the first step to the most successful life you can lead for yourself after separation.</p>
                <p>If you are considering working through your marriage to rebuild and avoid an impending divorce, take a look at our <a href="https://www.therapyin.london/counselling-services/marriage-counselling/">marriage counselling</a> page.</p>
                <h2>How Therapy in London can help.</h2>
                <p>We take any issue as something for you to build and learn from. Even through divorce, you can learn more about yourself than ever before.</p>
                <p>Sessions with our practitioners ensure that you can find the best in yourself and exceed goals that you may never have envisioned after your separation. By taking your divorce and creating a new narrative for yourself, you will be able to step away with a real understanding and gratitude for everything you have experienced in your life.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Divorce;

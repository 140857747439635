import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const updateUser = async ({ userId, name, email, gender }) => {
  try {
    const response = await api.put(
      `/user/${userId}`,
      {
        name,
        email,
        gender,
      },
      await auth.currentUser.getIdToken()
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

import PropTypes from "prop-types"; // Ensure correct import for PropTypes
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import("../../styles/sections.scss");

// Remove gradientMap from propTypes
OctagonImage.propTypes = {
  src: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
};

function OctagonImage({ src, href, title, author, authorId }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);
    };
  }, [src]);

  return (
    <div className="articleBlock">
      <Link to={href}>
        <div className="octagon-container">
          <canvas ref={canvasRef} className="octagon-image" />
        </div>
        <h3>{title}</h3>
        <p>
          <Link to={`/results/profile/${authorId}`} className="author">
            {author}
          </Link>
        </p>
      </Link>
    </div>
  );
}

export default OctagonImage;

import { memo, forwardRef } from 'react';
import { motion } from "framer-motion";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import("../../../styles/articles.scss");
import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/textTransitions.scss");
import { getFileUrl } from "../../../app/lib/getFileUrl";
import parse from 'html-react-parser';

const staggeredVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

function Scrollview({ className, children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={staggeredVariants}
      className={className}
    >
      {children}
    </motion.div>
  );
}

Scrollview.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
};

export const ThumbnailBlog = memo(forwardRef(function ThumbnailBlog(
  { imageSrc, title, desc, date, blogId, slug },
  ref
) {
  return (
    <div className="block">
      <Link to={`/blog/${slug}`}>
        <Scrollview>
          <div
            ref={ref}
            className="image"
            style={{ overflow: "hidden", height: "130px" }}
          >
            <motion.img
              src={getFileUrl("blogImage", blogId, imageSrc)}
              alt={title}
              variants={itemVariants}
            />
          </div>

          <div style={{ overflow: "hidden", height: "auto" }}>
            <motion.h2 id="slide-up" variants={itemVariants}>
              {title}
            </motion.h2>
          </div>
          <div style={{ overflow: "hidden", height: "auto" }} className='blogThumnail'>
            <motion.p variants={itemVariants}>
              {parse(desc?.split(' ').slice(0, 25 ).join(' ') + (desc?.split(' ').length > 25 ? '...' : ''))}
            </motion.p>
            <motion.p variants={itemVariants} className='blogDate'>
              {date?.slice(0, 10)}
            </motion.p>
          </div>
        </Scrollview>
      </Link>
    </div>
  );
}));

// Add display name
ThumbnailBlog.displayName = 'ThumbnailBlog';

ThumbnailBlog.propTypes = {
  imageSrc: propTypes.string,
  title: propTypes.string,
  desc: propTypes.string,
  date: propTypes.string,
  blogId: propTypes.string,
  slug: propTypes.string,
};

import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createAvailableArticle } from "../../app/api/admin";
import { updateAvailableArticle } from "../../app/api/admin/updateAvailableArticle";
import { getSpecialisms } from "../../app/api/profile/getSpecialisms";
import useToast from "../../app/hooks/useToast";
import SearchInput from "../../components/elements/SearchInput";
import Loader from "../../components/elements/loader";
import CTA from "../../components/fragments/CTA";
import("../../styles/articles.scss");
import("../../styles/global.scss");
import("../../styles/sections.scss");

function EditAvailableArticle() {
  const navigate = useNavigate();
  const location = useLocation();
  const article = location.state?.article;
  const { aArticleId } = useParams();
  const { notifyFailure, notifySuccess } = useToast();
  const [title, setTitle] = useState(article?.title || "");
  const [description, setDescription] = useState(article?.description || "");
  const [specialisms, setSpecialisms] = useState(article?.specialisms || []);

  const updateAvailableArticleMutation = useMutation({
    mutationFn: async (data) =>
      updateAvailableArticle({
        title: data.title,
        description: data.description,
        specialisms: data.specialisms,
        id: aArticleId,
      }),
    onSuccess: () => {
      notifySuccess("Article Updated Successfully");
      navigate("/admin/articles/available-articles");
    },
    onError: (error) => {
      notifyFailure("Article Update Failed", error.message);
    },
  });

  const createAvailableArticleMutation = useMutation({
    mutationFn: async (data) =>
      createAvailableArticle({
        title: data.title,
        description: data.description,
        specialisms: data.specialisms,
      }),
    onSuccess: () => {
      notifySuccess("Article Created Successfully");
    },
    onError: (error) => {
      notifyFailure("Article Creation Failed", error.message);
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["specialisms"],
    queryFn: () => getSpecialisms(),
  });

  if (isLoading) {
    return <Loader subtitle="Loading..." />;
  }

  const handleSave = () => {
    if (article) {
      updateAvailableArticleMutation.mutate({
        id: article.id,
        title,
        description,
        specialisms,
      });
    } else {
      createAvailableArticleMutation.mutate({
        title,
        description,
        specialisms,
      });
    }
  };

  const specialismsData = data?.specialisms.map((specialism) => ({
    id: specialism.id,
    name: specialism.name,
  }));

  return (
    <div className="profileView_wrap backgroundClear">
      <div className="profileView">
        <div className="profileView_controls_wrap">
          <div className="titleEdit">
            <h2>Article Brief</h2>
          </div>
          <div className="controls">
            <CTA type="ghost" text="Go Back" onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className="narrowColumn_wrap">
          <div className="title">
            <h1>Edit Brief</h1>

            <p>Update Specialisims, Write Title and Brief.</p>
          </div>
          <div className="editorWrap">
            <div className="editor">
              <div className="availableArticles_wrap">
                <div className="availableArticles_block">
                  <SearchInput
                    items={specialismsData}
                    onSelectionChange={setSpecialisms}
                    initialSelectedItems={specialisms}
                  />

                  <div className="about adminAvailableArticles">
                    <input
                      placeholder="Article Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <textarea
                      placeholder="Article Brief"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className="control">
                      <CTA
                        type="ghost"
                        text="Update Brief"
                        onClick={handleSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAvailableArticle;

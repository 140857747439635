import { motion } from "framer-motion"; // Import Framer Motion
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useUserData } from "../../app/hooks/useUserData";
import { useProfileStore } from "../../app/stores";
import { WizardBanner } from "../../components/elements/WizardBanner";
import Loader from "../../components/elements/loader";
import ProfilePageView from "../../components/elements/profilePageView";
import AccountStatus from "../../components/fragments/AccountStatus";
import CTA from "../../components/fragments/CTA";
import("../../styles/buttons.scss");
import("../../styles/global.scss");
import("../../styles/notifications.scss");
import("../../styles/profiles.scss");

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

function TherapistAdmin() {
  const { updateProfile } = useProfileStore((state) => ({
    updateProfile: state.updateProfile,
  }));

  const { data: profileData, isLoading } = useUserData();

  const fetchedProfile = profileData?.user;

  useEffect(() => {
    if (fetchedProfile && fetchedProfile.profile !== null) {
      updateProfile({
        userId: fetchedProfile.id,
        name: fetchedProfile.name,
        email: fetchedProfile.email,
        phoneNumber: fetchedProfile.profile.phoneNumber,
        color: fetchedProfile.profile.color,
        lamp:
          fetchedProfile.profile.lamp === 0 ? 1 : fetchedProfile.profile.lamp,
        sofa:
          fetchedProfile.profile.sofa === 0 ? 1 : fetchedProfile.profile.sofa,
        plant:
          fetchedProfile.profile.plant === 0 ? 1 : fetchedProfile.profile.plant,
        pricePackage: fetchedProfile.profile.pricePackage,
        membershipBody: fetchedProfile.profile.membershipBody,
        languages: fetchedProfile.profile.languages,
        insuranceFile: fetchedProfile.profile.insuranceFile,
        profileInfos: fetchedProfile.profile.profileInfos,
        specialisms: fetchedProfile.profile.specialisms,
        selectedPhoto: null,
        typesOfTherapy: fetchedProfile.profile.typesOfTherapy || [],
        sessionTypes: fetchedProfile.profile.sessionTypes || [],
        wheelchairAccess: fetchedProfile.profile.wheelchairAccess,
        formattedAddress: fetchedProfile?.profile?.location?.formattedAddress,
        photo: fetchedProfile.profile.profilePicture,
        // Add this to the updateProfile call in the useEffect
        furniturePicture: fetchedProfile.profile.furniturePicture,
        selectedFurniturePicture: null,
        article1: fetchedProfile.profile.article1Id ?? null,
        article2: fetchedProfile.profile.article2Id ?? null,
        quote: fetchedProfile.profile.quote ?? null,
        locationObj: fetchedProfile.profile.location ?? null,
      });
      return;
    }
    if (fetchedProfile) {
      updateProfile({
        userId: fetchedProfile.id,
        name: fetchedProfile.name,
        email: fetchedProfile.email,
      });
    }
  }, [fetchedProfile, updateProfile]);

  if (isLoading) {
    return <Loader subTitle="Loading your profile..." />;
  }

  return (
    <motion.div
      className="profileView_wrap signedIn"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="profileView"
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
      >
        {fetchedProfile.subscriptionStatus === false && (
          <motion.div
            className="wizardBanner"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <WizardBanner profile={fetchedProfile} />
          </motion.div>
        )}
        <div className="profileView_controls_wrap">
          <motion.div
            className="adminNav"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
          >
            <div className="profileView_page_title">
              <div className="title">
                <h1>Account</h1>
                <AccountStatus status={fetchedProfile?.profile?.status} />
              </div>
            </div>
            <div className="adminNav_links">
              <div className="links">
                <ul>
                  <motion.li
                    className="active"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                  >
                    <Link to="/therapist/profile">Profile</Link>
                    <img
                      src="../../../images/illustrations/underline.svg"
                      alt="underline"
                    />
                  </motion.li>
                  {fetchedProfile.subscriptionStatus === true && (
                    <motion.li
                      initial="hidden"
                      animate="visible"
                      variants={fadeInVariants}
                    >
                      <Link to="/therapist/articles">Articles</Link>
                      <img
                        src="../../../images/illustrations/underline.svg"
                        alt="underline"
                      />
                    </motion.li>
                  )}
                  {fetchedProfile?.profile?.status === "approved" && (
                    <motion.li
                      initial="hidden"
                      animate="visible"
                      variants={fadeInVariants}
                    >
                      <Link to="/therapist/billing">Billing</Link>
                      <img
                        src="../../../images/illustrations/underline.svg"
                        alt="underline"
                      />
                    </motion.li>
                  )}
                  <motion.li
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                  >
                    <Link to="/therapist/settings">Settings</Link>
                    <img
                      src="../../../images/illustrations/underline.svg"
                      alt="underline"
                    />
                  </motion.li>
                </ul>
              </div>
              <div className="controls editCTA">
                <CTA
                  link="/therapist/profile/edit"
                  type="orange"
                  text="Edit Profile"
                />
              </div>
            </div>
          </motion.div>
        </div>

        <ProfilePageView
          pageType="TherapistAdmin"
          isTherapistAdmin={false}
          user={fetchedProfile}
        />
      </motion.div>
    </motion.div>
  );
}

export default TherapistAdmin;

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

// Define animation variants
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } }
};

function GenderIdentity() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../find-help-for">Find Help For..</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
          <img src="/images/icons/chevron_right.svg" className="chevron" alt="Chevron right" />
          <div className="breadcrumb current_page">
            <a href="#">Gender Identity and Dysphoria</a>
            <img src="/images/illustrations/underline.svg" alt="Underline decoration" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>Therapy for Gender Identity and Dysphoria</h1>
                    <img src="/images/illustrations/purpleScribble.svg" className="titleScribble" alt="Purple scribble decoration" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="/images/articles/findHelpFor/Gender-identity.jpg" alt="Gender Identity" />
                </div>
              </div>
              <div className="content">
                <p>Your own gender identity can only be decided by you as an individual. This is determined by who you feel you are and who you wish to be. Yet this can be a difficult journey for a lot of people.</p>
                <p>Just a few gender identities include:</p>
                <ul>
                  <li>Your gender matches your own sex from birth (cis)</li>
                  <li>You want to proceed with gender reassignment (trans)</li>
                  <li>You do not fall into any (non-binary).</li>
                </ul>
                <p>There are several other <a href="https://outrightinternational.org/content/terminology-surrounding-gender-identity-and-expression?gclid=CjwKCAiAhreNBhAYEiwAFGGKPCxWLu-gzdHVV5hpZTRkBgNMvE64Vbl3d7rFf4xTG6dg4qmzIsY9cxoCPYEQAvD_BwE">genders</a>, and the list is expansive, but only you can determine how you feel and who you would like to be seen as.</p>
                <h2>Questioning your gender identity, or gender dysphoria (GD), is <em>not</em> a mental disorder.</h2>
                <p>Today, we have moved away from the term gender disorder in order to give everyone the opportunity to fully realise who they are, without any stigma attached to it.</p>
                <p>Even with this movement and growth in society, you might still feel unsure about speaking out on the matter.</p>
                <p>GD still, along with identifying your sexual orientation, can, unfortunately, cause many mental illnesses and is why it’s so important to encourage open discussions about personal identity.</p>
                <p>Mental issues that can develop if ignored can include:</p>
                <ul>
                  <li>Depression</li>
                  <li>Body Dysmorphia and eating disorders</li>
                  <li>Anger issues</li>
                  <li>Self-esteem issues</li>
                </ul>
                <p>When seeking talking counselling or any help regarding your identity, this doesn’t mean it is something to be fixed. You are in fact giving yourself the mental space to fully understand who you are and what that means for your future.</p>
                <p>Gender identity and gender reassignment have become a growing talking point in recent years. As research develops on the correlation between born gender and mental gender, you yourself must understand what you are feeling and what it means.</p>
                <h2>What causes gender dysphoria?</h2>
                <p>Research into gender identity and dysphoria is ongoing and continuous. What we do fully understand is that no one is confined to one way of thinking. A single human being cannot conform to an idealised way of living within society.</p>
                <p>Every single human being has always seen and felt things uniquely to everyone else, we are simply trying to come out of the confines that society has held us in for so long.</p>
                <h2>How therapy can help.</h2>
                <p>When trying to understand your own identity, it can feel like everyone has their own thoughts on how you are feeling, which can be overwhelming and confusing.&nbsp;</p>
                <p>That is why counselling can be the most beneficial process in understanding your own thoughts and feelings. Within this space, you are able to clarify exactly what is going through your head, and what you would like for yourself and for your own happiness.</p>
                <p>Having that personal space offers enlightenment away from the noise.</p>
                <h2>How <em>Therapy in London</em> can help.</h2>
                <p>We want to be part of complete inclusivity, and that includes opening our doors to everyone.&nbsp;</p>
                <p>When you feel completely alone it can be difficult to believe that someone could understand you. But there is always someone that wants to help and this is what we want to offer to everyone.&nbsp;</p>
                <p>We guarantee we can partner you with a therapist who will help to create a new path for you, to see the best in yourself and who you want to be.</p>
                <p>We are proud to support the LGBTQ+ community and to encourage constant change, understanding and acceptance.</p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default GenderIdentity;

export const typesOfTherapy = [
  "Accelerated Experiential Dynamic Psychotherapy",
  "Acceptance and Commitment Therapy, ACT",
  "Adlerian Therapy",
  "Animal-assisted Therapy",
  "Applied Psychology",
  "Attachment-based Therapy",
  "Behavior Therapy",
  "Behavioural Therapy",
  "Bibliotherapy",
  "Christian Counselling",
  "Coaching",
  "Cognitive Analytic Therapy, CAT",
  "Cognitive Behavoural Therapy, CBT",
  "Cognitive Processing Therapy, CPT",
  "Cognitive Stimulation Therapy",
  "Cognitive Therapy",
  "Compassion-Focused Therapy",
  "Core Process Psychotherapy",
  "Couples Counselling",
  "Dance Therapy",
  "Dialectical Behavioural Therapy, DBT",
  "Dynamic Interpersonal Therapy",
  "Eclectic Counselling",
  "Ecotherapy",
  "EMDR",
  "Emotion-focused Therapy",
  "Emotional Freedom Technique, EFT",
  "Emotionally Focused Couple Therapy, EFCT",
  "Equine Therapy",
  "Existential Therapy",
  "Experiential Therapy",
  "Exposure and Response Prevention, ERP",
  "Family Therapy",
  "Gestalt Therapy",
  "Group Therapy",
  "Human Givens Psychotherapy",
  "Humanistic Therapies",
  "Humanistic Therapy",
  "Hypnotherapy",
  "Imago Relationship Therapy, IRT",
  "Integrative",
  "Integrative / Holistic Therapy",
  "Intercultural Therapy",
  "Internal Family Systems Therapy, IFS",
  "Interpersonal Therapy",
  "Jungian Therapy",
  "Mentalisation-Based Therapy",
  "Mindfulness",
  "Motivational interviewing, MI",
  "Narrative Therapy",
  "Neuro-Linguistic Programming, NLP",
  "Neurofeedback",
  "Person-centred Therapy",
  "Play Therapy",
  "Positive Psychology",
  "Prolonged Exposure Therapy, PET",
  "Psychoanalysis",
  "Psychoanalytic Therapy",
  "Psychoanalytical and Psychodynamic",
  "Psychodrama",
  "Psychodynamic",
  "Psychodynamic Therapy",
  "Psychosexual Therapy",
  "Psychosynthesis",
  "Rational Emotive Behaviour Therapy, REBT",
  "Reality Therapy",
  "Relational Therapy",
  "Sandplay Therapy",
  "Schema Therapy",
  "Social Recovery Therapy",
  "Solution-focused Brief Therapy",
  "Somatic Therapy",
  "Strength-based Therapy",
  "Structural Family Therapy",
  "Systemic Therapy",
  "The Gottman Method",
  "Transactional Analysis",
  "Transpersonal Psychology",
  "Trauma-focused CBT",
  "Walk and Talk Therapy",
  "Short-term Therapy",
];

import("../../../styles/global.scss");
import("../../../styles/sections.scss");
import("../../../styles/articles.scss");
import PostIt from "../../../components/elements/findhelpfor/postIt";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function HowToFindATherapist() {
  return (
    <>
      <motion.div
        className="articlesPage_wrap"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="breadcrumbs_wrap">
          <div className="breadcrumb">
            <a href="../../how-to/">How to</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
          <img src="../../images/icons/chevron_right.svg" className="chevron" />
          <div className="breadcrumb current_page">
            <a href="#">How to Find a Therapist</a>
            <img src="../../images/illustrations/underline.svg" />
          </div>
        </div>

        <div className="articleView_wrap fhfArticleWrap">
          <div className="articleView ">
            <motion.div
              className="articleContent"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="article_start">
                <div className="title">
                  <div className="titleWrap">
                    <h1>How to Find a Therapist</h1>
                    <img src="../../images/illustrations/purpleScribble.svg" className="titleScribble" />
                  </div>
                </div>
                <div className="featured_image">
                  <img src="../../images/articles/howTo/how-to-find-a-therapist.jpg" />
                </div>
              </div>
              <div className="content">
                <p>
                  If you want to start therapy but you don’t know how to find the right therapist, we are able to answer any question you have on therapy and finding a practitioner you can trust and grow with.
                </p>

                <p>
                  When it comes to finding a therapist, there are a few things to consider:
                </p>

                <h2>1. Do I need therapy?</h2>

                <p>
                  We recommend starting therapy at any point in your life. This is whether you feel you have underlying mental health issues or even feel happy and content in your life.
                </p>

                <p>
                  Therapy is not just for people seeking help or solutions to issues. Therapy is also hugely beneficial to you at any time in your life. The happiest moments in your life are helpful in discovering how you can continue that feeling of wellness and happiness through therapy.
                </p>

                <p>
                  Sitting within your emotions (happy or sad) is when you understand and learn from them the most. This is why when you have a space to regularly look back on things, you are able to learn from them and have a better understanding of how to feel happier and fulfilled in the future.
                </p>

                <p>
                  In the same way that learning from what makes you happy is beneficial, learning from your low moments is just as important. So if you are thinking either:
                </p>

                <p>
                  <em>‘I will start therapy when I don’t feel as sad’</em> or <em>‘I will start therapy when I’m not happy’</em>
                </p>

                <p>
                  Start today, because you’ll be surprised what you can learn about yourself right now.
                </p>

                <h2>2. How do I choose a therapist?</h2>

                <p>
                  Choosing your therapist is an important part of the therapeutic journey. Your therapy sessions must feel like your space and time. You create this space by sharing it with a counsellor you trust and who you feel you can build a strong relationship with.
                </p>

                <p>
                  This can be difficult when looking for a therapist, especially if you are not sure what you are looking for. A lot of the time, a photo and a brief profile can be enough to make you feel like you are ready to take your therapeutic journey with that practitioner. Many times, it can also be difficult to know if you are going to make the right choice.
                </p>

                <p>
                  A therapist’s experience and background can play a large role in what would be suited to you. This depends on if you would prefer to speak to someone with certain specialisms or a specific therapeutic style. This research can however pave the way for either overthinking who you may start with, or leave your procrastinating.
                </p>

                <p>
                  If you have left several tabs open of certain therapist’s profiles and just don’t know what is the right choice for you, it’s important to know that you cannot know for sure until you have started to talk to someone.
                </p>

                <p>
                  Many therapists offer a telephone chat to help you decide. If you would like further <a href="https://www.therapyin.london/find-us/">help finding a therapist</a>, we are able to refer you to someone who suits your needs just by having a brief chat with you. Our fully confidential consultations are free and there to help you take that first step.
                </p>

                <h2>3. What am I looking for?</h2>

                <p>
                  There are several elements you will consider when choosing a therapist:
                </p>

                <ul>
                  <li>Therapeutic style</li>
                  <li>Price</li>
                  <li>Experience</li>
                  <li>Gender</li>
                  <li>Ethnicity and background</li>
                  <li>Location or online availability</li>
                </ul>

                <p>
                  Having a vague understanding of therapeutic styles and qualifications can be useful, but can also feel quite daunting. All therapists go through a variety of training and have many different professional and personal backgrounds.
                </p>

                <p>
                  Most of these elements can be confirmed through a consultation, usually by telephone. A good indicator is if you feel that any of your questions still weren’t answered. Or you do not feel you could continue an open discussion with that person.
                </p>

                <h2>4. Is my therapist good?</h2>

                <p>
                  If you feel you can trust them and you are finding a benefit in seeing them, then you have found your therapist.
                </p>

                <p>
                  Trust is one of the most important things to determine from therapy in order for you to fully delve into what has brought you there and what you want to achieve from it. Therapy may not even be what you expected and few people see or feel results straight away. Trust, however, and feeling like you can talk to that person candidly, is what will tell you they are doing their job.
                </p>
              </div>
            </motion.div>
            <PostIt />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default HowToFindATherapist;

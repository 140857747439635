import { api } from "../../lib/createClient";
import { auth } from "../../lib/firebaseClient";

export const getAllArticles = async ({ status = "", page = 1, limit = 10 }) => {
  try {
    const response = await api.get(
      `/admin/articles?status=${status}&page=${page}&pageSize=${limit}`,
      await auth.currentUser.getIdToken()
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.problem}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting article:", error);
    throw error;
  }
};

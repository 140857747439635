import React, { useEffect, useRef } from "react";
const loadLottie = import("lottie-web");

const ArmChair = () => {
    const lottieRef = useRef(null);
    const animationInstance = useRef(null);

    useEffect(() => {
        let handleScroll, lottie, lottiePlayer;
        loadLottie.then((module) => {
            lottie = module.default;

            lottiePlayer = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                path: '/images/animations/room/smallchairplant.json'
            });

            animationInstance.current = lottiePlayer;

            handleScroll = () => {
                const rect = lottieRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // Calculate the scroll progress based on container's position relative to the viewport
                const scrollProgress = Math.min(
                    1,
                    Math.max(0, (windowHeight - rect.top) / (rect.height + windowHeight))
                );

                // Get the frame number corresponding to the scroll progress
                const totalFrames = lottiePlayer.totalFrames;
                const frame = Math.min(totalFrames, Math.max(0, scrollProgress * totalFrames));

                // Set the animation to the correct frame
                lottiePlayer.goToAndStop(Math.floor(frame), true);
            };

            // Attach the scroll event listener
            window.addEventListener("scroll", handleScroll);
        });
        
        return () => {
            if(typeof handleScroll != 'undefined' && handleScroll!=null) window.removeEventListener("scroll", handleScroll);
            if(lottiePlayer) lottiePlayer.destroy(); // Cleanup the Lottie instance
        };
    }, []);

    return <div ref={lottieRef} className="featureImage1"></div>;
};

export default ArmChair;
